<template>
    <div class="edit_support_contaier">
        <SettingsBlock class="settings_block" title="User support">
            <div class="edit_support_subtitle">Password reset</div>
            <div class="edit_support_text">
                The user was succesfully created and activated, but the user lost their password.
                <Button
                    @click.native="confirmSend('UPDATE_PASSWORD')"
                    buttonStyle="secondary"
                    :disabled="!data.isEmailVerified"
                    >Send email</Button
                >
            </div>
            <div class="edit_support_subtitle">Verify email</div>
            <div class="edit_support_text">
                The user has received the welcome mail, but the verify link has expired.
                <Button
                    @click.native="confirmSend('VERIFY_EMAIL')"
                    buttonStyle="secondary"
                    :disabled="data.isEmailVerified"
                    >Send email</Button
                >
            </div>
            <div class="edit_support_subtitle">Welcome</div>
            <div class="edit_support_text">
                The user has not received any email yet and should receive the welcome email
                (again).
                <Button
                    @click.native="confirmSend('WELCOME_EMAIL')"
                    buttonStyle="secondary"
                    :disabled="data.isEmailVerified"
                    >Send email</Button
                >
            </div>
        </SettingsBlock>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Sending the email"
            :message="popupMessage"
            confirm-btn="Send"
            confirmButtonStyle="primary"
            :confirmBtnIsLoading="loading"
            @confirm="sendEmail(emailType)"
            @cancel="closeConfirmPopup"
        />
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import { Button, ConfirmPopup } from "@feedbackcompany/feedback-company-vue-components";
import resendEmail from "@/graphql/resendEmail.gql";
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useStore } from "@/helpers/composition-helper";

const store = useStore();

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const showConfirmPopup = ref(false);
const popupMessage = ref("");
const emailType = ref("");
const loading = ref(false);

function confirmSend(msg) {
    showConfirmPopup.value = true;
    emailType.value = msg;
    switch (msg) {
        case "UPDATE_PASSWORD":
            popupMessage.value = `You are about to send the following email to ${props.data.email}:<br><br><strong>Password reset</strong>. The user will receive an email to set a new password.`;
            break;
        case "VERIFY_EMAIL":
            popupMessage.value = `You are about to send the following email to ${props.data.email}:<br><br><strong>Verify email</strong>. The user will receive an new email with a verify link so that their email adress can be verified.`;
            break;
        case "WELCOME_EMAIL":
            popupMessage.value = `You are about to send the following email to ${props.data.email}:<br><br><strong>Welcome</strong>. The user will receive an email with an introduction the customer portal and a link to set up their user.`;
            break;
        default:
            break;
    }
}

function closeConfirmPopup() {
    showConfirmPopup.value = false;
}

function formatEmailType() {
    if (emailType.value === "UPDATE_PASSWORD") {
        return '"Password reset"';
    }
    if (emailType.value === "VERIFY_EMAIL") {
        return '"Verify"';
    }
    if (emailType.value === "WELCOME_EMAIL") {
        return '"Welcome"';
    }
}

// mutations
const { mutate, onDone, onError } = useMutation(resendEmail);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "Email sent",
        message: `The user will receive the ${formatEmailType()} email.`,
    });
    loading.value = false;
    closeConfirmPopup();
});
onError(() => {
    loading.value = false;
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Email could not be delivered.",
    });
    closeConfirmPopup();
});

async function sendEmail(type) {
    const variables = {
        userId: props.data.id,
        emailType: type,
    };
    loading.value = true;
    mutate(variables);
}
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.edit_support_subtitle {
    @extend %subtitle3_style;
    margin-top: 30px;
    margin-bottom: 4px;
}
.edit_support_text {
    @extend %body2_style;
    display: flex;
    align-items: center;
    width: 430px;
    letter-spacing: 0;
    margin-bottom: 30px;
    color: $grey_manatee;
}
.edit_support_contaier {
    margin-bottom: 60px;
}
</style>
