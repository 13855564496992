<template>
    <metadata-overview
        title="Service identifiers"
        :metadata="metadata"
        :showEdit="isEvaluation"
        @onClick="$emit('openDialog')"
    />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed } from "vue";

const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
});
const metadata = computed(() => {
    return [
        {
            key: "Company ID",
            value: props.company.externalId ? props.company.externalId : "−",
            isEmpty: !props.company.externalId,
        },
        {
            key: "Platform",
            value: props.company.platform ? props.company.platform : "No platform selected",
            isEmpty: !props.company.platform,
        },
        {
            key: "Custom platform",
            value: props.company.subPlatform
                ? props.company.subPlatform
                : "No custom platform selected",
            isEmpty: !props.company.subPlatform,
        },
        {
            key: "Entity type",
            value: props.company.entityType ? props.company.entityType?.toLowerCase() : "−",
            isEmpty: !props.company.entityType,
        },
        {
            key: "Entity ID",
            value: props.company.entityId ? props.company.entityId : "−",
            isEmpty: !props.company.entityId,
        },
    ];
});
const isEvaluation = computed(() => {
    return props.company.services.includes("EVALUATION");
});
</script>
