<template>
    <div>
        <Table
            class="users_table"
            title="User overview"
            :columns="[
                { label: 'Username', property: 'username' },
                { label: 'First name', property: 'firstName' },
                { label: 'Last name', property: 'lastName' },
                { label: 'Email', property: 'email' },
                { label: 'Division(s)', property: 'divisionsNames' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="[
                'username',
                'company.name',
                'firstName',
                'lastName',
                'email',
                'divisionsNames',
            ]"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="[
                'username',
                'company.name',
                'firstName',
                'lastName',
                'email',
                'divisionsNames',
            ]"
            :searching="searchTerm !== null"
            :searchTerm="searchTermInit"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell>
                    <router-link :to="getEditUserUrl(row.id)" class="edit_user_link">
                        {{ row.username }}
                    </router-link>
                </TableCell>
                <TableCell>{{ row.firstName }}</TableCell>
                <TableCell>{{ row.lastName }}</TableCell>
                <TableCell>{{ row.email }}</TableCell>
                <TableCell>{{ row.divisionsNames }}</TableCell>
                <TableCell compact>
                    <IconButton label="" v-tippy="'Delete user'" @click.native="confirmDelete(row)">
                        <font-awesome-icon :icon="['far', 'trash-alt']" />
                    </IconButton>
                </TableCell>
                <TableCell compact>
                    <IconButton label="">
                        <router-link :to="getEditUserUrl(row.id)" v-tippy="'Edit user'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
                <TableCell compact>
                    <IconButton
                        label=""
                        @click.native="row.isEmailVerified === true ? impersonate(row.id) : ''"
                    >
                        <ImpersonateIcon :disabledIcon="row.isEmailVerified" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No users </span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="users"
                    ofString="of"
                />
            </template>
        </Table>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            message="Are you sure you want to continue? Once a user has been deleted, <b>this cannot be undone.</b>"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            @confirm="deleteUser"
            @cancel="closeConfirmDialog"
        />
    </div>
</template>

<script setup>
import {
    LoaderCircular,
    IconButton,
    PaginationIndicator,
    TableCell,
    TableRow,
    Table,
    ConfirmPopup,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { ref, watch, onMounted } from "vue";
import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import { useHttp } from "@/helpers/useHttp";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
import { useStore } from "@/helpers/composition-helper";
import { useMutation } from "@vue/apollo-composable";
import deleteUserMutation from "../graphql/deleteUser.gql";
import ImpersonateIcon from "./icons/ImpersonateIcon.vue";

library.add(faTrashAlt, faEdit);

const { httpPost } = useHttp();
const realm = process.env.VUE_APP_KEYCLOAK_CUSTOMER_REALM;
const store = useStore();

const emit = defineEmits(["refreshUsers"]);

const props = defineProps({
    users: {
        type: Array,
        required: true,
    },
    isLoading: {
        type: Boolean,
    },
});

const showConfirmPopup = ref(false);
const deletionUser = ref(null);
const searchTermInit = ref(null);

const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = data;

function getDivisionsNames(row) {
    if (row.companyLevelAccess) {
        return "All divisions";
    }
    return row.divisions?.length === 1 ? row.divisions[0].name : "Multiple divisions";
}

function setTableData() {
    props.users.forEach((user) => {
        user.divisionsNames = getDivisionsNames(user);
    });

    const oldTableState = store.getters["OverviewStore/getState"]("companyUsers");
    if (oldTableState.currentSorted) {
        sort({
            column: oldTableState.currentSorted,
            direction: oldTableState.currentSortDirection,
        });
    }
    if (oldTableState.searchTerm) {
        searchTermInit.value = oldTableState.searchTerm;
    }
    if (oldTableState.currentPage > 1) {
        setPage(oldTableState.currentPage);
    }
    if (oldTableState.paginationSize) {
        setPaginationSize(oldTableState.paginationSize);
    }
    setData(props.users);
}
function onPageChange(page) {
    setPage(page);
}
async function impersonate(userId) {
    const headers = { "Content-Type": "application/json;charset=utf-8" };

    const body = {
        realm,
        user: userId,
    };

    httpPost({
        endPoint: `${process.env.VUE_APP_KEYCLOAK_URL}/admin/realms/${realm}/users/${userId}/impersonation`,
        body,
        headers,
    })
        .then(() => {
            window.open(process.env.VUE_APP_IMPERSONATION_REDIRECT_URL);
        })
        .catch(() => {
            this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Failed to impersonate user",
            });
        });
}
function getEditUserUrl(userId) {
    return {
        path: `/edit-user/${userId}`,
    };
}
function confirmDelete(user) {
    showConfirmPopup.value = true;
    deletionUser.value = user;
}
function closeConfirmDialog() {
    showConfirmPopup.value = false;
    deletionUser.value = null;
}

// mutation
const { mutate: deleteSelectedUser, onDone, onError } = useMutation(deleteUserMutation);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "User deleted",
        message: `You've successfully deleted user "${deletionUser.value.username}"`,
    });
    deletionUser.value = null;
    emit("refreshUsers");
});

onError((error) => {
    Sentry.captureException(error);
    deletionUser.value = null;
    if (resolveGraphQLErrors(error)[0] === "USER_NOT_FOUND") {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Unknown user",
            message: "There is no user with this id.",
        });
    } else {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "User could not be deleted.",
        });
    }
});

function deleteUser() {
    const variables = {
        userId: deletionUser.value.id,
    };
    showConfirmPopup.value = false;
    deleteSelectedUser(variables);
}

watch(
    () => props.users,
    () => {
        setTableData();
    }
);

watch(tableState, () => {
    store.commit("OverviewStore/setState", { page: "companyUsers", newState: tableState.value });
});

onMounted(async () => {
    if (!props.isLoading) setTableData();
});
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.users_table {
    width: 100%;
    margin-bottom: 60px;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.edit_user_link {
    color: $blue;
    text-decoration: none;
}
.fa-edit {
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
}
.fa-trash-alt {
    color: $blue;
    &:hover {
        color: $red;
    }
}
</style>
