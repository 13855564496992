<template>
    <div>
        <SettingsBlock title="Settings">
            <div class="mood_monitor_subtitle">Pronouns</div>
            <div class="mood_monitor_text">
                Select the pronoun to be used for the questionnaires, invitations and Thank You
                page.
            </div>
            <RadioList
                v-model="settings.formal"
                required
                :options="[
                    { value: false, displayValue: 'Je' },
                    { value: true, displayValue: 'U / Uw' },
                ]"
            />
        </SettingsBlock>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, computed, watch } from "vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import { RadioList } from "@feedbackcompany/feedback-company-vue-components";
import deepEqual from "deep-equal";
import cloneDeep from "lodash.clonedeep";
import { useStore } from "@/helpers/composition-helper";
import { useMutation } from "@vue/apollo-composable";
import updateCompanyMoodMonitorSettings from "../../graphql/mood-monitor/updateMoodMonitorSettings.gql";

const store = useStore();
const emit = defineEmits(["isDirty"]);

const props = defineProps({
    companyId: {
        required: true,
    },
    settings: {
        required: true,
    },
});
const originalSettings = ref(cloneDeep(props.settings));

const isDirty = computed(() => {
    return !deepEqual(originalSettings.value, props.settings);
});

watch(isDirty, () => {
    emit("isDirty", isDirty.value);
});

// mutations
const { mutate, onDone, onError } = useMutation(updateCompanyMoodMonitorSettings);
onDone((response) => {
    // eslint-disable-next-line no-throw-literal
    if (!response.data.updateCompanyMoodMonitorSettings) throw false;
    store.dispatch("pushNotification", {
        type: "success",
        title: "Success",
        message: "You've successfully updated Mood monitor settings",
    });
    // emit loadCompany once sync issue is fixed on BE, until then just set lastest sestting if update is successful
    originalSettings.value = cloneDeep(props.settings);
});
onError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Something went wrong while updating settings.",
    });
});

function save() {
    const variables = {
        companyId: props.companyId,
        input: {
            formal: props.settings.formal,
        },
    };
    mutate(variables);
}

defineExpose({
    save,
});
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.mood_monitor_subtitle {
    @extend %subtitle3_style;
    margin-top: 30px;
    margin-bottom: 4px;
}

.mood_monitor_text {
    @extend %body2_style;
    margin-bottom: 14px;
}
</style>
