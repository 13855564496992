var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isReady)?_c(_setup.SettingsLayout,{attrs:{"headerProps":{
        title: _setup.title,
        backButtonTo: { path: '/divisions' },
        backButtonLabel: 'Divisions',
        tabs: _setup.tabs,
        activeTab: _setup.route.query.activeTab,
        onTabSelect: _setup.onTabSelect,
    }},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('h2',{staticClass:"header__subtitle body1 division__header__subtitle"},[_c(_setup.FontAwesomeIcon,{staticClass:"header__subtitle__icon",attrs:{"icon":['far', 'sitemap'],"size":"sm"}}),_c('span',[_vm._v("Division")])],1)]},proxy:true},{key:"main",fn:function(){return [(_setup.isGeneralTabActive)?_c('section',[(_setup.division.services.includes('APPRECIATION'))?_c(_setup.DivisionGeneralSettings,{ref:"generalTab",attrs:{"division":_setup.division,"isLoading":!_setup.isReady},on:{"isDirty":function($event){_setup.isUpdateGeneralDivisionDisabled = !$event}}}):_c('section',{staticClass:"invitation_block"},[_c('div',{staticClass:"invitation_info"},[_vm._v("Soon, settings for division will appear here.")])])],1):_vm._e()]},proxy:true},(_setup.isGeneralTabActive)?{key:"aside",fn:function(){return [_c('div',{staticClass:"aside-wrapper"},[_c(_setup.CreateUserButton,{on:{"click":function($event){return _setup.router.push({
                        name: 'CreateUser',
                        query: {
                            divisionId: _setup.division.id,
                            divisionName: _setup.division.name,
                            platforms: _setup.platforms,
                        },
                    })}}}),_c(_setup.DivisionMetadataOverview,{attrs:{"division":_setup.division}}),_c(_setup.DivisionServiceIdentifiers,{attrs:{"division":_setup.division},on:{"openDialog":_setup.openEditIdentifiers}}),_c(_setup.DivisionList,{attrs:{"divisionList":_setup.divisionList}}),(_setup.showEditIdentifiers)?_c(_setup.Popup,{attrs:{"disableScroll":""},on:{"close":function($event){_setup.showEditIdentifiers = false}}},[_c(_setup.EditIdentifiers,{attrs:{"data":_setup.identifiers,"type":"divison","loading":_setup.savingIdentifiers},on:{"save":function($event){return _setup.saveIdentifiers($event)},"close":function($event){_setup.showEditIdentifiers = false}}})],1):_vm._e()],1)]},proxy:true}:null,{key:"footer",fn:function(){return [_c(_setup.Button,{attrs:{"disabled":_setup.isUpdateGeneralDivisionDisabled},nativeOn:{"click":function($event){return _setup.updateDivision.apply(null, arguments)}}},[_vm._v(" Save changes ")])]},proxy:true}],null,true)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }