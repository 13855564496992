<template>
    <CompactTable
        title="Divisions"
        class="table"
        :columns="[
            { label: '', property: 'lang' },
            { label: 'Name', property: 'name' },
            { label: 'ID', property: 'externalId' },
        ]"
        :empty="data.data.length === 0"
        sortable
        :sortableColumns="['name', 'id']"
        :currentSorted="data.currentSorted"
        :current-sort-direction="data.currentSortDirection"
        v-on:sort="sort($event)"
        :searchable="data.searchEnabled"
        :searchableColumns="['name', 'id']"
        :searching="data.searchTerm !== null"
        v-on:search="search($event)"
        :paginate="data.pages > 1"
        :pages="data.pages"
        :currentPage="data.currentPage"
        v-on:pageChange="setPage($event)"
    >
        <TableRow v-for="(row, index) in data.data" v-bind:key="index" :active="isCurrentPage(row)">
            <TableCell compact class="language_cell">{{ row.language }}</TableCell>
            <TableCell compact class="division_cell">
                <router-link
                    v-if="!isCurrentPage(row)"
                    :to="{ path: getDivisionUrl(row), query: { activeTab: 'general' } }"
                    v-line-clamp:30="3"
                    class="division_link"
                >
                    {{ row.name }}
                </router-link>
                <span v-else v-line-clamp:30="3" class="active_division">{{ row.name }}</span>
            </TableCell>
            <TableCell compact style="display: flex; white-space: nowrap">{{
                row.externalId
            }}</TableCell>
        </TableRow>
        <template v-slot:empty>
            <span v-if="data.searchTerm === null">
                No divisions are linked to this company yet.
            </span>
            <span v-else>No results</span>
        </template>
    </CompactTable>
</template>

<script setup>
import {
    CompactTable,
    TableRow,
    TableCell,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { onMounted } from "vue";
import { useRoute } from "@/helpers/composition-helper";

const route = useRoute();

const props = defineProps({
    divisionList: {
        type: Array,
        required: true,
    },
});
const { data, setData, sort, search, setPage } = useTableState();

function isCurrentPage(item) {
    return item.id === route.params.id;
}
function getDivisionUrl(division) {
    return `/division/${division.id}`;
}

onMounted(async () => {
    setData(props.divisionList);
    sort({ column: "name" });
});
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.division_cell {
    padding-left: 4px !important;
}
.division_link,
.active_division {
    word-break: break-word !important;
    padding-right: 22px !important;
}
.division_link {
    color: $blue;
    text-decoration: none;
}
.table {
    margin-bottom: 40px;
}
.language_cell {
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    padding-right: 22px !important;
}
</style>
