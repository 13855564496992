import { required } from "@/validation/validators";

export const createTracebuzzConnection = () => ({
    innerConnectionData: {
        clientSecret: { required },
        username: { required },
        password: { required },
        currentPlatform: { required },
        apiUser: { required },
        questionnaire: {
            name: { required },
        },
        invitationLink: {
            required: (link, data) => data.invitationLink.includes("INVITATION_LINK"),
        },
    },
});

export const createKidsKonnectConnection = () => ({
    innerConnectionData: {
        fromDate: { required },
        fromDateType: { required },
        apiToken: { required },
        username: { required },
        password: { required },
        currentPlatform: { required },
        evApiKey: { required },
        evUsername: { required },
        questionnaire: {
            name: { required },
        },
    },
});
