import axios from "axios";
import util from "./util";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

function setInterceptors(apiClient) {
    apiClient.interceptors.request.use((request) => {
        // @todo: SP-204 fix headers
        let authHeaderValues = window.localStorage.getItem("evAuthHeaderValues");
        if (authHeaderValues) {
            authHeaderValues = JSON.parse(authHeaderValues);
            request.headers.common["X-Tevreden-Host"] = authHeaderValues.platform;
            request.headers.common["X-Tevreden-Username"] = authHeaderValues.username;
        } else {
            // @todo: remove this else clause when fixing https:/feedbackcompany.atlassian.net/browse/TT-1167
            request.headers.common["X-Tevreden-Host"] = "woningcorporatie";
        }
        request.headers.common["X-Tevreden-Realm"] = "support";
        request.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
        return request;
    });

    apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401 || error.response?.status === 403) {
                // @todo: SP-204 how to deal with this
                // window.location.reload(true);
            } else {
                return Promise.reject(error);
            }
        }
    );
}

const dashboardApiClient = axios.create({
    baseURL: util.methods.getDashboardApiUrl(),
});
setInterceptors(dashboardApiClient);

const dashboardApiV2Client = axios.create({
    baseURL: util.methods.getDashboardApiV2Url(),
});
setInterceptors(dashboardApiV2Client);

const usersApiClient = axios.create({
    baseURL: util.methods.getUsersApiUrl(),
});
setInterceptors(usersApiClient);

const dataApiClient = axios.create({
    baseURL: util.methods.getDataApiUrl(),
});
setInterceptors(dataApiClient);

export { dashboardApiClient, dashboardApiV2Client, usersApiClient, dataApiClient };
