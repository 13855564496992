<template>
    <SettingsLayout
        :loading="loading"
        :headerProps="{
            title: `Case #${String(route.params.caseId).padStart(4, '0')}`,
            backButtonTo: { name: 'FraudDetection' },
            backButtonLabel: 'Fraud detection',
        }"
        :largerGrid="true"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1">
                Turn your attention to detection for prevention of devastation.
            </h2>
        </template>
        <template v-slot:main>
            <ReviewsTable :reviews="statusData.reviews" :isLoading="loading" />
        </template>
        <template v-slot:aside>
            <MetadataOverview :metadata="status" :statusData="statusData" />
        </template>
    </SettingsLayout>
</template>

<script setup>
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import ReviewsTable from "@/components/fraud-detection/ReviewsTable.vue";
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { getFraudDetectionCase } from "@/graphql/fraud-detection/getFraudDetectionCase.gql";
import { format } from "date-fns";
import { ref, computed, onMounted } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "@/helpers/composition-helper";

const route = useRoute();
const loading = ref(true);
const statusData = ref(null);

const status = computed(() => {
    return [
        {
            key: "Case",
            value: statusData.value.formattedId,
        },
        {
            key: "Detected date",
            value: statusData.value.displayCreatedDate,
        },
        {
            key: "Last updated",
            value: statusData.value.displayModifiedDate,
        },
        {
            key: "Violated rules",
            value: statusData.value.rule,
        },
        {
            key: "Reviews",
            value: statusData.value.reviewCount,
        },
    ];
});

function displayDate(date) {
    if (!date) return "";
    return format(new Date(date), "MMM d, yyyy HH:mm");
}

function handleResult(result) {
    statusData.value = {
        ...result.fraudCase,
        displayCreatedDate: displayDate(result.fraudCase.dateCreated),
        displayModifiedDate: displayDate(result.fraudCase.dateModified),
        formattedId: String(result.fraudCase.id).padStart(4, "0"),
        // Mocking this until we get this data from the KC token
        creator: "Nando",
    };
    loading.value = false;
}
function fetchFraudCase() {
    const { result, onResult } = useQuery(getFraudDetectionCase, {
        caseId: route.params.caseId,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
}

onMounted(async () => {
    fetchFraudCase();
});
</script>
