<template>
    <SettingsLayout
        :headerProps="{
            title: 'Edit filter',
            backButtonTo: { path: '/manage-filters' },
            backButtonLabel: 'Manage filters',
        }"
        :loading="isLoading"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1">
                <span>Manage your filter template here.</span>
            </h2>
        </template>
        <template v-slot:main>
            <SettingsForm class="settings_form">
                <SettingsBlock class="settings_block" title="Filter name">
                    <SettingsFieldSet class="settings_fieldset" label="Create filter name">
                        <label class="body2">
                            Choose and fill in the name for your new filter template.
                        </label>
                        <TextInput
                            v-model="filter.name"
                            label="Filter name"
                            enableExternalValidation
                            :externalValidationError="$v.filter.name.$error"
                            :externalValidationErrorMessage="resolveErrorMessage($v.filter.name)"
                        />
                    </SettingsFieldSet>
                </SettingsBlock>
                <SettingsBlock class="settings_block" title="Filter settings">
                    <SettingsFieldSet class="settings_fieldset" label="">
                        <label class="subtitle3">Parent platform</label>
                        <label class="body2">
                            Choose and select the platform for your filter template.
                        </label>
                        <Dropdown
                            class="text_input_holder"
                            v-model="filter.platform"
                            placeholder="Choose platform"
                            disabled
                            :searchAble="platforms.length > 3"
                            :options="platforms"
                            enableExternalValidation
                            :externalValidationError="$v.filter.platform.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.filter.platform)
                            "
                        />
                        <label class="subtitle3">Category</label>
                        <label class="body2">
                            Choose by which category you want to filter on.
                        </label>
                        <Dropdown
                            class="text_input_holder"
                            v-model="filter.category"
                            placeholder="Choose category"
                            :searchAble="categories.length > 3"
                            :options="categories"
                            disabled
                            enableExternalValidation
                            :externalValidationError="$v.filter.category.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.filter.category)
                            "
                        />
                        <label class="subtitle3">Subcategory</label>
                        <label class="body2">
                            Choose by which subcategory you want to filter on.
                        </label>
                        <Dropdown
                            class="text_input_holder"
                            v-model="filter.subcategory"
                            placeholder="Choose subcategory"
                            :searchAble="subcategories.length > 3"
                            :options="subcategories"
                            disabled
                            enableExternalValidation
                            :externalValidationError="$v.filter.subcategory.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.filter.subcategory)
                            "
                        />
                    </SettingsFieldSet>
                </SettingsBlock>
            </SettingsForm>
        </template>
        <template v-slot:footer>
            <SettingsFooter>
                <Button @click.native="editFilter" :loading="isSaving">Save</Button>
                <Button class="cancel_button" buttonStyle="secondary" @click.native="cancel">
                    Cancel
                </Button>
            </SettingsFooter>
        </template>
    </SettingsLayout>
</template>

<script>
import * as Sentry from "@sentry/vue";
import { Dropdown, TextInput, Button } from "@feedbackcompany/feedback-company-vue-components";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import SettingsFooter from "@/components/layout/SettingsFooter.vue";
import { resolveErrorMessage, validationMixin, scrollToFirstError } from "@/validation";
import { getCreateFilterSchema } from "@/validation/schemas/createFilter";
import cloneDeep from "lodash.clonedeep";
import getPlatforms from "../graphql/getPlatforms.gql";
import getFilterTemplateCategories from "../graphql/research/getFilterTemplateCategories.gql";
import updateFilterTemplate from "../graphql/research/updateFilterTemplate.gql";
import getFilterTemplate from "../graphql/research/getFilterTemplate.gql";

export default {
    name: "CreateFilter",
    mixins: [validationMixin],
    components: {
        SettingsLayout,
        SettingsBlock,
        SettingsFieldSet,
        SettingsForm,
        SettingsFooter,
        TextInput,
        Button,
        Dropdown,
    },
    validations() {
        return getCreateFilterSchema();
    },
    data() {
        return {
            isSaving: false,
            filter: this.getInitialState(),
            platforms: [],
            categories: [],
            subcategories: [],
            subcategoryList: null,
            isLoading: true,
        };
    },
    methods: {
        resolveErrorMessage,
        scrollToFirstError,
        getInitialState() {
            return {
                name: "",
                platform: "",
                category: "",
                subcategory: "",
            };
        },
        cancel() {
            this.$router.push("/manage-filters");
        },
        async getCategories() {
            const response = await this.$apollo.query({
                query: getFilterTemplateCategories,
                variables: {
                    platform: this.filter.platform,
                },
                fetchPolicy: "network-only",
            });
            const filterTemplateCategories = cloneDeep(response.data.filterTemplateCategories);
            this.categories = filterTemplateCategories.map((category) => ({
                value: category.label,
                displayValue: category.name,
            }));
            this.subcategoryList = new Map(
                filterTemplateCategories.map((category) => [category.label, category.subcategories])
            );
        },
        setSubcategories() {
            this.subcategories = this.subcategoryList
                .get(this.filter.category)
                .map((subcategory) => ({ value: subcategory, displayValue: subcategory }));
        },
        async editFilter() {
            this.$v.$touch();
            if (this.$v.$error) {
                this.$nextTick(() => {
                    this.scrollToFirstError(this.$el);
                });
                return;
            }
            this.isSaving = true;
            try {
                const payload = {
                    mutation: updateFilterTemplate,
                    variables: {
                        templateId: this.filter.id,
                        name: this.filter.name,
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.updateFilterTemplate) {
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Updated",
                        message: `You\'ve successfully updated filter template “${this.filter.name}”.`,
                    });
                    this.$router.push("/manage-filters");
                } else {
                    await this.pushError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushError();
            }
            this.isSaving = false;
        },
        async pushError() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Something went wrong while creating the new filter template.",
            });
        },
        async setFilter(filterTemplate) {
            this.filter.id = filterTemplate.id;
            this.filter.name = filterTemplate.name;
            this.filter.platform = filterTemplate.platform;
            await this.getCategories();
            this.filter.category = filterTemplate.type;
            await this.setSubcategories();
            this.filter.subcategory = filterTemplate.attribute;
        },
    },
    async created() {
        const response = await this.$apollo.query({
            query: getPlatforms,
        });
        const platforms = cloneDeep(response.data.platforms);
        this.platforms = platforms.map((platform) => ({
            value: platform.name,
            displayValue: platform.name,
        }));

        const result = await this.$apollo.query({
            query: getFilterTemplate,
            variables: {
                templateId: this.$route.params.id,
            },
        });
        this.setFilter(cloneDeep(result.data.filterTemplate));
        this.isLoading = false;
    },
};
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 0px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }
}
.settings_fieldset .subtitle3 {
    display: block;
}

.text_input_holder {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 12px;

    &:last-of-type {
        margin-bottom: 0;
    }
}
.cancel_button {
    margin-left: 12px;
}
.header__subtitle {
    display: flex;
    align-items: center;
}
</style>
