/* eslint-disable class-methods-use-this, no-plusplus, no-restricted-globals, no-unused-vars, radix, no-unreachable */
import { splitByService } from "@/services/Evaluation";
import tense from "@/assets/mood-monitor/tense.svg";
import sad from "@/assets/mood-monitor/sad.svg";
import happy from "@/assets/mood-monitor/happy.svg";
import tired from "@/assets/mood-monitor/tired.svg";
import frustrated from "@/assets/mood-monitor/frustrated.svg";
import enthusiastic from "@/assets/mood-monitor/enthusiastic.svg";
import atease from "@/assets/mood-monitor/atease.svg";
import relaxed from "@/assets/mood-monitor/relaxed.svg";

const moodMonitorSeriesNames = [
    "widget_label_tense",
    "widget_label_relaxed",
    "widget_label_tired",
    "widget_label_at_ease",
    "widget_label_sad",
    "widget_label_enthusiastic",
    "widget_label_happy",
    "widget_label_frustrated",
];

const moodMonitorEmojiList = [
    { id: "at ease", image: atease },
    { id: "happy", image: happy },
    { id: "relaxed", image: relaxed },
    { id: "enthusiastic", image: enthusiastic },
    { id: "tense", image: tense },
    { id: "frustrated", image: frustrated },
    { id: "sad", image: sad },
    { id: "tired", image: tired },
];

export default class ChartService {
    getWidgetData(i18n, widget, dataSet, preview, fullScreen, widgetColClass) {
        const self = this;
        let error = null;
        const widgetData = {};
        if (widget.id) {
            widgetData.id = widget.id;
        }
        const { widgetTemplateOptions } = widget.widgetTemplate;

        if (!widgetColClass && preview) {
            widgetColClass = "col-md-12";
        }

        // We initialize the gauge specific option just in case
        widgetData.gaugeGradient = false;

        if (widgetTemplateOptions.length !== 0) {
            for (let iter = 0; iter < widgetTemplateOptions.length; iter++) {
                // We check for boolean values coming in as strings and convert them to true boolean.
                if (widgetTemplateOptions[iter].value.toString().toLowerCase() === "true") {
                    widgetTemplateOptions[iter].value = true;
                } else if (widgetTemplateOptions[iter].value.toString().toLowerCase() === "false") {
                    widgetTemplateOptions[iter].value = false;
                }

                // We check for a specific gauge gradient option and set accordingly
                if (widgetTemplateOptions[iter].key === "GAUGE_GRADIENT_OPTION") {
                    widgetData.gaugeGradient = widgetTemplateOptions[iter].value;
                }
            }
        }

        // widgetData.legend = true;
        widgetData.title = widget.title;

        widgetData.widgetTemplate = {};
        widgetData.widgetTemplate.benchmark = widget.widgetTemplate.benchmark;
        if (dataSet.target !== null && !isNaN(dataSet.target)) {
            widgetData.target = dataSet.target;
        }
        widgetData.xAxisName = dataSet.xAxisName;

        widgetData.debugInfo = dataSet.debugInfo;
        widgetData.widgetType = dataSet.widgetTemplateType;
        widgetData.displayType = dataSet.widgetTemplateType
            ? this.getHighchartsType(dataSet.series[0].displayType)
            : "TABLE";
        if (dataSet.widgetTemplateType === "GAUGE") {
            widgetData.displayType = "gauge";
        }
        if (dataSet.widgetTemplateType === "MOOD_MONITOR_GAUGE") {
            widgetData.displayType = "MOOD_MONITOR_GAUGE";
        }
        widgetData.series = dataSet.series;
        widgetData.labels = dataSet.xAxisLabels ? dataSet.xAxisLabels : [];

        let yAxisArray = [];

        const limits = this.getLimitValues(
            widgetData.series,
            dataSet.range ? dataSet.range[0].min : null,
            dataSet.range ? dataSet.range[0].max : null
        );

        yAxisArray.push(
            this.getYAxis(
                limits,
                dataSet.range ? dataSet.range[0].step : null,
                widget.title,
                widget.target,
                false
            )
        );

        const legendIndexes = [];
        let seriesIndex = 0;
        let oldGroupLength = 0;
        $.each(dataSet.groupedChartSeries, (index, groupInfo) => {
            const groupSize = groupInfo.size;
            let negativeReper = oldGroupLength;
            let positiveReper = oldGroupLength + groupSize;
            oldGroupLength += groupSize;
            for (let i = 0; i < groupSize; i++) {
                let dataIndex = 0;
                while (dataSet.series[seriesIndex].data[dataIndex] === null) {
                    dataIndex++;
                }
                if (dataSet.series[seriesIndex].data[dataIndex]) {
                    if (dataSet.series[seriesIndex].data[dataIndex][0] < 0) {
                        legendIndexes[seriesIndex] = ++negativeReper;
                    } else {
                        legendIndexes[seriesIndex] = --positiveReper;
                    }
                }
                seriesIndex++;
            }
        });

        const series = [];
        const availableWidthsForPieLegendDisplay = ["col-md-4", "col-md-3"];
        let showPieLegend = false;
        if (widgetData.displayType === "pie") {
            if (preview) {
                showPieLegend = false;
            } else {
                showPieLegend = availableWidthsForPieLegendDisplay.indexOf(widgetColClass) > -1;
            }
            const widgetView = $(`#widget-${widget.id}`);
            const pieSeries = [];

            let longestLabelWidth = 0;
            $.each(dataSet.series[0].data, (index, item) => {
                const yAxis = item ? item[0] : null;
                const zAxis = item ? item[1] : null;
                if (!preview) {
                    const label =
                        dataSet.xAxisLabels[index].displayValue +
                        widgetData.series[0].name +
                        yAxis +
                        zAxis;
                    const dimensions = self.calculateLabelDimensions(
                        label,
                        widgetView.css("font-size")
                    );
                    if (longestLabelWidth < dimensions.width) {
                        longestLabelWidth = dimensions.width;
                    }
                }
                pieSeries.push({
                    name: dataSet.xAxisLabels[index].displayValue,
                    y: yAxis,
                    z: zAxis,
                });
                if (isNaN(yAxis)) {
                    error = "Y_AXIS_MUST_BE_NUMBER";
                }
            });
            if (!error) {
                const width =
                    widgetView && widgetView.innerWidth() ? widgetView.innerWidth() : 1280;
                if (!preview && this.isPieTooBigForView(longestLabelWidth, width)) {
                    showPieLegend = true;
                }

                const showPercentage = this.showPercentage(widgetData.series[0].name);
                const dataLabelsInPie = {
                    distance: -40,
                    formatter() {
                        return this.y + showPercentage;
                    },
                };
                const dataLabelsPointer = {
                    enabled: !showPieLegend,
                    style: {
                        textShadow: "none",
                        width: "300px",
                    },
                    format: `<b>{point.name}</b> : ${i18n.t(
                        widgetData.series[0].name
                    )} ({point.y}${this.showPercentage(widgetData.series[0].name)})`,
                };
                series.push({
                    name: i18n.t(widgetData.series[0].name),
                    translateKey: widgetData.series[0].name,
                    data: pieSeries,
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: showPieLegend ? dataLabelsInPie : dataLabelsPointer,
                    showInLegend: showPieLegend,
                });
            }
        } else {
            $.each(widgetData.series, (sIndex, item) => {
                const tempData = [];
                const name = self.setSeriesName(i18n, item, widget);
                const stackName = item.sourceLabel ? item.sourceLabel : "default";
                const stackType = self.getStackingType(item.displayType);

                $.each(item.data, (dataIndex, data) => {
                    const yAxis = data ? data[0] : null;
                    const zAxis = data ? data[1] : null;
                    tempData.push({
                        y: yAxis,
                        z: zAxis,
                    });
                    if (isNaN(yAxis) && widgetData.displayType !== "MOOD_MONITOR_GAUGE") {
                        error = "Y_AXIS_MUST_BE_NUMBER";
                    }
                });

                // If there are no errors attached to the scope, proceed
                if (!error) {
                    // We get the color for the series that is currently being processed
                    // and store it temporarily as we will be using it for both axis and marker color
                    const seriesColor = self.getSeriesColor(
                        item,
                        widget.splitBys && widget.splitBys.length
                    );

                    // We build the series object itself and push onto the list of series to display
                    series.push({
                        marker: {
                            fillColor: seriesColor,
                            symbol: self.getSeriesSymbol(
                                item,
                                widget.splitBys && widget.splitBys.length
                            ),
                        },
                        type: self.getHighchartsType(item.displayType),
                        name,
                        color: seriesColor,
                        translateKey: item.name,
                        data: tempData,
                        yAxis: item.yAxis,
                        connectNulls: true,
                        stacking: stackType,
                        stack: stackName,
                        legendIndex: stackType === "normal" ? legendIndexes[sIndex] : null,
                        dataLabels: {
                            enabled: self.showDataLabels(item.displayType),
                            format: `{point.y}${self.showPercentage(item.name)}`,
                            allowOverlap: true,
                            style: {
                                textShadow: "0 0 3px #ffffff;",
                                textOutline: false,
                                color: "black",
                            },
                        },
                    });
                }
            });
        }

        let chartConfig = null;
        if (dataSet && !error) {
            yAxisArray = this.setGridLineWidth(yAxisArray);
            const xAxis = [];
            $.each(widgetData.labels, (index, label) => {
                xAxis.push(label.displayValue);
            });
            chartConfig = {
                chart: {
                    spacingLeft: 10,
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: widgetData.displayType,
                },
                title: {
                    text: "",
                },
                xAxis: {
                    categories: xAxis,
                    type: "",
                    labels: {
                        style: {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        },
                        useHTML: true,
                    },
                },
                yAxis: yAxisArray,
                credits: {
                    enabled: false,
                },
                navigation: {
                    buttonOptions: {
                        verticalAlign: "bottom",
                        align: "right",
                    },
                },
                exporting: {
                    filename: widgetData.title.replace(/[^\w\s-]/g, ""),
                    chartOptions: {
                        title: {
                            text: widgetData.title,
                        },
                    },
                    buttons: {
                        contextButton: {
                            enabled: false,
                        },
                    },
                },
                tooltip: {
                    useHTML: true,
                    padding: 0,
                    formatter() {
                        let numberOfRespondents = "";
                        if (this.point.options.z) {
                            numberOfRespondents = `<br/>${i18n.t("NUMBER_OF_RESPONDENTS")}: <b>${
                                this.point.options.z
                            }</b>`;
                        }
                        let title = "";
                        if (this.point.options.name) {
                            title = this.point.options.name;
                        } else if (this.x || this.x === 0) {
                            title = this.x.toString();
                        }
                        if (title !== "") {
                            title += "<br/>";
                        }

                        return `<div class="chart-tooltip">${title}<br/>${i18n.t(
                            this.series.userOptions.translateKey
                        )}: <b>${this.y}${self.showPercentage(
                            this.series.userOptions.translateKey
                        )}</b>${numberOfRespondents}</div>`;
                    },
                },
                legend: {
                    useHTML: true,
                    symbolRadius: 0,
                    labelFormatter() {
                        if (
                            typeof this.series !== "undefined" &&
                            typeof this.series.userOptions !== "undefined" &&
                            typeof this.series.userOptions.translateKey !== "undefined"
                        ) {
                            return `<b>${self.getDisplayValue(this.name)}: ${i18n.t(
                                this.series.userOptions.translateKey
                            )} (${this.y}${self.showPercentage(
                                this.series.userOptions.translateKey
                            )})</b>`;
                        }
                        return `<b>${self.getDisplayValue(this.name)}</b>`;
                    },
                },
                options: {},
                series,
            };

            if (widgetData.displayType === "bar") {
                chartConfig.chart.marginLeft = this.calculateBarChartMargin(
                    dataSet,
                    widgetColClass,
                    fullScreen
                );
                chartConfig.xAxis.labels.step = 1;
            } else if (widgetData.displayType === "gauge") {
                // only in the case of gauge charts do we substitute the active chart config with a complete custom one.
                const newConfig = this.getGaugeChartConfig(
                    chartConfig.series[0].data[0].y,
                    limits,
                    widgetData.gaugeGradient,
                    widgetColClass,
                    preview
                );
                if (newConfig != null) {
                    chartConfig = newConfig;
                }
            } else if (widgetData.displayType === "MOOD_MONITOR_GAUGE") {
                const newConfig = this.getMoodMonitorGaugeChartConfig(
                    chartConfig.series[0].data[0].y,
                    limits,
                    widgetData.gaugeGradient,
                    widgetColClass,
                    preview
                );
                if (newConfig != null) {
                    chartConfig = newConfig;
                }
            } else if (
                widgetData.displayType === "line" ||
                widgetData.displayType === "column" ||
                widgetData.displayType === "scatter"
            ) {
                chartConfig.chart.marginBottom = 200;
            }
        }

        const tableData = {};

        let widgetDataType = widgetData.widgetType;
        if (widgetData.displayType === "SINGLE_VALUE" || widgetData.displayType === "TABLE") {
            widgetDataType = widgetData.displayType;
        }

        for (let i = 0; i < dataSet.tableSets.length; i++) {
            for (let j = 0; j < dataSet.tableSets[i].length; j++) {
                dataSet.tableSets[i][j] = this.getTableDisplayValue(dataSet.tableSets[i][j]);
            }
        }

        tableData.tableSet = dataSet.tableSets;
        tableData.tableSeries = dataSet.tableSeries;
        tableData.tableGroups = dataSet.tableGroups;

        return {
            widgetType: widgetDataType,
            chartConfig,
            widget: widgetData,
            tableData,
            error,
        };
    }

    getHighchartsType(enumType) {
        switch (enumType) {
            case "TABLE":
                return "TABLE";
            case "BAR_STACKED":
            case "BAR_CHART":
                return "bar";
            case "PIE_CHART":
                return "pie";
            case "LINE_CHART":
                return "line";
            case "COLUMN_STACKED":
            case "COLUMN_CHART":
                return "column";
            case "SINGLE_VALUE":
                return "SINGLE_VALUE";
            case "GAUGE":
                return "solidgauge";
            case "SCATTER_CHART":
                return "scatter";
            default:
                return enumType || "TABLE";
        }
    }

    getLimitValues(series, yAxisMin, yAxisMax) {
        let min = null;
        let max = null;
        const result = {};

        $.each(series, (index, dataSource) => {
            $.each(dataSource.data, (dataIndex, data) => {
                if (data && data[0]) {
                    const value = data[0];
                    if (min === null) min = value;
                    if (max === null) max = value;
                    if (value > max) max = value;
                    if (value < min) min = value;
                }
            });
        });

        if (!isNaN(yAxisMin) && yAxisMin !== null) {
            result.min = min < yAxisMin ? min : yAxisMin;
        }
        if (!isNaN(yAxisMax) && yAxisMax !== null) {
            result.max = max > yAxisMax ? max : yAxisMax;
        }

        return result;
    }

    getYAxis(limits, step, label, target, opposite) {
        return {
            title: { text: "" },
            allowDecimals: false,
            min: limits.min,
            max: limits.max,
            tickInterval: step,
            gridLineWidth: 0,
            opposite,
            endOnTick: false,
            plotLines: [
                {
                    value: target === null ? undefined : target,
                    color: "#ff0000",
                    width: 2,
                    zIndex: 4,
                    label: {
                        text: "",
                    },
                },
            ],
        };
    }

    calculateLabelDimensions(text, fontSize) {
        const classes = ["labelDimensionCalculation"];
        const element = document.createElement("span");
        element.setAttribute("class", classes.join(" "));
        element.style.fontSize = fontSize;
        element.innerHTML = text;
        document.body.appendChild(element);
        const dimensions = {
            width: element.offsetWidth,
            height: element.offsetHeight,
        };
        element.parentNode.removeChild(element);
        return dimensions;
    }

    isPieTooBigForView(longestLabelWidth, widgetViewWidth) {
        if (widgetViewWidth < 400) {
            return true;
        }
        if (widgetViewWidth > 500) {
            return longestLabelWidth > widgetViewWidth / 2.5;
        }
        return longestLabelWidth * 4 > widgetViewWidth;

        // eslint-disable-next-line no-unreachable
        return false;
    }

    showPercentage(seriesName) {
        if (seriesName === "PERCENTAGE" || moodMonitorSeriesNames.includes(seriesName)) {
            return "%";
        }
        return "";
    }

    setSeriesName(i18n, series, widget) {
        if (
            widget.anonymization?.id &&
            widget.splitBys.length > 0 &&
            series.sourceLabel !== widget.anonymization.value
        ) {
            return i18n.t("PEER");
        }
        let seriesName = series.sourceLabel ? `${series.sourceLabel} - ` : "";
        seriesName += i18n != null ? i18n.t(series.name) : series.name;
        return seriesName;
    }

    getStackingType(displayType) {
        if (displayType === "BAR_STACKED" || displayType === "COLUMN_STACKED") {
            return "normal";
        }
        return "";
    }

    getSeriesColor(item, isSplitBy) {
        if (isSplitBy) {
            if (item.displayType === "BAR_STACKED" || item.displayType === "COLUMN_STACKED") {
                return splitByService.getColor(`${item.sourceLabel} - ${item.name}`);
            }
            return splitByService.getColor(item.sourceLabel);
        }
        return item.color;
    }

    getSeriesSymbol(item, isSplitBy) {
        if (isSplitBy) {
            if (item.displayType === "BAR_STACKED" || item.displayType === "COLUMN_STACKED") {
                return splitByService.getSymbol(`${item.sourceLabel} - ${item.name}`);
            }
            return splitByService.getSymbol(item.sourceLabel);
        }
        return item.color;
    }

    showDataLabels(displayType) {
        return (
            ["BAR_STACKED", "COLUMN_STACKED", "BAR_CHART", "COLUMN_CHART"].indexOf(displayType) > -1
        );
    }

    setGridLineWidth(axes) {
        let lineDensity = 0;
        let axisIndex = 0;
        for (let i = 0; i < axes.length; i++) {
            if (axes[i].tickInterval !== null) {
                const axisLineDensity = Math.abs(axes[i].max - axes[i].min) / axes[i].tickInterval;
                if (!isNaN(axisLineDensity) && axisLineDensity > lineDensity) {
                    lineDensity = axisLineDensity;
                    axisIndex = i;
                }
            }
            axes[i].labels = {
                style: {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            };
        }

        axes[axisIndex].gridLineWidth = 1;

        return axes;
    }

    getDisplayValue(label) {
        if (typeof label === "string") {
            label = label.replace("<=", "\u2264");
            label = label.replace("<", "\u003C");
            label = label.replace(">=", "\u2265");
            label = label.replace(">", "\u003E");
        }
        return label;
    }

    calculateBarChartMargin(dataSet, widgetColClass, fullScreen) {
        let longestLabelLength = 0;
        let longestLabel = "";

        $.each(dataSet.series[0].data, (index, item) => {
            // Conversion to string because some display values can be numbers etc.
            const label = `${dataSet.xAxisLabels[index].displayValue}`;
            if (label.length > longestLabelLength) {
                longestLabelLength = label.length;
                longestLabel = label;
            }
        });

        const marginCharacterPixelMultiplier = 7.5;
        let widgetLeftMargin = longestLabelLength * marginCharacterPixelMultiplier;

        // Rounding
        if (!(widgetLeftMargin === parseInt(widgetLeftMargin, 10))) {
            widgetLeftMargin = Math.round(widgetLeftMargin);
        }

        switch (widgetColClass) {
            case "col-md-12":
                if (widgetLeftMargin > 432) {
                    widgetLeftMargin = 432;
                }
                break;
            case "col-md-9":
                if (widgetLeftMargin > 324) {
                    widgetLeftMargin = 324;
                }
                break;
            case "col-md-8":
                if (widgetLeftMargin > 288) {
                    widgetLeftMargin = 288;
                }
                break;
            case "col-md-7":
                if (widgetLeftMargin > 252) {
                    widgetLeftMargin = 252;
                }
                break;
            case "col-md-6":
                if (widgetLeftMargin > 216) {
                    widgetLeftMargin = 216;
                }
                break;
            case "col-md-5":
                if (widgetLeftMargin > 180) {
                    widgetLeftMargin = 180;
                }
                break;
            case "col-md-4":
                if (widgetLeftMargin > 144) {
                    widgetLeftMargin = 144;
                }
                break;
            case "col-md-3":
                if (widgetLeftMargin > 108) {
                    widgetLeftMargin = 108;
                }
                break;
            default:
                widgetLeftMargin = 200;
        }

        if (fullScreen) {
            widgetLeftMargin = 432;
        }

        if (widgetLeftMargin <= 108) {
            widgetLeftMargin = 108;
        }

        return widgetLeftMargin;
    }

    getMoodMonitorGaugeChartConfig(value, limits, isGradient, widgetColClass, preview) {
        const color = ["happy", "enthusiastic", "at ease", "relaxed"].includes(value)
            ? "#6EDCB1"
            : "#FF0000";
        return {
            chart: {
                type: "solidgauge",
            },
            tooltip: {
                enabled: false,
            },
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
                allowHTML: true,
            },
            pane: {
                size: "70%",
                startAngle: 0,
                endAngle: 360,
                background: {
                    innerRadius: "75%",
                    outerRadius: "100%",
                    backgroundColor: "#eeeeee",
                    borderWidth: 0,
                    shape: "arc",
                },
            },
            plotOptions: {
                solidgauge: {
                    innerRadius: "75%",
                    radius: "100%",
                    dataLabels: {
                        useHTML: true,
                        zIndex: 1000,
                    },
                },
            },
            yAxis: {
                labels: {
                    distance: 40,
                    useHTML: true,
                    formatter(tick) {
                        if (!tick.isFirst) {
                            const index = tick.value / 12.5 - 1;
                            const emotion = moodMonitorEmojiList[index];
                            const imageUrl = `${window.location.protocol}//${window.location.host}${emotion.image}`;
                            if (emotion.id === value) {
                                return `<div style="width: 32px; height: 32px; box-shadow: 1px 1px 3px 1px grey; border-radius: 15px;">
                                            <img style="margin-left: 5px; margin-top: 5px; filter: brightness(0%);" src="${imageUrl}" title="${emotion.id}"></img>
                                        </div>`;
                            }
                            return `<div style="width: 22px; height: 22px;">
                                        <img src="${imageUrl}" title="${emotion.id}"></img>
                                    </div>`;
                        }
                    },
                },
                min: 0,
                max: 100,
                minorTickLength: 0,
                tickPosition: "outside",
                tickLength: 0,
                tickWidth: 0,
                tickInterval: 12.5,
                stops: [[100, color]],
            },
            series: [
                {
                    animation: false,
                    data: [100],
                    dataLabels: {
                        borderColor: "#F7F8F9",
                        backgroundColor: "#F7F8F9",
                        color: "#64748B",
                        format: `<span style="font-size:20px">${value}</span><br/>`,
                        y: -15,
                    },
                },
            ],
        };
    }

    getGaugeChartConfig(value, limits, isGradient, widgetColClass, preview) {
        const self = this;
        if (isNaN(value)) {
            return null;
        }
        // eslint-disable-next-line no-new-object
        const newConfig = new Object({});
        newConfig.current = value;

        // We get the number of decimals from the backend results
        newConfig.decimals = (newConfig.current.toString().split(".")[1] || []).length;
        if (newConfig.decimals > 2) {
            newConfig.decimals = 2;
        }

        /*
            We set limits:
            If the min limit value is not available we use the opposite of the max value or 0 if that isn't available either.
            If the max limit value is not available we use the first round base 10 number (10, 100, 1000...) that's higher than the current value.
             */

        if (!isNaN(limits.min) && limits.min <= newConfig.current) {
            newConfig.min = limits.min;
        } else if (newConfig.current > 0) {
            newConfig.min = 0;
        } else {
            newConfig.places = (-newConfig.current.toString().split(".")[0] || []).length;
            newConfig.ceiling = 10;

            for (let i = 1; i < newConfig.places; i++) {
                newConfig.ceiling *= 10;
            }
            newConfig.min = -(
                Math.ceil(-newConfig.current / newConfig.ceiling) * newConfig.ceiling
            );
        }

        if (!isNaN(limits.max) && limits.max >= newConfig.current) {
            newConfig.max = limits.max;
        } else if (newConfig.current < 0) {
            newConfig.max = 0;
        } else {
            newConfig.places = (newConfig.current.toString().split(".")[0] || []).length;
            newConfig.ceiling = 10;

            for (let i = 1; i < newConfig.places; i++) {
                newConfig.ceiling *= 10;
            }
            newConfig.max = Math.ceil(newConfig.current / newConfig.ceiling) * newConfig.ceiling;
        }

        newConfig.colorStops = [
            [0.0, "#369AE2"], // tevredenBlue
        ];

        if (isGradient) {
            newConfig.colorStops = [
                [0.15, "#DF5353"], // red
                [0.5, "#DDDF0D"], // yellow
                [0.85, "#55BF3B"], // green
            ];
        }

        /*
            Doesn't work because we use highcharts-ng:
            newConfig.colorGradient = [0, 0, 300, 0];
            */

        // Here we calculate font size based on the widget container, default is half of the dash size (col-md-6)
        let colSize = 6;
        const last2 = widgetColClass.slice(-2);
        if (last2[0] === "-") {
            colSize = parseInt(last2[1]);
        } else {
            colSize = parseInt(last2);
        }
        colSize *= 4;
        if (colSize < 20) {
            colSize = 20;
        }
        newConfig.mainLabelFontSize = colSize.toString();

        newConfig.mainLabelHeightOffset = this.calculateGaugeChartMainLabelYOffset(widgetColClass);

        newConfig.dataFormat = `{y:.${newConfig.decimals}f}`;
        newConfig.labelStyle = '<span style="color:#369AE2;">';
        newConfig.mainLabelFormat = `<div style="text-align:center; font-size:${newConfig.mainLabelFontSize}px; margin-top: -34px;">${newConfig.labelStyle}${newConfig.dataFormat}</span><br/></div>`;

        // Size for viewing in the dashboard
        newConfig.size = "100%";

        // We set a couple of styles here, the preview gives us more space so we can make things bigger.
        if (preview) {
            newConfig.mainLabelFontSize = "48";
            newConfig.mainLabelHeightOffset = -17;
        }

        newConfig.mainLabelFormat = `<div style="text-align:center; font-size:${newConfig.mainLabelFontSize}px; margin-top: -34px;">${newConfig.labelStyle}${newConfig.dataFormat}</span><br/></div>`;

        // We return the chart object directly.
        return {
            chart: {
                type: "solidgauge",
                marginTop: -200,
            },
            title: {
                text: "",
            },
            subtitle: {
                text: "",
            },
            credits: {
                enabled: false,
            },

            pane: {
                center: ["50%", "85%"],
                size: newConfig.size,
                startAngle: -90,
                endAngle: 90,
                background: {
                    innerRadius: "60%",
                    outerRadius: "100%",
                    shape: "arc",
                },
            },

            tooltip: {
                enabled: false,
            },

            // the value axis
            yAxis: [
                {
                    showFirstLabel: false,
                    showLastLabel: false,
                    stops: newConfig.colorStops,
                    min: newConfig.min,
                    max: newConfig.max,
                    lineWidth: 0,

                    /*
                    we set the tickInterval to the maximum value if we want just two ticks in total.
                    because we don't know the min and max before the user sets them we let highcharts calculate its own ticks.
                    //tickInterval: newConfig.max,
                    */

                    tickInterval: null,
                    minorTickInterval: null,
                    title: {
                        y: -70,
                    },
                    labels: {
                        enabled: false,
                    },
                },
                {
                    linkedTo: 0,
                    lineWidth: 0,
                    minorTickLength: 0,
                    tickPositions: [newConfig.min, newConfig.max],
                    tickLength: 0,
                    labels: {
                        x: 0,
                        y: this.calculateGaugeChartSideLabelYOffset(widgetColClass),
                        formatter() {
                            return self.getGaugeSideLabelFormat(
                                this.value,
                                newConfig.decimals,
                                newConfig.min,
                                newConfig.max,
                                widgetColClass
                            );
                        },
                    },
                },
            ],
            navigation: {
                buttonOptions: {
                    verticalAlign: "bottom",
                    align: "right",
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: "none",
                    },
                },
                solidgauge: {
                    dataLabels: {
                        y: newConfig.mainLabelHeightOffset,
                        borderWidth: 0,
                        useHTML: true,
                    },
                },
                series: {
                    animation: {
                        duration: 1500,
                    },
                },
            },
            series: [
                {
                    data: [
                        {
                            color: {
                                // linearGradient: newConfig.colorGradient,
                                stops: newConfig.colorStops,
                            },
                            y: newConfig.current,
                        },
                    ],
                    dataLabels: {
                        format: newConfig.mainLabelFormat,
                    },
                },
            ],
            decimals: newConfig.decimals,
        };
    }

    calculateGaugeChartMainLabelYOffset(widgetColClass) {
        switch (widgetColClass) {
            case "col-md-3":
                return 14;
            case "col-md-4":
                return 14;
            case "col-md-5":
                return 10;
            case "col-md-6":
            case "col-md-7":
                return 5;
            case "col-md-8":
                return -2;
            case "col-md-9":
                return -8;
            case "col-md-12":
                return -17;
            default:
                return 5;
        }
    }

    calculateGaugeChartSideLabelYOffset(widgetColClass) {
        switch (widgetColClass) {
            case "col-md-3":
                //
                return 13;
            case "col-md-4":
                //
                return 14;
            case "col-md-5":
                //
                return 16;
            case "col-md-6":
                //
                return 18;
            case "col-md-7":
                return 20;
            case "col-md-8":
                //
                return 22;
            case "col-md-9":
                //
                return 25;
            case "col-md-12":
                return 30;
            default:
                return 16;
        }
    }

    getGaugeSideLabelFormat(value, decimals, min, max, widgetColClass) {
        const untouchedValue = value;

        if (value.toString().indexOf(".") !== -1) {
            for (let i = 0; i < decimals - value.toString().split(".")[1].length; i++) {
                value += "0";
            }
        } else {
            if (decimals > 0) {
                value += ".";
            }
            for (let i = 0; i < decimals; i++) {
                value += "0";
            }
        }

        if (untouchedValue === max && min < 0 && max > 0) {
            value = `+${value.toString()}`;
        }

        // Here we calculate font size based on the widget container, default is half of the dash size (col-md-6)
        let colSize = 6;
        const last2 = widgetColClass.slice(-2);
        if (last2[0] === "-") {
            colSize = parseInt(last2[1]);
        } else {
            colSize = parseInt(last2);
        }
        colSize *= 3;
        if (colSize < 12) {
            colSize = 12;
        }

        if (colSize > 24) {
            colSize = 24;
        }

        const sideLabelFontSize = colSize.toString();

        let sideLabelFormat;

        sideLabelFormat = `<span style="color:#369AE2; font-size:${sideLabelFontSize}px;">`;

        sideLabelFormat = sideLabelFormat.concat(value.toString());
        sideLabelFormat = sideLabelFormat.concat("</span>");
        return sideLabelFormat;
    }

    getTableDisplayValue(value) {
        if (value === null) {
            return value;
        }
        if (value.sortValue) {
            value.sortValue = isNaN(value.sortValue) ? value.sortValue : Number(value.sortValue);
        }
        if (value.displayValue || value.displayValue === 0) {
            value.displayValue = value.displayValue.toString();
        }
        return value;

        return isNaN(value) ? value : Number(value);
    }
}
