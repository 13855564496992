<template>
    <div class="status-indicator">
        <FontAwesomeIcon
            :icon="['fas', availableIcons[status]]"
            class="status-icon"
            :class="`status-icon--${status.toLowerCase()}`"
        />
        <span class="status-description"
            ><slot>{{ status }}</slot></span
        >
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faClipboardList,
    faCommentAltExclamation,
    faTimesCircle,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ref } from "vue";

library.add(faClipboardList, faCommentAltExclamation, faTimesCircle, faCheckCircle);

const availableIcons = ref({
    CREATED: "message-exclamation",
    UNDER_INVESTIGATION: "clipboard-list",
    NO_FRAUD: "times-circle",
    CONFIRMED_FRAUD: "circle-check",
});

defineProps({
    status: {
        type: String,
        required: true,
        validator(status) {
            const statuses = ["CREATED", "UNDER_INVESTIGATION", "NO_FRAUD", "CONFIRMED_FRAUD"];
            if (!statuses.includes(status)) {
                /* eslint-disable no-console */
                console.error(
                    `[FC Warn] invalid status passed, supported statuses are: "${statuses.join(
                        ", "
                    )}".`
                );
                return false;
            }
            return true;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.status-indicator {
    display: flex;
    align-items: center;
    width: max-content;
}
.status-icon {
    font-size: 0.625rem;
    margin-right: 8px;

    &.status-icon--created {
        color: $blue;
    }

    &.status-icon--under_investigation {
        color: $navy;
    }

    &.status-icon--no_fraud {
        color: $green;
    }

    &.status-icon--confirmed_fraud {
        color: $red;
    }
}
.status-description {
    @extend %body1_style;
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 17px;
}
</style>
