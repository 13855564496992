/* eslint-disable class-methods-use-this */
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import store from "@/store";

export default class FilterTemplateService {
    getAll() {
        dashboardApiClient.get("/filter-templates").then((response) => {
            const filterTemplates = response.data;
            // eslint-disable-next-line no-plusplus
            for (let i = filterTemplates.length - 1; i >= 0; i--) {
                if (
                    filterTemplates[i].name.indexOf("REACTION_DATE") > -1 ||
                    filterTemplates[i].name.indexOf("SEND_DATE") > -1
                ) {
                    filterTemplates.splice(i, 1);
                }
            }
            store.commit("DashboardStore/setFilterTemplates", filterTemplates);
        });
    }

    getFilterCategories() {
        return dashboardApiClient.get("/filter-templates/categories");
    }

    save(filterTemplate) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .post("/filter-templates", filterTemplate)
                .then((response) => {
                    // eslint-disable-next-line no-shadow
                    const filterTemplate = response.data;
                    if (
                        filterTemplate.name.indexOf("REACTION_DATE") === -1 &&
                        filterTemplate.name.indexOf("SEND_DATE") === -1
                    ) {
                        store.commit("DashboardStore/addFilterTemplate", filterTemplate);
                    }
                    resolve(filterTemplate);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    rename(filterTemplateId, newName) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .put(`/filter-templates/${filterTemplateId}`, newName, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    store.commit("DashboardStore/renameFilterTemplate", {
                        id: filterTemplateId,
                        name: newName,
                    });
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    delete(filterTemplateId) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .delete(`/filter-templates/${filterTemplateId}`)
                .then(() => {
                    store.commit("DashboardStore/removeFilterTemplate", filterTemplateId);
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
