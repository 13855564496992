<template>
    <Table
        class="reviews_table"
        title="Reviews"
        :columns="[
            { label: 'Reviewer', property: 'reviewer' },
            { label: 'Email', property: 'email' },
            { label: 'Order nr.', property: 'orderNumber' },
            { label: 'Session ID', property: 'sessionId' },
            { label: 'IP address', property: 'ip' },
            { label: 'Review date', property: 'date' },
        ]"
        :disableHeaderTextWrap="true"
        sortable
        :empty="tableState.data.length === 0"
        :sortableColumns="['reviewer', 'email', 'orderNumber', 'sessionId', 'ip', 'date']"
        :currentPage="tableState.currentPage"
        :currentSorted="tableState.currentSorted"
        :currentSortDirection="tableState.currentSortDirection"
        :searchable="tableState.searchEnabled"
        :searchableColumns="['reviewer', 'email', 'orderNumber', 'sessionId', 'ip', 'date']"
        :searching="searchTerm !== null"
        :paginate="
            tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
        "
        :defaultPaginationSize="tableState.paginationSize"
        searchbarPlaceholder="Search"
        :pages="tableState.pages"
        @sort="sort($event)"
        @search="search($event)"
        @pageChange="onPageChange"
        @onPaginationSizeChange="setPaginationSize($event)"
    >
        <TableRow
            v-for="(row, index) in tableState.data"
            v-bind:key="index"
            v-bind:isLast="tableState.data.length - 1 === index"
            bordered
        >
            <TableCell>{{ row.reviewer }} </TableCell>
            <TableCell>{{ row.email }}</TableCell>
            <TableCell>{{ row.orderNumber }}</TableCell>
            <TableCell>{{ row.sessionId }}</TableCell>
            <TableCell>{{ row.ip }}</TableCell>
            <TableCell>
                <div>{{ displayDate(row.date) }}</div>
                <div class="tablecell_subtitle">{{ displayTime(row.date) }}</div>
            </TableCell>
        </TableRow>
        <template v-slot:empty>
            <div v-if="isLoading" class="loading-and-messages">
                <LoaderCircular />
            </div>
            <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                No results
            </span>
            <span v-else class="loading-and-messages"> No reviews </span>
        </template>
        <template v-slot:pagination-indicator>
            <PaginationIndicator
                :pages="tableState.pages"
                :currentPage="tableState.currentPage"
                :currentPageItemCount="tableState.data.length"
                :current-page-range-start="tableState.currentPageRangeStart"
                :current-page-range-end="tableState.currentPageRangeEnd"
                :itemCount="tableState.itemCount"
                itemDescription="detected cases"
                ofString="of"
            />
        </template>
    </Table>
</template>

<script setup>
import {
    LoaderCircular,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { format } from "date-fns";
import { ref, onMounted } from "vue";
import cloneDeep from "lodash.clonedeep";

const props = defineProps({
    isLoading: {
        type: Boolean,
    },
    reviews: {
        type: Array,
        required: true,
    },
});

// General table state
const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = ref(data);

function setTableData() {
    setData(cloneDeep(props.reviews));
}
function onPageChange(page) {
    setPage(page);
}
function displayDate(date) {
    if (!date) return "";
    return format(new Date(date), "dd/MM/yyyy");
}
function displayTime(date) {
    if (!date) return "";
    return format(new Date(date), "HH:mm");
}

onMounted(() => {
    setTableData();
});
</script>

<style lang="scss">
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.tablecell_subtitle {
    color: $grey_french;
}
</style>
