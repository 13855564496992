/* eslint-disable class-methods-use-this */
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";

export default class AxisService {
    constructor() {
        this.availableColors = [];
        this.colorsNotInUse = [];
    }

    getAvailableAxisLabels() {
        return dashboardApiClient.get("/axis-labels");
    }

    initAvailableColors() {
        return new Promise((resolve, reject) => {
            if (this.availableColors.length > 0) {
                resolve(this.availableColors);
            } else {
                dashboardApiClient
                    .get("/axis-colors")
                    .then((response) => {
                        this.availableColors = response.data;
                        resolve(this.availableColors);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    setColorsNotInUse(colorsNotInUse) {
        this.colorsNotInUse = colorsNotInUse;
    }

    defineColor(axisColor) {
        if (this.colorsNotInUse.length > 0) {
            const index = this.colorsNotInUse.indexOf(axisColor);
            if (index > -1) {
                this.colorsNotInUse.splice(index, 1);
            } else {
                const randomColorIndex = Math.floor(Math.random() * this.colorsNotInUse.length);
                axisColor = this.colorsNotInUse[randomColorIndex];
                this.colorsNotInUse.splice(randomColorIndex, 1);
            }
        } else {
            axisColor =
                this.availableColors[Math.floor(Math.random() * this.availableColors.length)];
        }
        return axisColor;
    }
}
