<template>
    <SettingsLayout
        :headerProps="{
            title: 'Create new user',
            backButtonTo: { path: backButtonUrl },
            backButtonLabel,
        }"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1">
                <template v-if="divisionName">
                    <font-awesome-icon
                        class="header__subtitle__icon"
                        :icon="['far', 'sitemap']"
                        size="sm"
                    />
                    <span>{{ divisionName }}</span>
                </template>
                <template v-else-if="companyName">
                    <font-awesome-icon
                        class="header__subtitle__icon"
                        :icon="['far', 'building']"
                        size="sm"
                    />
                    <span>{{ companyName }}</span>
                </template>
            </h2>
        </template>
        <template v-slot:main>
            <SettingsForm class="settings_form" ref="formEl">
                <SettingsBlock class="settings_block" title="User information">
                    <SettingsFieldSet
                        class="settings_fieldset"
                        label="Basic user information settings"
                    >
                        <label class="body2">
                            Here you fill in the basic user information. This information will be
                            shown on the user’s profile.
                        </label>

                        <label class="subtitle3">Name</label>
                        <TextInput
                            ref="firstNameField"
                            v-model="newUser.firstName"
                            label="First name"
                            enableExternalValidation
                            :externalValidationError="v$.newUser.firstName.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage(v$.newUser.firstName)
                            "
                        />
                        <TextInput
                            v-model="newUser.lastName"
                            label="Last name"
                            enableExternalValidation
                            :externalValidationError="v$.newUser.lastName.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage(v$.newUser.lastName)
                            "
                        />
                        <label class="subtitle3">Email</label>
                        <TextInput
                            v-model="newUser.email"
                            label="Email"
                            enableExternalValidation
                            :externalValidationError="v$.newUser.email.$error"
                            :externalValidationErrorMessage="resolveErrorMessage(v$.newUser.email)"
                        />
                        <label class="subtitle3">Generated username</label>
                        <label class="body2">
                            The username will be generated, so the numbers at the end are not final
                            yet. The final username is visible in the overview.
                        </label>
                        <TextInput :value="username" label="Username" disabled />
                    </SettingsFieldSet>
                </SettingsBlock>
                <SettingsBlock class="settings_block" title="Connection">
                    <SettingsFieldSet class="settings_fieldset" label="Connect user">
                        <label class="body2 language_body">
                            If needed, choose a Research user to give it’s corresponding rights to
                            this user.
                        </label>
                        <Dropdown
                            class="text_input_holder"
                            v-model="newUser.connection"
                            placeholder="Choose a connection"
                            searchAble
                            :options="connectionUsers"
                            :disabled="!hasUserPlatforms"
                        />
                        <span v-if="!hasUserPlatforms" class="unknown_platform">
                            The user can not linked because the platform is unknown. Please connect
                            a platform to the company (or division).
                        </span>
                    </SettingsFieldSet>
                </SettingsBlock>
                <SettingsBlock class="settings_block" title="Language">
                    <SettingsFieldSet class="settings_fieldset" label="Account language">
                        <label class="body2 language_body">
                            The account language defines the preferred communication about the
                            account such as login and passwords.
                        </label>
                        <Dropdown
                            class="text_input_holder"
                            v-model="newUser.keycloakLanguage"
                            placeholder="Choose a language"
                            :options="languages"
                            enableExternalValidation
                            :externalValidationError="v$.newUser.keycloakLanguage.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage(v$.newUser.keycloakLanguage)
                            "
                        />
                    </SettingsFieldSet>
                </SettingsBlock>
                <SettingsBlock
                    v-if="!$route.query.divisionId"
                    class="settings_block"
                    title="Select divisions"
                >
                    <Checkbox
                        class="checkbox"
                        label="I want to select the divisions"
                        v-model="enableSelectDivision"
                        @click="toggleCheckbox"
                    />
                    <SettingsFieldSet class="settings_fieldset" label="Select divisions">
                        <label class="body2 language_body">
                            Instead of having access to all divisions of this company, you can
                            assign specific divisions to this user
                        </label>
                        <div
                            v-for="(item, index) in usersDivisions"
                            :key="index"
                            class="division_picker"
                        >
                            <Dropdown
                                class="text_input_holder"
                                placeholder="Select a division"
                                v-model="item.id"
                                :disabled="!enableSelectDivision"
                                searchAble
                                :options="divisionsList"
                                enableExternalValidation
                                :externalValidationError="v$.$dirty && !item.id"
                                externalValidationErrorMessage="This field is required"
                            />
                            <InlineButton
                                v-if="enableSelectDivision"
                                grey
                                @click.native="prepareDelete(index)"
                            >
                                <font-awesome-icon
                                    class="delete_icon"
                                    slot="icon"
                                    :icon="['far', 'times']"
                                    size="lg"
                                />
                            </InlineButton>
                        </div>
                        <div
                            v-if="!usersDivisions.length || addingNewDivision"
                            class="division_picker"
                        >
                            <Dropdown
                                class="text_input_holder"
                                placeholder="Select a division"
                                :disabled="!enableSelectDivision"
                                v-model="newDivisionId"
                                searchAble
                                :options="unselectedDivisionsList"
                                enableExternalValidation
                                :externalValidationError="v$.newDivisionId.$error"
                                :externalValidationErrorMessage="
                                    resolveErrorMessage(v$.newDivisionId)
                                "
                            />
                            <InlineButton
                                grey
                                v-if="enableSelectDivision && addingNewDivision"
                                @click.native="addingNewDivision = false"
                            >
                                <font-awesome-icon
                                    class="delete_icon"
                                    slot="icon"
                                    :icon="['far', 'times']"
                                    size="lg"
                                />
                            </InlineButton>
                        </div>
                        <button
                            v-if="
                                enableSelectDivision &&
                                !addingNewDivision &&
                                unselectedDivisionsList.length !== 0
                            "
                            :disabled="!usersDivisions.length"
                            type="button"
                            class="add_more"
                            :class="{ add_more_disabled: !usersDivisions.length }"
                            @click="addingNewDivision = true"
                        >
                            <span>Add another</span>
                            <font-awesome-icon class="add_icon" :icon="['far', 'plus']" />
                        </button>
                    </SettingsFieldSet>
                </SettingsBlock>
            </SettingsForm>
        </template>
        <template v-slot:footer>
            <SettingsFooter>
                <Button
                    :loading="isSaving === 'createUser'"
                    :disabled="!isDirty || !!isSaving"
                    @click.native="callCreateUser"
                >
                    Create
                </Button>
                <Button
                    class="create_and_add_new_button"
                    buttonStyle="secondary"
                    :loading="isSaving === 'createAndAddNewUser'"
                    :disabled="!isDirty || !!isSaving"
                    @click.native="createAndAddNewUser"
                >
                    Create and add new
                </Button>
            </SettingsFooter>
            <ConfirmPopup
                v-if="!!popup"
                :title="popupTitle"
                :message="popupMessage"
                :confirmBtn="popup === popupOptions.DELETE_DIVISION ? 'Confirm' : 'Understood'"
                :cancel-btn="'Cancel'"
                @confirm="popupConfirmMethod"
                @cancel="popup = null"
                :closeAllowed="popup === popupOptions.DELETE_DIVISION"
            />
        </template>
    </SettingsLayout>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSitemap, faBuilding, faTimes, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import deepEqual from "deep-equal";
import {
    Dropdown,
    TextInput,
    Button,
    Checkbox,
    InlineButton,
    ConfirmPopup,
} from "@feedbackcompany/feedback-company-vue-components";
import * as Sentry from "@sentry/vue";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import SettingsFooter from "@/components/layout/SettingsFooter.vue";
import { createUser } from "@/graphql/createUser.gql";
import { getUserConnections } from "@/graphql/getUserConnections.gql";
import { resolveErrorMessage } from "@/validation/resolveErrorMessageComposition";
import { getCreateUserSettingsSchema } from "@/validation/schemas/createUser";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
import cloneDeep from "lodash.clonedeep";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { scrollToFirstError } from "@/validation";
import { useStore, useRouter } from "@/helpers/composition-helper";
import getUsers from "../graphql/overview/getUsers.gql";
import getCompany from "../graphql/overview/getCompany.gql";

library.add(faSitemap, faBuilding, faTimes, faPlus);

const store = useStore();
const router = useRouter();

const formEl = ref(null);

// props
const props = defineProps({
    companyId: {
        type: String,
    },
    companyName: {
        type: String,
    },
    divisionId: {
        type: String,
    },
    divisionName: {
        type: String,
    },
    platforms: {
        type: Array,
    },
});

function getRandomUsernameNumber() {
    return Math.floor(1000 + Math.random() * 9000);
}

const isSaving = ref(false);
const initialState = ref({
    firstName: null,
    lastName: null,
    email: null,
    keycloakLanguage: null,
    divisions: [],
});
const newUser = ref(cloneDeep(initialState.value));
const languages = ref([
    {
        value: "nl",
        displayValue: "Dutch",
    },
    {
        value: "en",
        displayValue: "English",
    },
]);
const usernameNumber = ref(getRandomUsernameNumber());
const connectionUsers = ref([]);
const firstNameField = ref(null);
const enableSelectDivision = ref(false);
const divisionsList = ref([]);
const deleteIndex = ref(null);
const usersDivisions = ref([]);
const popup = ref(null);
const popupOptions = ref({
    DELETE_DIVISION: "DELETE_DIVISION",
    UNSELECT_CHECKBOX: "UNSELECT_CHECKBOX",
});
const addingNewDivision = ref(false);
const newDivisionId = ref(null);

const v$ = useVuelidate(getCreateUserSettingsSchema(), {
    newUser,
    newDivisionId,
    enableSelectDivision,
    usersDivisions,
    addingNewDivision,
});

// workaround for composition api, find a way to use forEach in vulidate3
const areDivisionsValid = computed(() => {
    return v$.value.$dirty && !usersDivisions.value.find((el) => el.id === "");
});

// computed
const username = computed(() => {
    let userName = "";
    if (newUser.value.firstName) userName += newUser.value.firstName.toLowerCase();
    if (newUser.value.lastName) userName += newUser.value.lastName.toLowerCase();
    if (newUser.value.firstName && newUser.value.lastName) userName += usernameNumber.value;

    return userName.replaceAll(" ", "");
});

const backButtonUrl = computed(() => {
    if (props.divisionId) {
        return `/division/${props.divisionId}`;
    }
    return `/company/${props.companyId}`;
});

const backButtonLabel = computed(() => {
    if (props.divisionName && props.divisionId) {
        return props.divisionName;
    }

    if (props.companyName && props.companyId) {
        return props.companyName;
    }

    return "";
});

const hasUserPlatforms = computed(() => {
    return props.platforms?.length > 0;
});

const isDirty = computed(() => {
    return (
        !deepEqual(newUser.value, initialState.value) ||
        !deepEqual(newUser.value.divisions, usersDivisions.value)
    );
});

const popupTitle = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX) return "Warning";
    return "Attention";
});

const popupMessage = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX)
        return "It’s not possible to uncheck this option because this user has access to specific divisions. You CAN unselect this option if you delete the divisions it has access to first. After that you can unselect the select division part and then the user will have access to ALL divisions.";
    return "Deleting this division from the list would mean that this user can’t access this division anymore.<br><br> Are you sure you want to continue?";
});

const prunedDivisionsArray = computed(() => {
    return usersDivisions.value.filter((div) => {
        return !!div.id;
    });
});

const unselectedDivisionsList = computed(() => {
    const newUserDivs = usersDivisions.value.map((div) => div.id);
    return divisionsList.value.filter((div) => {
        return !newUserDivs.includes(div.value);
    });
});

function deleteDivision() {
    usersDivisions.value.splice(deleteIndex.value, 1);
    popup.value = null;
}

function userUnderstood() {
    enableSelectDivision.value = true;
    popup.value = null;
}

const popupConfirmMethod = computed(() => {
    if (popup.value === popupOptions.value.UNSELECT_CHECKBOX) return userUnderstood;
    return deleteDivision;
});

// mutation
const { mutate: createUserMutate, onDone, onError } = useMutation(createUser);
onDone((result) => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "User created",
        message: `You've successfully created user "${result.data.createUser.username}"`,
    });

    if (isSaving.value === "createUser") {
        router.push(backButtonUrl.value);
    } else {
        // clear form
        newUser.value = cloneDeep(initialState.value);
        v$.value.$reset();
        usernameNumber.value = getRandomUsernameNumber();

        // focus first field
        firstNameField.value.focus();
    }

    isSaving.value = false;
});

onError((error) => {
    Sentry.captureException(error);
    if (resolveGraphQLErrors(error)[0] === "EMAIL_ALREADY_TAKEN") {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Unique email error",
            message: "There is already a user created with that email address.",
        });
    } else {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "The user could not be created.",
        });
    }
    isSaving.value = false;
});

// methods
function validate() {
    v$.value.$touch();
    if (v$.value.$error || !!formEl.value.$el.querySelector("[class*=--invalid]")) {
        nextTick(() => {
            scrollToFirstError(formEl.value.$el);
        });
        return;
    }
    if (v$.value.$error || !areDivisionsValid.value) {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "The user could not be created. Check the fields for errors.",
        });
    }
    return !v$.value.$error;
}

function getCreateVariables() {
    const { firstName, lastName, email, keycloakLanguage, connection } = newUser.value;

    const variables = {
        input: {
            firstName,
            lastName,
            email,
            keycloakLanguage,
            connection: connection !== "Choose a connection" ? connection : null,
        },
        companyId: props.companyId || null,
        divisions: props.divisionId ? [props.divisionId] : null,
    };
    if (usersDivisions.value.length > 0) {
        variables.divisions = usersDivisions.value.map((division) => division.id);
    }
    return variables;
}
function callCreateUser() {
    const isValid = validate();
    if (!isValid) return;

    isSaving.value = "createUser";

    createUserMutate(getCreateVariables(), {
        refetchQueries: [{ query: getUsers }],
    });
}

function createAndAddNewUser() {
    const isValid = validate();
    if (!isValid) return;

    isSaving.value = "createAndAddNewUser";

    createUserMutate(getCreateVariables(), {
        refetchQueries: [{ query: getUsers }],
    });
}

function handleResult(result) {
    if (hasUserPlatforms.value) {
        const connections = cloneDeep(result.connections);
        connectionUsers.value = connections.map((item) => ({
            value: item,
            displayValue: item,
        }));
    }
}

function handleCompanyResult(result) {
    result.company.divisions.forEach((element) => {
        divisionsList.value.push({ value: element.id, displayValue: element.name });
    });
}

function fetchCompany() {
    const { result: companyResult, onResult: onCompanyResult } = useQuery(getCompany, {
        id: props.companyId,
    });
    if (companyResult.value) handleCompanyResult(companyResult.value);
    onCompanyResult((response) => {
        handleCompanyResult(response.data);
    });
}

function toggleCheckbox() {
    usersDivisions.value = prunedDivisionsArray.value;
    if (enableSelectDivision.value && usersDivisions.value.length > 0) {
        popup.value = popupOptions.value.UNSELECT_CHECKBOX;
    }
}

function prepareDelete(index) {
    if (!usersDivisions.value[index].id) {
        usersDivisions.value.splice(index, 1);
        return;
    }
    popup.value = popupOptions.value.DELETE_DIVISION;
}

onMounted(async () => {
    const { result, onResult } = await useQuery(getUserConnections, {
        platforms: props.platforms,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
});

defineExpose({
    isDirty,
});

watch(newDivisionId, () => {
    if (newDivisionId.value) usersDivisions.value.push({ id: newDivisionId.value });
    addingNewDivision.value = false;
    newDivisionId.value = null;
});

if (hasUserPlatforms.value) {
    const { result, onResult } = useQuery(getUserConnections, {
        platforms: props.platforms,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
}
if (!props.divisionId) {
    fetchCompany();
}
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 20px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }
}
.settings_fieldset * {
    display: inline-block;
    margin-bottom: 12px;
}
.settings_fieldset .subtitle3 {
    margin-top: 18px;
}
.settings_fieldset::v-deep .formgroup__header {
    padding: 0 0 0 0;
}
.text_input_holder {
    width: 100%;
}
.create_and_add_new_button {
    margin-left: 12px;
}
.header__subtitle {
    display: flex;
    align-items: center;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
.language_body {
    margin-bottom: 12px;
}
.settings_form::v-deep .formgroup__header {
    margin-bottom: 0;
}
.unknown_platform {
    @extend %body2_style;
    color: $red;
    padding-left: 14px;
    letter-spacing: 0px;
}

.division_picker {
    display: flex;
}

.delete_icon {
    margin-top: 12px;
}

.checkbox {
    margin-top: 20px;
}

.add_more {
    display: flex;
    align-items: center;
    @extend %button_typography_style;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0;
    color: $blue;

    &:hover {
        color: $blue_sapphire;
    }
    &_disabled {
        color: $grey_french;
        &:hover {
            color: $grey_french;
        }
    }

    span {
        padding-right: 8px;
    }
}
</style>
