<template>
    <div>
        <Table
            class="dashboards_table"
            title="Dashboard overview"
            :columns="[
                { label: 'Type', property: 'formattedType' },
                { label: 'Name', property: 'name' },
                { label: 'User', property: 'username' },
                { label: 'Parent platform', property: 'platform' },
            ]"
            disableHeaderTextWrap
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="['name', 'username', 'platform', 'formattedType']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="['name', 'username', 'platform', 'formattedType']"
            :searching="searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell>{{ row.formattedType }}</TableCell>
                <TableCell>
                    <router-link
                        :to="{
                            path: `/dashboards/${row.id}`,
                            query: { company: companyId, type: row.type },
                        }"
                        class="dashboard_link"
                    >
                        {{ row.name }}
                    </router-link>
                </TableCell>
                <TableCell>{{ row.username }}</TableCell>
                <TableCell>{{ row.platform }}</TableCell>
                <TableCell class="controls">
                    <IconButton
                        label=""
                        v-tippy="
                            row.type === 'DASHBOARD'
                                ? `Delete ${row.formattedType.toLowerCase()}`
                                : 'Cannot be deleted'
                        "
                        :class="{ 'delete-btn-disabled': row.type !== 'DASHBOARD' }"
                        class="delete-btn"
                        @click.native="row.type === 'DASHBOARD' ? openDeleteDialog(row) : ''"
                    >
                        <font-awesome-icon :icon="['far', 'trash-alt']" />
                    </IconButton>
                    <IconButton class="edit-btn" label="">
                        <router-link
                            :to="{
                                path: `/dashboards/${row.id}`,
                                query: { company: companyId, type: row.type },
                            }"
                            v-tippy="`Edit ${row.formattedType.toLowerCase()}`"
                        >
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No dashboards </span>
            </template>
            <template v-if="!isLoading && tableState.rawData.length > 10" v-slot:filter>
                <FilterButton
                    id="dashboards-filter"
                    @toggleFilter="openFilterTray"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#dashboards-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button
                                button-style="secondary"
                                @click.native="clearWidgetTemplatesFilters"
                            >
                                Clear all filters
                            </Button>
                            <Button @click.native="applyWidgetTemplateFilters"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Type</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="dashboard" label="Dashboard" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox
                                        v-model="responseOverview"
                                        label="Response overview"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="dashboards"
                    ofString="of"
                />
            </template>
        </Table>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            :message="`Are you sure you want to delete “${deletionDashboard.name}”? Once deleted, this <b>cannot be undone</b>.`"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            :confirmBtnIsLoading="deleting"
            @confirm="deleteDashboard"
            @cancel="closeConfirmDialog"
        />
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import {
    LoaderCircular,
    Button,
    Checkbox,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    FilterButton,
    Tray,
    IconButton,
    ConfirmPopup,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import cloneDeep from "lodash.clonedeep";
import { ref, onMounted, watch } from "vue";
import { useStore } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import deleteDashboardMutation from "../graphql/dashboards/deleteDashboard.gql";
import getCompanyDashboardViews from "../graphql/dashboards/getCompanyDashboards.gql";

library.add(faTrashAlt, faEdit);

const store = useStore();

const TYPE = {
    DASHBOARD: "Dashboard",
    RESPONSE_OVERVIEW: "Response overview",
};

const isLoading = ref(true);
const showConfirmPopup = ref(false);
const deletionDashboard = ref(null);
const deleting = ref(false);
const left = ref(0);

// props
const props = defineProps({
    companyId: {
        type: String,
        required: true,
    },
});

const {
    data,
    setData,
    sort,
    search,
    searchTerm,
    setPage,
    setPaginationSize,
    filtersApplied,
    applyFilters,
    clearFilters,
    setFilters,
} = useTableState();
const tableState = data;

// Filter state
const filterTrayOpenPercentage = ref(0);
const setFilterOpenPercentage = (percentage) => {
    filterTrayOpenPercentage.value = percentage;
};

const dashboard = ref(false);
const responseOverview = ref(false);

const dashboardLocal = ref(false);
const responseOverviewLocal = ref(false);

const applyWidgetTemplateFilters = () => {
    dashboardLocal.value = dashboard.value;
    responseOverviewLocal.value = responseOverview.value;

    if (dashboardLocal.value || responseOverviewLocal.value) {
        applyFilters();
    } else {
        clearFilters();
    }
    filterTrayOpenPercentage.value = 0;
};

const clearWidgetTemplatesFilters = () => {
    clearFilters();
    dashboard.value = false;
    responseOverview.value = false;
};

setFilters([
    ({ formattedType }) => {
        let allowedTypes = [];

        if (dashboardLocal.value) {
            allowedTypes.push(TYPE.DASHBOARD);
        }

        if (responseOverviewLocal.value) {
            allowedTypes.push(TYPE.RESPONSE_OVERVIEW);
        }

        if (allowedTypes.length === 0) {
            allowedTypes = [TYPE.DASHBOARD, TYPE.RESPONSE_OVERVIEW];
        }

        return allowedTypes.includes(formattedType);
    },
]);

function onPageChange(page) {
    setPage(page);
}

function handleResult(results) {
    const companyDashboardViews = cloneDeep(results.companyDashboardViews);
    companyDashboardViews.forEach((element) => {
        const formattedType = element.type?.replaceAll("_", " ");

        switch (element.type) {
            case "RESULTS":
                // Special case: RESULTS needs to be replaced by "Dashboard".
                element.formattedType = "Dashboard";
                break;
            default:
                if (formattedType) {
                    element.formattedType =
                        formattedType.charAt(0) + formattedType.slice(1).toLowerCase();
                }

                break;
        }
    });

    companyDashboardViews.sort((item1, item2) => (item1.name > item2.name ? 1 : -1));
    setData(companyDashboardViews);
    isLoading.value = false;
}

function getDashboards() {
    const { result, onResult } = useQuery(getCompanyDashboardViews, {
        companyId: props.companyId,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
}

function openFilterTray() {
    setFilterOpenPercentage(1);
}
function openDeleteDialog(lDashboard) {
    showConfirmPopup.value = true;
    deletionDashboard.value = lDashboard;
}
function closeConfirmDialog() {
    showConfirmPopup.value = false;
    deletionDashboard.value = null;
    deleting.value = false;
}

function showError() {
    deleting.value = false;
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while trying to delete dashboard.",
    });
}

// mutations
const { mutate, onDone, onError } = useMutation(deleteDashboardMutation);
onDone((response) => {
    if (response.data.deleteDashboard) {
        store.dispatch("pushNotification", {
            type: "success",
            title: "Deleted",
            message: `Dashboard “${deletionDashboard.value.name}” has successfully been deleted.`,
        });
        closeConfirmDialog();
        getDashboards();
    } else {
        showError();
    }
});
onError((error) => {
    Sentry.captureException(error);
    showError();
});

function deleteDashboard() {
    deleting.value = true;
    const variables = {
        id: deletionDashboard.value.id,
    };
    mutate(variables, {
        refetchQueries: [
            { query: getCompanyDashboardViews, variables: { companyId: props.companyId } },
        ],
    });
}

watch(filterTrayOpenPercentage, () => {
    const filterButton = document.getElementById("dashboards-filter");
    left.value = filterButton.offsetLeft - 335;
});

onMounted(async () => {
    getDashboards();
});
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.dashboards_table {
    width: 100%;
    margin-bottom: 160px;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.dashboard_link {
    color: $blue;
    text-decoration: none;
}
.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 20px 32px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 12px;
        width: 160px;
    }
}
.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $red;
        }
    }
    .clone-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
    .edit-btn {
        margin-right: 6px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
    .delete-btn-disabled {
        color: $grey_french;
        cursor: default;
        &:hover {
            cursor: default;
            color: $grey_french;
        }
    }
}
</style>
