/* eslint-disable class-methods-use-this */
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";

export default class AnonymizationService {
    save(widgetId, anonymization) {
        return new Promise((resolve, reject) => {
            const url = `/widgets/${widgetId}/anonymization`;
            let apiRequest;
            if (anonymization.id) {
                apiRequest = dashboardApiClient.put(url, anonymization);
            } else {
                apiRequest = dashboardApiClient.post(url, anonymization);
            }
            apiRequest
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    delete(widgetId, anonymizationId) {
        return dashboardApiClient.delete(`/widgets/${widgetId}/anonymization/${anonymizationId}`);
    }
}
