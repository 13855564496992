<template>
    <SettingsLayout
        v-if="isReady"
        :headerProps="{
            title,
            backButtonTo: { path: '/divisions' },
            backButtonLabel: 'Divisions',
            tabs: tabs,
            activeTab: route.query.activeTab,
            onTabSelect: onTabSelect,
        }"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1 division__header__subtitle">
                <font-awesome-icon
                    class="header__subtitle__icon"
                    :icon="['far', 'sitemap']"
                    size="sm"
                />
                <span>Division</span>
            </h2>
        </template>
        <template v-slot:main>
            <section v-if="isGeneralTabActive">
                <DivisionGeneralSettings
                    v-if="division.services.includes('APPRECIATION')"
                    ref="generalTab"
                    :division="division"
                    :isLoading="!isReady"
                    @isDirty="isUpdateGeneralDivisionDisabled = !$event"
                />
                <section v-else class="invitation_block">
                    <div class="invitation_info">Soon, settings for division will appear here.</div>
                </section>
            </section>
        </template>

        <template v-if="isGeneralTabActive" v-slot:aside>
            <div class="aside-wrapper">
                <CreateUserButton
                    @click="
                        router.push({
                            name: 'CreateUser',
                            query: {
                                divisionId: division.id,
                                divisionName: division.name,
                                platforms: platforms,
                            },
                        })
                    "
                />
                <DivisionMetadataOverview :division="division" />
                <DivisionServiceIdentifiers
                    :division="division"
                    @openDialog="openEditIdentifiers"
                />
                <DivisionList :divisionList="divisionList" />
                <Popup
                    v-if="showEditIdentifiers"
                    @close="showEditIdentifiers = false"
                    disableScroll
                >
                    <EditIdentifiers
                        @save="saveIdentifiers($event)"
                        @close="showEditIdentifiers = false"
                        :data="identifiers"
                        type="divison"
                        :loading="savingIdentifiers"
                    />
                </Popup>
            </div>
        </template>

        <template v-slot:footer>
            <Button :disabled="isUpdateGeneralDivisionDisabled" @click.native="updateDivision">
                Save changes
            </Button>
        </template>
    </SettingsLayout>
</template>

<script setup>
import { Button, Popup } from "@feedbackcompany/feedback-company-vue-components";
import { faSitemap } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import CreateUserButton from "@/components/CreateUserButton.vue";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import DivisionMetadataOverview from "@/components/DivisionMetadataOverview.vue";
import DivisionServiceIdentifiers from "@/components/DivisionServiceIdentifiers.vue";
import DivisionList from "@/components/DivisionList.vue";
import { updateDivisionServiceIdentifiers } from "@/graphql/updateDivisionServiceIdentifiers.gql";
import cloneDeep from "lodash.clonedeep";
import { ref, onMounted, computed } from "vue";
import { useRoute, useStore, useRouter } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import EditIdentifiers from "../components/EditIdentifiers.vue";
import getDivisionQuery from "../graphql/overview/getDivision.gql";
import DivisionGeneralSettings from "../components/DivisionGeneralSettings.vue";

const route = useRoute();
const store = useStore();
const router = useRouter();

library.add(faSitemap);

// data
const division = ref({});
const isReady = ref(false);
const tabs = ref([
    { name: "general", displayName: "General" },
    // Temporary disabled. See issue TT-1215.
    // { name: 'data', displayName: 'Data' },
]);
const generalTab = ref(null);
const showEditIdentifiers = ref(false);
const savingIdentifiers = ref(false);
const editedIdentifiers = ref({});
const isUpdateGeneralDivisionDisabled = ref(true);

// computed
const title = computed(() => {
    return division.value?.name ? division.value.name : "";
});
const divisionList = computed(() => {
    return [division.value, ...division.value.sisterDivisions];
});
const isGeneralTabActive = computed(() => {
    // return route.query?.activeTab === "general";
    // temp, since we have only one tab and route is not responsive until
    // we update to vue 3
    return true;
});

const identifiers = computed(() => {
    return {
        platform: division.value.platform,
        subPlatform: division.value.subPlatform,
        externalId: division.value.externalId,
        entityType: division.value.entityType,
        entityId: division.value.entityId,
    };
});

const platforms = computed(() => {
    if (division.value.platform) {
        return [division.value.platform];
    }
    if (division.value.company.platform) {
        return [division.value.company.platform];
    }
    return null;
});

function onTabSelect(name) {
    router.replace({ name: "Division", query: { activeTab: name } });
}

function handleResult(result) {
    division.value = cloneDeep(result.division);
    isReady.value = true;
}

function getDivision() {
    const { result, onResult } = useQuery(
        getDivisionQuery,
        {
            id: route.params.id,
        },
        { fetchPolicy: "network-only" }
    );
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
}

function updateDivision() {
    generalTab.value?.save();
}

function openEditIdentifiers() {
    showEditIdentifiers.value = true;
}

function pushIdentifiersError() {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while trying to save identifiers.",
    });
}

// mutation
const {
    mutate: updateIdentifiers,
    onDone,
    onError,
} = useMutation(updateDivisionServiceIdentifiers);
onDone((result) => {
    if (result.data.updateDivisionServiceIdentifiers) {
        division.value.platform = editedIdentifiers.value.platform;
        division.value.subPlatform = editedIdentifiers.value.subPlatform;
        division.value.entityType = editedIdentifiers.value.entityType;
        division.value.entityId = editedIdentifiers.value.entityId;
        store.dispatch("pushNotification", {
            type: "success",
            title: "Saved",
            message: "The Service identifiers have successfully been changed.",
        });
        showEditIdentifiers.value = false;
        savingIdentifiers.value = false;
    } else {
        pushIdentifiersError();
    }
    editedIdentifiers.value = {};
});

onError((error) => {
    Sentry.captureException(error);
    pushIdentifiersError();
    savingIdentifiers.value = false;
    editedIdentifiers.value = {};
});

function saveIdentifiers(identifierValues) {
    const variables = {
        input: {
            platform: identifierValues.platform,
            subPlatform: identifierValues.subPlatform,
            entityType: identifierValues.entityType,
            entityId: identifierValues.entityId,
        },
        divisionId: division.value.id,
    };
    editedIdentifiers.value = variables.input;
    savingIdentifiers.value = true;
    updateIdentifiers(variables);
}

onMounted(() => {
    if (!route.query.activeTab) {
        onTabSelect("general");
    }
    getDivision();
});
</script>

<script>
// TODO: remove this when we update router to version to one compatible with composition api
// and add watcher in setup insted
export default {
    watch: {
        "$route.params.id": {
            handler(oldValue, newValue) {
                if (oldValue !== newValue) this.$router.go();
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.division__header__subtitle {
    margin: 0;
    line-height: 20px;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
.invitation_block {
    flex-direction: column;
    background: $white;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
}
.invitation_info {
    @extend %body1_style;
}
</style>
