import { required, isOneOf } from "@/validation/validators";

export const newQuestionnaireSchema = () => ({
    newQuestionnaire: {
        type: { required, isOneOf: isOneOf(["EVALUATION", "APPRECIATION"]) },
        name: { required },
        defaultLanguage: {
            required,
            isOneOf: isOneOf(["nl", "en", "es", "fr"], ["Dutch", "English", "French", "Spanish"]),
        },
        formal: { required },
    },
});
