<template>
    <div class="error">
        <hgroup>
            <h1 class="headline1">401</h1>
            <h2 class="headline2">Unauthorized</h2>
        </hgroup>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";

Sentry.captureMessage("Unauthorized access!");
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
