<template>
    <MetadataOverview title="Last events" :metadata="metadata" />
</template>

<script setup>
import { computed } from "vue";
import MetadataOverview from "@/components/CompanyDataTab/CompanyMetadataOverview.vue";
import { format } from "date-fns";

// props
defineProps({
    company: {
        type: Object,
        required: true,
    },
});

// methods
function displayDate(date) {
    if (!date) return "";
    return format(new Date(date), "MMM d, yyyy HH:mm");
}

// computed
const metadata = computed(() => {
    return [
        {
            key: "Invitations scheduled",
            value: displayDate(new Date()),
        },
        {
            key: "Review received",
            value: displayDate(new Date()),
        },
        {
            key: "Last logged in",
            value: displayDate(new Date()),
        },
    ];
});
</script>

<style lang="scss" scoped />
