<template>
    <SettingsLayout
        :class="{ company_layout: !activeTab === 'general' }"
        v-if="isReady"
        :headerProps="{
            title,
            backButtonTo: { path: '/companies' },
            backButtonLabel: 'Companies',
            tabs: tabs,
            activeTab: $route.query.activeTab,
            onTabSelect: onTabSelect,
        }"
        :largerGrid="activeTab === 'data'"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1 company__header__subtitle">
                <font-awesome-icon
                    class="header__subtitle__icon"
                    :icon="['far', 'building']"
                    size="sm"
                />
                <span>Company</span>
            </h2>
        </template>
        <template v-slot:main>
            <section v-if="activeTab === 'general'">
                <CompanyGeneralSettings
                    ref="general"
                    :company="company"
                    :isLoading="isLoadingCompany"
                    @isDirty="isUpdateGeneralCompanyDisabled = !$event"
                />
            </section>
            <section v-else-if="activeTab === 'users'">
                <CompanyUsers :users="users" :isLoading="isLoading" @refreshUsers="loadUsers" />
            </section>
            <section v-else-if="activeTab === 'questionnaires'">
                <CompanyQuestionnaires
                    :companyId="company.id"
                    :companyPlatform="company.platform"
                />
                <Popup v-if="showCreateQuestionnaire" @close="showCreateQuestionnaire = false">
                    <CreateNewQuestionnaire
                        :company="company"
                        @saveQuestionnaire="createQuestionnaire($event)"
                    />
                </Popup>
            </section>
            <section v-else-if="activeTab === 'dashboards'">
                <DashboardsOverview :companyId="company.id" />
                <Popup
                    v-if="showCreateDashboard"
                    @close="showCreateDashboard = false"
                    disableScroll
                >
                    <CreateNewDashboard
                        @save="createNewDashboard($event)"
                        @close="showCreateDashboard = false"
                        :platforms="
                            company.platform ? [company.platform] : company.divisionPlatforms
                        "
                        :entities="entities"
                        :isResponseOverview="isResponseOverview"
                        :loading="savingDashboard"
                    />
                </Popup>
            </section>
            <section v-else-if="activeTab === 'connections'">
                <CompanyConnections :company="company" />
            </section>
            <section v-else-if="activeTab === 'data'">
                <DivisionDataTable />
            </section>
            <section v-else-if="activeTab === 'moodMonitor'">
                <MoodMonitorSettings
                    ref="moodMonitor"
                    :companyId="company.id"
                    :settings="company.moodMonitorSettings"
                    @isDirty="isUpdateCompanyDisabled = !$event"
                    @loadCompanyData="loadCompanyData()"
                />
            </section>
            <ConfirmPopup
                v-if="intendedTabSwitch !== null"
                title="Warning"
                message="There are unsaved changes in this tab. Please save or cancel changes before proceeding."
                confirm-btn="Save"
                confirmButtonStyle="primary"
                :confirmBtnIsLoading="confirmBtnIsLoading"
                @confirm="handleConfirmation"
                @cancel="intendedTabSwitch = null"
            />
        </template>

        <template v-if="activeTab === 'general'" v-slot:aside>
            <CreateUserButton
                @click="
                    $router.push({
                        name: 'CreateUser',
                        query: {
                            companyId: `${company.id}`,
                            companyName: company.name,
                            platforms: company.platform
                                ? [company.platform]
                                : company.divisionPlatforms,
                        },
                    })
                "
            />
            <CompanyMetadataOverview :company="company" />
            <CompanyServiceIdentifiers :company="company" @openDialog="openEditIdentifiers" />
            <DivisionList :divisionList="company.divisions" />
            <Popup v-if="showEditIdentifiers" @close="showEditIdentifiers = false" disableScroll>
                <EditIdentifiers
                    @save="saveIdentifiers($event)"
                    @close="showEditIdentifiers = false"
                    :data="identifiers"
                    :loading="savingIdentifiers"
                />
            </Popup>
        </template>
        <template v-else-if="activeTab === 'data'" v-slot:aside>
            <div><CompanyLastEvent :company="company" /></div>
        </template>
        <template v-if="activeTab === 'general'" v-slot:footer>
            <Button :disabled="isUpdateGeneralCompanyDisabled" @click.native="updateCompany">
                Save changes
            </Button>
        </template>
        <template v-else-if="activeTab === 'questionnaires'" v-slot:footer>
            <Button @click.native="openCreateQuestionnaire">New questionnaire</Button>
        </template>
        <template v-else-if="activeTab === 'moodMonitor'" v-slot:footer>
            <Button :disabled="isUpdateCompanyDisabled" @click.native="updateCompany">
                Save changes
            </Button>
        </template>
        <template v-else-if="activeTab === 'dashboards'" v-slot:footer>
            <Button @click.native="openCreateDashboard(false)" class="new_dashboard">
                New dashboard
            </Button>
            <Button @click.native="openCreateDashboard(true)" buttonStyle="secondary">
                New response overview
            </Button>
        </template>
    </SettingsLayout>
</template>

<script>
import { Button, Popup, ConfirmPopup } from "@feedbackcompany/feedback-company-vue-components";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import CompanyLastEvent from "@/components/CompanyDataTab/CompanyLastEvent.vue";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import DivisionDataTable from "@/components/DivisionDataTab/DivisionDataTable.vue";
import CompanyMetadataOverview from "@/components/CompanyMetadataOverview.vue";
import DivisionList from "@/components/DivisionList.vue";
import CreateUserButton from "@/components/CreateUserButton.vue";
import CompanyUsers from "@/components/CompanyUsers.vue";
import CompanyQuestionnaires from "@/components/CompanyQuestionnaires.vue";
import CompanyConnections from "@/components/CompanyConnections.vue";
import DashboardsOverview from "@/components/DashboardsOverview.vue";
import CreateNewQuestionnaire from "@/components/CreateNewQuestionnaire.vue";
import { updateCompanyServiceIdentifiers } from "@/graphql/updateCompanyServiceIdentifiers.gql";
import { createDashboard } from "@/graphql/dashboards/createDashboard.gql";
import cloneDeep from "lodash.clonedeep";
import getUsersByCompany from "../graphql/overview/getUsersByCompany.gql";
import EditIdentifiers from "../components/EditIdentifiers.vue";
import CreateNewDashboard from "../components/CreateNewDashboard.vue";
import getCompany from "../graphql/overview/getCompany.gql";
import CompanyServiceIdentifiers from "../components/CompanyServiceIdentifiers.vue";
import MoodMonitorSettings from "../components/mood-monitor/MoodMonitorSettings.vue";
import CompanyGeneralSettings from "../components/CompanyGeneralSettings.vue";

library.add(faBuilding);

export default {
    name: "Company",
    components: {
        Button,
        CreateUserButton,
        SettingsLayout,
        CompanyMetadataOverview,
        FontAwesomeIcon,
        DivisionList,
        CompanyUsers,
        CompanyQuestionnaires,
        CompanyConnections,
        CompanyServiceIdentifiers,
        Popup,
        EditIdentifiers,
        CompanyLastEvent,
        DashboardsOverview,
        CreateNewDashboard,
        DivisionDataTable,
        MoodMonitorSettings,
        ConfirmPopup,
        CompanyGeneralSettings,
        CreateNewQuestionnaire,
    },
    data() {
        return {
            company: {},
            divisions: [],
            isReady: false,
            users: [],
            questionnaires: [],
            isLoading: false,
            showEditIdentifiers: false,
            savingIdentifiers: false,
            showCreateDashboard: false,
            showCreateQuestionnaire: false,
            isResponseOverview: false,
            savingDashboard: false,
            isUpdateCompanyDisabled: true,
            isUpdateGeneralCompanyDisabled: true,
            intendedTabSwitch: null,
            confirmBtnIsLoading: false,
            isLoadingCompany: false,
        };
    },
    computed: {
        tabs() {
            const tabs = [
                { name: "general", displayName: "General" },
                { name: "users", displayName: "Users" },
                /*
                Temporary hide Questionnaires tab
                {
                    name: "questionnaires",
                    displayName: "Questionnaires",
                    disabled: !this.isEvaluation,
                    tooltip: !this.isEvaluation ? "No questionnaire features enabled" : "",
                },
                */
                { name: "data", displayName: "Data" },
                {
                    name: "dashboards",
                    displayName: "Dashboards",
                    disabled: !this.isEvaluation,
                    tooltip: !this.isEvaluation ? "No dashboard features enabled" : "",
                },
                {
                    name: "connections",
                    displayName: "Connections",
                    disabled: !this.isEvaluation,
                    tooltip: !this.isEvaluation ? "No connection features enabled" : "",
                },
            ];
            if (this.company.moodMonitorEnabled) {
                tabs.push({ name: "moodMonitor", displayName: "Mood monitor" });
            }
            return tabs;
        },
        activeTab() {
            return this.$route.query?.activeTab;
        },
        title() {
            return this.company ? this.company.name : "";
        },
        identifiers() {
            return {
                platform: this.company.platform,
                subPlatform: this.company.subPlatform,
                externalId: this.company.externalId,
                entityType: this.company.entityType,
                entityId: this.company.entityId,
            };
        },
        isEvaluation() {
            return this.company.services.includes("EVALUATION");
        },
        entities() {
            if (this.company.entityId) {
                return [
                    {
                        entityId: this.company.entityId,
                        entityType: this.company.entityType,
                    },
                ];
            }
            return this.company.divisions.map((element) => ({
                entityId: element.entityId,
                entityType: element.entityType,
            }));
        },
    },
    methods: {
        onTabSelect(name) {
            if (this.activeTab === name) return;
            if (this.$refs[this.activeTab]?.isDirty) {
                this.intendedTabSwitch = name;
                return;
            }
            this.$router.replace({ name: "Company", query: { activeTab: name } });
        },
        async loadUsers() {
            this.isLoading = true;
            const results = await this.$apollo.query({
                query: getUsersByCompany,
                variables: {
                    companyId: this.company.id,
                },
                fetchPolicy: "network-only",
            });
            this.users = cloneDeep(results.data.usersByCompany);
            this.users.sort((item1, item2) => (item1.username > item2.username ? 1 : -1));
            this.isLoading = false;
        },
        openEditIdentifiers() {
            this.showEditIdentifiers = true;
        },
        async saveIdentifiers(identifiers) {
            this.savingIdentifiers = true;
            try {
                const payload = {
                    mutation: updateCompanyServiceIdentifiers,
                    variables: {
                        input: {
                            platform: identifiers.platform,
                            subPlatform: identifiers.subPlatform,
                            entityType: identifiers.entityType,
                            entityId: identifiers.entityId,
                        },
                        companyId: this.company.id,
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.updateCompanyServiceIdentifiers) {
                    // Platform data might have updated, reload data.
                    await this.loadCompanyData();

                    this.company.platform = identifiers.platform;
                    this.company.subPlatform = identifiers.subPlatform;
                    this.company.entityType = identifiers.entityType;
                    this.company.entityId = identifiers.entityId;

                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Saved",
                        message: "The Service identifiers have successfully been changed.",
                    });
                    this.showEditIdentifiers = false;
                } else {
                    await this.pushIdentifiersError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushIdentifiersError();
            }
            this.savingIdentifiers = false;
        },
        async pushIdentifiersError() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Error occured while trying to save identifiers.",
            });
        },
        openCreateDashboard(isResponseOverview) {
            this.showCreateDashboard = true;
            this.isResponseOverview = isResponseOverview;
        },
        openCreateQuestionnaire() {
            this.showCreateQuestionnaire = true;
        },
        createQuestionnaire(questId) {
            this.showCreateQuestionnaire = false;
            this.$router.push({ path: `/form-builder/${questId}` });
        },
        async createNewDashboard(dashboard) {
            this.savingDashboard = true;
            try {
                const payload = {
                    mutation: createDashboard,
                    variables: {
                        input: {
                            type: dashboard.type,
                            name: dashboard.name,
                            userId: dashboard.user,
                            platform: dashboard.platform,
                        },
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.createDashboard) {
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Created",
                        message: `${
                            dashboard.type === "DASHBOARD" ? "Dashboard" : "Response overview"
                        } “${data.createDashboard.name}” have successfully been created.`,
                    });
                    this.showEditIdentifiers = false;
                    this.$router.push({
                        path: `/dashboards/${data.createDashboard.id}`,
                        query: {
                            company: this.company.id,
                            type: dashboard.type,
                        },
                    });
                } else {
                    await this.pushIdentifiersError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushIdentifiersError();
            }
            this.savingDashboard = false;
        },
        async loadCompanyData() {
            this.isLoadingCompany = true;
            const response = await this.$apollo.query({
                query: getCompany,
                variables: { id: this.$route.params.id },
                fetchPolicy: "network-only",
            });
            this.company = cloneDeep(response.data.company);
            this.divisions = this.company.divisions.map((item) => ({
                value: item.id,
                displayValue: item.name,
            }));
            this.isLoadingCompany = false;
        },
        async updateCompany() {
            await this.$refs[this.activeTab]?.save();
        },
        async handleConfirmation() {
            this.confirmBtnIsLoading = true;
            await this.$refs[this.activeTab]?.save();
            this.confirmBtnIsLoading = false;

            await this.$router.replace({
                path: this.$route.path,
                query: { activeTab: this.intendedTabSwitch },
            });
            this.intendedTabSwitch = null;
        },
    },
    async created() {
        await this.loadCompanyData();
        this.isReady = true;
        if (!this.activeTab) {
            this.onTabSelect("general");
        }
        this.loadUsers();
    },
};
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@/style_variables/style_variables.scss";

.company__header__subtitle {
    margin: 0;
    line-height: 20px;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
.settings__input__period_dropdown {
    display: inline-block;
    width: 166px;
    max-height: 56px;
    margin-left: 16px;

    .dropdown_button__value {
        font-weight: 600;
        font-size: 16px;
    }
}
.invite_input__wrapper {
    display: flex;
}
.invitation_block {
    flex-direction: column;
    background: $white;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
}
.invitation_info {
    @extend %body1_style;
}
.company_layout {
    height: 100%;
}
.data_companies_grid {
    display: grid;
    grid-template-columns: 750px 250px;
    grid-gap: 40px;
    justify-content: center;
}
.new_dashboard {
    margin-right: 12px;
}
</style>
