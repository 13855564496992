import Vue, { provide } from "vue";
import Keycloak from "keycloak-js";
import Element from "element-ui";
import DataTables from "vue-data-tables";
import InfiniteLoading from "vue-infinite-loading";
import vClickOutside from "v-click-outside";
import lineClamp from "vue-line-clamp";
import PortalVue from "portal-vue";
import VueTippy from "vue-tippy";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import i18n from "@/i18n";
import storage from "@/helpers/evaluation/storage";
import VueApollo from "vue-apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { filesRoot, createProvider } from "./vue-apollo";
import { version } from "../package.json";

document.title = process.env.VUE_APP_TITLE;

if (process.env.VUE_APP_ENVIRONMENT !== "development") {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: `feedback-company-support-portal@${version}`,
        environment: process.env.VUE_APP_ENVIRONMENT,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [
                    "localhost",
                    "local-support-portal.feedbackcompany.com",
                    "feedbackcompany.com",
                    /^\//,
                ],
            }),
        ],
        tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === "production" ? 0.5 : 1,
        tracingOptions: {
            trackComponents: true,
        },
        maxBreadcrumbs: 50,
        debug: process.env.VUE_APP_ENVIRONMENT !== "production",
        logErrors: process.env.VUE_APP_ENVIRONMENT !== "production",
        attachProps: true,
        attachStacktrace: true,
    });
}

Vue.config.productionTip = false;

Vue.prototype.$filesRoot = filesRoot;

Vue.use(VueApollo);
Vue.use(lineClamp);
Vue.use(PortalVue);

Vue.use(VueTippy, {
    placement: "bottom",
    arrow: true,
    followCursor: true,
    distance: 25,
    animation: "fade",
    delay: [500, 0],
});

// Evaluation plugin config
Vue.use(storage);
Vue.use(DataTables);
Vue.use(InfiniteLoading, {
    slots: {
        noMore: { render: (h) => h("span") },
        noResults: { render: (h) => h("span") },
    },
});
Vue.use(vClickOutside);
Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value),
});

const initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
    onLoad: "login-required",
};
const keycloak = Keycloak(initOptions);

keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
        if (!auth) {
            keycloak.logout();
            return;
        }

        localStorage.setItem("accessToken", keycloak.token);
        store.state.keycloak = keycloak;

        // Store the current user to access it data across the app.
        keycloak.loadUserInfo().then((user) => {
            store.commit("UserAccountStore/setCurrentUser", user);
        });

        const apolloClientWrapper = createProvider({ vuexStore: store });
        const vueApolloClient = new VueApollo(apolloClientWrapper);

        new Vue({
            router,
            store,
            i18n,
            apolloProvider: vueApolloClient,
            setup() {
                provide(DefaultApolloClient, apolloClientWrapper.clients.default);
            },
            render: (h) => h(App),
        }).$mount("#app");

        const updateToken = () => {
            keycloak
                .updateToken(35)
                .then(() => {
                    localStorage.setItem("accessToken", keycloak.token);
                })
                .catch(() => {
                    keycloak.logout();
                });
        };
        setInterval(updateToken, 10000);
        window.addEventListener("focus", () => {
            if (!keycloak.isTokenExpired()) return;
            updateToken();
        });
    })
    .catch(() => {
        document.write("Authentication failed");
    });
