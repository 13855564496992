const UserAccountStore = {
    namespaced: true,
    state: {
        user: {},
        logoutTrayOpenPercentage: 0,
    },

    mutations: {
        setCurrentUser(state, user) {
            state.user = user;
        },
        logoutTrayOpenPercentage(state, { openPercentage }) {
            if (openPercentage !== undefined) {
                state.logoutTrayOpenPercentage = openPercentage;
            }
        },
    },

    getters: {
        getCurrentUser(state) {
            return state.user;
        },
    },
};

export default UserAccountStore;
