<template>
    <div>
        <SettingsLayout
            :loading="loading"
            :headerProps="{
                title: 'Edit user',
                backButtonTo: { path: backButtonUrl },
                backButtonLabel: 'Users',
                onTabSelect,
                tabs,
                activeTab,
            }"
        >
            <template v-slot:subtitle>
                <h2 class="header__subtitle body1">
                    <span v-if="subheaderTitle">
                        <font-awesome-icon
                            class="header__subtitle__icon"
                            :icon="['far', subheaderIcon]"
                            size="sm"
                        />
                        <span>{{ subheaderTitle }}</span>
                    </span>
                    <span v-else>Manage user settings.</span>
                </h2>
            </template>
            <template v-slot:main>
                <EditUserForm
                    v-if="activeTab === 'edit'"
                    :data="user"
                    :previousPage="backButtonUrl"
                    ref="edit"
                />
                <EditUsersParent
                    v-if="activeTab === 'parent'"
                    ref="parent"
                    :selectedParent="user.company ? user.company : {}"
                    :selectedDivisions="user.divisions"
                    :isParentCompany="user.companyLevelAccess"
                    :username="user.username"
                />
                <UserSupport v-if="activeTab === 'support'" :data="user" ref="support" />
                <UserAccess
                    v-if="activeTab === 'access'"
                    :previousPage="backButtonUrl"
                    :data="user"
                    ref="access"
                />
            </template>
            <template v-slot:aside>
                <SupportUserOverview v-if="isSupportTabActive" :user="user" />
            </template>
            <template v-slot:footer>
                <SettingsFooter>
                    <portal-target name="editUserFooter" />
                </SettingsFooter>
            </template>
        </SettingsLayout>
        <ConfirmPopup
            v-if="intendedTabSwitch !== null"
            title="Warning"
            message="There are unsaved changes in this tab. Please save or cancel changes before proceeding."
            confirm-btn="Save"
            confirmButtonStyle="primary"
            :confirmBtnIsLoading="confirmBtnIsLoading"
            @confirm="handleConfirmation"
            @cancel="intendedTabSwitch = null"
        />
    </div>
</template>

<script setup>
import cloneDeep from "lodash.clonedeep";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSitemap, faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ConfirmPopup } from "@feedbackcompany/feedback-company-vue-components";
import { getUser } from "@/graphql/getUser.gql";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import SettingsFooter from "@/components/layout/SettingsFooter.vue";
import EditUserForm from "@/components/user/EditUserForm.vue";
import EditUsersParent from "@/components/user/EditUsersParent.vue";
import UserSupport from "@/components/user/UserSupport.vue";
import UserAccess from "@/components/user/UserAccess.vue";
import SupportUserOverview from "@/components/user/SupportUserOverview.vue";
import { useRoute, useRouter } from "@/helpers/composition-helper";
import { useQuery } from "@vue/apollo-composable";
import { ref, onMounted, computed } from "vue";

library.add(faSitemap, faBuilding);

const route = useRoute();
const router = useRouter();

const backButtonUrl = ref("/users");
defineExpose({
    backButtonUrl,
});

// data
const loading = ref(true);
const user = ref({});
const intendedTabSwitch = ref(null);
const confirmBtnIsLoading = ref(false);

const edit = ref(null);
const parent = ref(null);
const support = ref(null);
const access = ref(null);
const tabsRefs = ref({ edit, parent, support, access });

// TODO: remove this ref onde we update router version
const activeTab = ref("edit");

// computed
const tabs = computed(() => {
    const defaultTabs = [
        { name: "edit", displayName: "Edit" },
        { name: "parent", displayName: "Parent" },
        { name: "support", displayName: "Support" },
    ];
    if (user.value.isAppreciation && user.value.connection) {
        defaultTabs.push({ name: "access", displayName: "Access" });
    }
    return defaultTabs;
});

const subheaderTitle = computed(() => {
    if (user.value.company && user.value.companyLevelAccess) return user.value.company.name;
    if (user.value.divisions && user.value.divisions[0]?.name) return user.value.divisions[0].name;
    return null;
});

const subheaderIcon = computed(() => {
    if (user.value.company && user.value.companyLevelAccess) return "building";
    if (user.value.divisions && user.value.divisions[0].name) return "sitemap";
    return null;
});

const isSupportTabActive = computed(() => {
    return activeTab.value === "support";
});

// methods
function onTabSelect(name) {
    if (activeTab.value === name) return;
    if (tabsRefs.value[activeTab.value]?.isDirty) {
        intendedTabSwitch.value = name;
        return;
    }
    router.replace({ name: "EditUser", query: { activeTab: name } });
    activeTab.value = name;
}

async function handleConfirmation() {
    confirmBtnIsLoading.value = true;
    await tabsRefs.value[activeTab.value]?.save();
    confirmBtnIsLoading.value = false;

    if (tabsRefs.value[activeTab.value]?.$v?.$error) {
        intendedTabSwitch.value = null;
    } else {
        router.replace({
            name: "EditUser",
            query: { activeTab: intendedTabSwitch.value },
        });
        activeTab.value = intendedTabSwitch.value;
        intendedTabSwitch.value = null;
    }
}

function handleResult(response) {
    const localUser = cloneDeep(response.user);
    if (!localUser.divisions) localUser.divisions = [];
    if (!localUser.keycloakLanguage) localUser.keycloakLanguage = "nl";
    user.value = localUser;
    loading.value = false;
}

function fetchUser() {
    const { result, onResult } = useQuery(getUser, {
        id: route.params.userId,
    });
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
}

onMounted(async () => {
    if (!activeTab.value) {
        router.replace({ name: "EditUser", query: { activeTab: "edit" } });
    }
    fetchUser();
});
</script>

<script>
export default {
    beforeRouteEnter(to, from, next) {
        if (!to.query.activeTab) {
            next((vm) => {
                vm.backButtonUrl = from.fullPath;
            });
            return;
        }
        next();
    },
};
</script>

<style lang="scss" scoped>
.header__subtitle {
    display: flex;
    align-items: center;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
</style>
