// workaround until we update router and store versions to versions competable with
// composition api
import { getCurrentInstance } from "vue";

export function useRoute() {
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    return route;
}
export function useRouter() {
    const { proxy } = getCurrentInstance();
    const router = proxy.$router;
    return router;
}
export function useStore() {
    const { proxy } = getCurrentInstance();
    const router = proxy.$store;
    return router;
}
