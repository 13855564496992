<template>
    <SettingsLayout
        :headerProps="{
            title: formTitle,
            backButtonTo: { path: backButtonUrl },
        }"
    >
        <template v-slot:subtitle>
            <h2 class="header__subtitle body1">{{ connection.name }}</h2>
        </template>

        <template v-slot:side-block>
            <SwitchInput v-model="connection.enabled" />
        </template>

        <template v-slot:main>
            <component
                v-if="!loadingProvider"
                :is="providerComponent"
                :connection="connection"
                @save="save"
                ref="providerCpn"
            />
        </template>

        <template v-slot:footer>
            <SettingsFooter>
                <Button type="submit" @click.native="submit($event, false)">
                    {{ saveBtnLabel }}
                </Button>
                <Button
                    v-if="!editMode"
                    @click.native="submit($event, true)"
                    class="create_and_add_new_button"
                    buttonStyle="secondary"
                >
                    Create and add new
                </Button>
            </SettingsFooter>
        </template>
    </SettingsLayout>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, onMounted, computed } from "vue";
import { useStore, useRouter, useRoute } from "@/helpers/composition-helper";
import { useMutation, useApolloClient } from "@vue/apollo-composable";
import { Button, SwitchInput } from "@feedbackcompany/feedback-company-vue-components";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import SettingsFooter from "@/components/layout/SettingsFooter.vue";
import ProviderService from "@/composables/providerService";
import { createOrUpdateTracebuzzIntegration } from "@/graphql/integrations/createOrUpdateTracebuzzIntegration.gql";
import { createUpdateKidsKonnectIntegration } from "@/graphql/integrations/createOrUpdateKidsKonnectIntegration.gql";
// Provider's form.
import TracebuzzConnectionForm from "@/components/providers/tracebuzzConnectionForm.vue";
import KidsKonnectConnectionForm from "@/components/providers/KidsKonnectConnectionForm.vue";
import getIntegrations from "../graphql/connections/getIntegrations.gql";
import kidsKonnectIntegration from "../graphql/integrations/getKidsKonnectIntegration.gql";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { resolveClient } = useApolloClient();

const connection = ref({});
const mutationConn = ref({});
const prevRoute = ref({});
const loadingProvider = ref(true);
const editMode = ref(false);
const messageTitle = ref("");
const providerCpn = ref(null);
const isAddNew = ref(false);

defineExpose({
    prevRoute,
});

// computed
const backButtonUrl = computed(() => {
    return prevRoute.value.fullPath;
});

const providerComponent = computed(() => {
    switch (connection.value.provider) {
        case "tracebuzz":
            return TracebuzzConnectionForm;
        case "kids-konnect":
            return KidsKonnectConnectionForm;
        default:
            return "";
    }
});

const formTitle = computed(() => {
    return editMode.value ? "Edit connection" : "Create new connection";
});

const saveBtnLabel = computed(() => {
    return editMode.value ? "Save connection" : "Create";
});

// creation / update mutation helpers
function creationError() {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Failed",
        message: "Something went wrong. The connection could not be created.",
    });
    router.push(backButtonUrl.value);
}

function creationSuccess() {
    store.dispatch("pushNotification", {
        type: "success",
        title: messageTitle.value,
        message: `“${
            mutationConn.value.name
        }” configuration has been successfully ${messageTitle.value.toLowerCase()}.`,
    });
    if (isAddNew.value) {
        providerCpn.value.reset();
    } else {
        router.push(backButtonUrl.value);
    }
}

// Tracebuzz mutation
const {
    mutate: mutateTb,
    onDone: onTbDone,
    onError: onTbError,
} = useMutation(createOrUpdateTracebuzzIntegration);
const {
    mutate: mutateKk,
    onDone: onKkDone,
    onError: onKkError,
} = useMutation(createUpdateKidsKonnectIntegration);

onTbDone(() => {
    creationSuccess();
});
onTbError(() => {
    creationError();
});

onKkDone(() => {
    creationSuccess();
});
onKkError((e) => {
    creationError();
});

function submit(e, isAddNewLocal) {
    isAddNew.value = isAddNewLocal;
    e.preventDefault();
    providerCpn.value.submitDataToParent();
}

function save(providerData) {
    // If inner form validation fails we get false.
    if (providerData === false) {
        return false;
    }

    mutationConn.value = { ...connection.value, ...providerData };
    const { connectionId } = route.params;
    messageTitle.value = connectionId ? "Updated" : "Created";

    const questId = mutationConn.value.questionnaire.id;
    let questionnaireId = questId;

    if (typeof questId === "string" && (questId.startsWith("ric_") || questId.startsWith("riq_"))) {
        questionnaireId = parseInt(questId.replace("ric_", "").replace("riq_", ""), 10);
    }

    // set timestamp for KkVaribles fromDate to midnight
    const formatDate = new Date(mutationConn.value.fromDate);
    formatDate.setUTCHours(0, 0, 0, 0);

    const TbVariables = {
        input: {
            credentials: {
                clientSecret: mutationConn.value.clientSecret,
                username: mutationConn.value.username,
                password: mutationConn.value.password,
            },
            platform: mutationConn.value.currentPlatform,
            username: mutationConn.value.apiUser,
            questionnaireId,
            questionnaireName: mutationConn.value.questionnaire.name,
            message: mutationConn.value.invitationLink,
            enabled: mutationConn.value.enabled,
        },
    };

    const KkVariables = {
        input: {
            name: mutationConn.value.name,
            username: mutationConn.value.username,
            password: mutationConn.value.password,
            apiToken: mutationConn.value.apiToken,
            evApiKey: mutationConn.value.evApiKey,
            evUsername: mutationConn.value.evUsername,
            cron: "0 0 10 * * ?",
            enabled: mutationConn.value.enabled,
            platform: mutationConn.value.currentPlatform,
            companyId: route.params.id,
            questionnaireId,
            questionnaireName: mutationConn.value.questionnaire.name,
            initialDelay: mutationConn.value.initialDelay,
            initialDelayUnit: "DAYS",
            reminderDelay: mutationConn.value.reminderDelay,
            reminderDelayUnit: "DAYS",
            fromDateType: mutationConn.value.fromDateType,
            fromDate: formatDate,
            invitationHours: mutationConn.value.invitationTime.getHours(),
            invitationMinutes: mutationConn.value.invitationTime.getMinutes(),
        },
    };

    // If we are editing we must include the id.
    if (connectionId) {
        TbVariables.input.id = connectionId;
        KkVariables.input.id = connectionId;
    }

    switch (mutationConn.value.provider) {
        case "tracebuzz":
            mutateTb(TbVariables, {
                refetchQueries: [
                    { query: getIntegrations, variables: { companyId: route.params.id } },
                ],
            });
            break;
        case "kids-konnect":
            mutateKk(KkVariables, {
                refetchQueries: [
                    { query: getIntegrations, variables: { companyId: route.params.id } },
                    { query: kidsKonnectIntegration, variables: { id: route.params.connectionId } },
                ],
            });
            break;
        default:
    }
}

onMounted(() => {
    const { provider, connectionId } = route.params;
    const client = resolveClient();
    const providerService = new ProviderService(client);

    providerService.get(provider, connectionId).then((conn) => {
        connection.value = conn;
        loadingProvider.value = false;
        editMode.value = connectionId !== undefined;
    });
});
</script>

<script>
export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables";
@import "~include-media";

.create_and_add_new_button {
    margin-left: 12px;
}
.header__subtitle {
    display: flex;
    align-items: center;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
</style>
