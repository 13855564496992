<template>
    <button @click="$emit('click')" class="button">
        <font-awesome-icon :icon="['far', 'user-plus']" class="icon" />
        <span>Create new user</span>
    </button>
</template>

<script setup>
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserPlus);
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables";
.button {
    @extend %button_typography_style;
    width: 100%;
    background-color: $white;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    padding: 18px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 40px;

    &:hover,
    &:target,
    &:focus {
        background-color: $grey_alabaster;
        border: 1px solid $grey_athens;
        color: $grey_mineshaft;
    }

    &:active {
        background-color: $grey_mischka;
        border: 1px solid $grey_french;
        color: $grey_mineshaft;
    }

    .icon {
        margin-right: 12px;
        color: $blue;
    }
}
</style>
