<template>
    <metadata-overview
        title="Service identifiers"
        :metadata="metadata"
        :showEdit="isEvaluation"
        @onClick="$emit('openDialog')"
    />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed } from "vue";

const props = defineProps({
    division: {
        type: Object,
        required: true,
    },
});

const metadata = computed(() => {
    const dataList = [
        {
            key: "Division ID",
            value: props.division.externalId ? props.division.externalId : "−",
            empty: !props.division.externalId,
        },
    ];
    if (props.division.platform) {
        dataList.push({
            key: "Platform",
            value: props.division.platform ? props.division.platform : "No platform selected",
        });
    }
    if (props.division.subPlatform) {
        dataList.push({
            key: "Custom platform",
            value: props.division.subPlatform
                ? props.division.subPlatform
                : "No custom platform selected",
        });
    }
    if (props.division.entityType) {
        dataList.push({
            key: "Entity type",
            value: props.division.entityType?.toLowerCase(),
        });
    }
    if (props.division.entityId) {
        dataList.push({
            key: "Entity ID",
            value: props.division.entityId,
        });
    }
    return dataList;
});

const isEvaluation = computed(() => {
    return props.division.services.includes("EVALUATION");
});
</script>
