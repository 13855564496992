<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Widget templates',
            subtitle: 'Manage or create new widget templates.',
            disableBackButton: true,
        }"
    >
        <Table
            class="widget_template_table"
            title="Widgets template overview"
            :columns="[
                { label: 'Template name', property: 'name' },
                { label: 'Description', property: 'description' },
                { label: 'Type', property: 'type' },
                { label: 'Display type', property: 'formattedDisplayType' },
            ]"
            disableHeaderTextWrap
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="['name', 'description', 'type', 'formattedDisplayType']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="['name', 'description', 'type', 'formattedDisplayType']"
            :searching="tableState.searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell>
                    <router-link :to="`/widget-templates/${row.id}`" class="widget_link">{{
                        row.name
                    }}</router-link>
                </TableCell>
                <TableCell>{{ row.description }}</TableCell>
                <TableCell>{{ row.type }}</TableCell>
                <TableCell>{{ row.formattedDisplayType }}</TableCell>
                <TableCell class="controls">
                    <IconButton
                        label=""
                        v-tippy="'Delete template'"
                        class="delete-btn"
                        @click.native="openDeleteDialog(row)"
                    >
                        <font-awesome-icon :icon="['far', 'trash-alt']" />
                    </IconButton>

                    <IconButton
                        label=""
                        v-tippy="'Clone template'"
                        class="clone-btn"
                        @click.native="cloneWidgetTemplate(row.id)"
                    >
                        <font-awesome-icon :icon="['far', 'clone']" />
                    </IconButton>

                    <IconButton class="edit-btn" label="">
                        <router-link :to="`/widget-templates/${row.id}`" v-tippy="'Edit template'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No templates </span>
            </template>
            <template v-if="!isLoading" v-slot:filter>
                <FilterButton
                    id="widget-templates-filter"
                    @toggleFilter="openFilterTray"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#widget-templates-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button
                                button-style="secondary"
                                @click.native="clearWidgetTemplatesFilters"
                            >
                                Clear all filters
                            </Button>
                            <Button @click.native="applyWidgetTemplateFilters"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Type</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="general" label="General" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="benchmark" label="Benchmark" />
                                </div>
                            </div>

                            <label>Display type</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="graph" label="Graph" />
                                    <Checkbox v-model="table" label="Table" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="gauge" label="Gauge" />
                                    <Checkbox v-model="singleValue" label="Single Value" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="templates"
                    ofString="of"
                />
            </template>
        </Table>
        <template v-slot:footer>
            <Button buttonType="primary" @click.native="$router.push('/widget-templates/new')"
                >Create new widget</Button
            >
        </template>
        <ConfirmPopup
            v-if="showConfirmationDialog"
            title="Warning"
            :message="confirmMessage"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            :typeToConfirmString="affectedUsers > 0 ? 'Confirm delete' : ''"
            :typeToConfirmInstruction="affectedUsers > 0 ? 'Type “Confirm delete” to continue' : ''"
            :confirmBtnIsLoading="isDeleting"
            @confirm="deleteWidgetTemplate"
            @cancel="closeDialog"
        />
    </GenericPage>
</template>

<script>
import * as Sentry from "@sentry/vue";
import {
    LoaderCircular,
    PaginationIndicator,
    TableCell,
    TableRow,
    Table,
    Button,
    Checkbox,
    FilterButton,
    Tray,
    IconButton,
    ConfirmPopup,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt, faClone } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import { widgetService } from "@/services/Evaluation";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import getWidgetTemplates from "../graphql/research/getWidgetTemplates.gql";
import getAffectedUsersWidgetTemplate from "../graphql/research/getAffectedUsersWidgetTemplate.gql";
import deleteWidgetTemplate from "../graphql/research/deleteWidgetTemplate.gql";

library.add(faTrashAlt, faEdit, faClone);

const DISPLAY_TYPE = {
    TABLE: "TABLE",
    GRAPH: "GRAPH",
    SINGLE_VALUE: "SINGLE_VALUE",
    GAUGE: "GAUGE",
};

const TYPE = {
    GENERAL: "General",
    BENCHMARK: "Benchmark",
};

export default {
    name: "WidgetTemplates",
    components: {
        GenericPage,
        Table,
        TableRow,
        TableCell,
        PaginationIndicator,
        LoaderCircular,
        FontAwesomeIcon,
        FilterButton,
        Tray,
        Button,
        IconButton,
        Checkbox,
        ConfirmPopup,
    },
    data() {
        return {
            isLoading: true,
            left: 0,
            showConfirmationDialog: false,
            affectedUsers: null,
            templateForDelete: null,
            isDeleting: false,
        };
    },
    computed: {
        confirmMessage() {
            return this.affectedUsers > 0
                ? `This widget is set for ${this.affectedUsers} ${
                      this.affectedUsers === 1 ? "user" : "users"
                  }. Are you sure you want to delete <b>${this.templateForDelete.name}</b>?`
                : "The widget is currently not in use.";
        },
    },
    watch: {
        filterTrayOpenPercentage() {
            const filterButton = document.getElementById("widget-templates-filter");
            this.left = filterButton.offsetLeft - 335;
        },
    },
    setup() {
        // General table state
        const {
            data,
            setData,
            sort,
            search,
            searchTerm,
            setPage,
            setPaginationSize,
            filtersApplied,
            applyFilters,
            clearFilters,
            setFilters,
        } = useTableState();

        // Filter state
        const filterTrayOpenPercentage = ref(0);
        const setFilterOpenPercentage = (percentage) => {
            filterTrayOpenPercentage.value = percentage;
        };

        const general = ref(false);
        const benchmark = ref(false);
        const graph = ref(false);
        const table = ref(false);
        const gauge = ref(false);
        const singleValue = ref(false);

        const generalLocal = ref(false);
        const benchmarkLocal = ref(false);
        const graphLocal = ref(false);
        const tableLocal = ref(false);
        const gaugeLocal = ref(false);
        const singleValueLocal = ref(false);

        const applyWidgetTemplateFilters = () => {
            generalLocal.value = general.value;
            benchmarkLocal.value = benchmark.value;
            graphLocal.value = graph.value;
            tableLocal.value = table.value;
            gaugeLocal.value = gauge.value;
            singleValueLocal.value = singleValue.value;

            if (
                generalLocal.value ||
                benchmarkLocal.value ||
                graphLocal.value ||
                tableLocal.value ||
                gaugeLocal.value ||
                singleValueLocal.value
            ) {
                applyFilters();
            } else {
                clearFilters();
            }
            filterTrayOpenPercentage.value = 0;
        };

        const clearWidgetTemplatesFilters = () => {
            clearFilters();
            general.value = false;
            benchmark.value = false;
            graph.value = false;
            table.value = false;
            gauge.value = false;
            singleValue.value = false;
        };

        setFilters([
            ({ type, displayType }) => {
                let allowedTypes = [];

                if (generalLocal.value) {
                    allowedTypes.push(TYPE.GENERAL);
                }

                if (benchmarkLocal.value) {
                    allowedTypes.push(TYPE.BENCHMARK);
                }

                if (allowedTypes.length === 0) {
                    allowedTypes = [TYPE.GENERAL, TYPE.BENCHMARK];
                }

                let allowedDisplayTypes = [];
                if (graphLocal.value) {
                    allowedDisplayTypes.push(DISPLAY_TYPE.GRAPH);
                }
                if (tableLocal.value) {
                    allowedDisplayTypes.push(DISPLAY_TYPE.TABLE);
                }
                if (gaugeLocal.value) {
                    allowedDisplayTypes.push(DISPLAY_TYPE.GAUGE);
                }
                if (singleValueLocal.value) {
                    allowedDisplayTypes.push(DISPLAY_TYPE.SINGLE_VALUE);
                }

                if (allowedDisplayTypes.length === 0) {
                    allowedDisplayTypes = [
                        DISPLAY_TYPE.TABLE,
                        DISPLAY_TYPE.GRAPH,
                        DISPLAY_TYPE.SINGLE_VALUE,
                        DISPLAY_TYPE.GAUGE,
                    ];
                }
                return allowedDisplayTypes.includes(displayType) && allowedTypes.includes(type);
            },
        ]);

        return {
            tableState: data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            setPaginationSize,
            setFilterOpenPercentage,
            filtersApplied,
            filterTrayOpenPercentage,
            general,
            benchmark,
            graph,
            table,
            singleValue,
            gauge,
            applyWidgetTemplateFilters,
            clearWidgetTemplatesFilters,
        };
    },
    methods: {
        onPageChange(page) {
            this.setPage(page);
        },
        async getWidgetTemplates() {
            this.isLoading = true;
            const results = await this.$apollo.query({
                query: getWidgetTemplates,
                fetchPolicy: "network-only",
            });
            const widgetTemplates = cloneDeep(results.data.widgetTemplates);
            widgetTemplates.forEach((element) => {
                element.type = element.benchmark ? TYPE.BENCHMARK : TYPE.GENERAL;
                const formattedDisplayType = element.displayType?.replaceAll("_", " ");
                if (formattedDisplayType) {
                    element.formattedDisplayType =
                        formattedDisplayType.charAt(0) +
                        formattedDisplayType.slice(1).toLowerCase();
                }
            });
            this.setData(widgetTemplates);
            this.isLoading = false;
        },
        openFilterTray() {
            this.setFilterOpenPercentage(1);
        },
        async openDeleteDialog(template) {
            this.templateForDelete = template;
            const results = await this.$apollo.query({
                query: getAffectedUsersWidgetTemplate,
                variables: {
                    templateId: template.id,
                },
            });
            this.affectedUsers = cloneDeep(results.data.affectedUsersWidgetTemplate);
            this.showConfirmationDialog = true;
        },
        async deleteWidgetTemplate() {
            this.isDeleting = true;
            try {
                await this.$apollo.mutate({
                    mutation: deleteWidgetTemplate,
                    variables: {
                        templateId: this.templateForDelete.id,
                    },
                });
                this.isDeleting = false;
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: "Deleted",
                    message: `You've successfully deleted widget template "${this.templateForDelete.name}"`,
                });
                this.closeDialog();
                await this.getWidgetTemplates();
            } catch (error) {
                Sentry.captureException(error);
                this.isDeleting = false;
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: "Error",
                    message: "Error occured while trying to delete widget template.",
                });
            }
        },
        closeDialog() {
            this.showConfirmationDialog = false;
            this.affectedUsers = null;
            this.templateForDelete = null;
        },
        async cloneWidgetTemplate(id) {
            widgetService
                .getWidgetTemplate(id)
                .then((template) => {
                    template.data.defaultTitle += ` - Clone ${
                        this.getMaxInt(template.data.defaultTitle) + 1
                    }`;
                    template.data.id = null;
                    widgetService
                        .saveWidgetTemplate(template.data)
                        .then((response) => {
                            this.$router.push(`/widget-templates/${response.id}`);
                            this.$store.dispatch("pushNotification", {
                                type: "success",
                                title: "Saved",
                                message: `You've successfully saved widget template "${response.defaultTitle}"`,
                            });
                        })
                        .catch(() => {
                            this.$store.dispatch("pushNotification", {
                                type: "error",
                                title: "Error",
                                message: "The widget template form contains errors!",
                            });
                        });
                })
                .catch(() => {
                    this.$store.dispatch("pushNotification", {
                        type: "error",
                        title: "Error",
                        message: "The widget template form contains errors!",
                    });
                });
        },
        getMaxInt(title) {
            const re = new RegExp(`^${title}( - Clone (\\d+))?$`);
            let maxInt = 0;

            this.tableState.rawData.forEach((template) => {
                const matches = re.exec(template.name);
                if (matches) {
                    const titleInt = parseInt(matches[2], 10);
                    if (maxInt < titleInt) {
                        maxInt = titleInt;
                    }
                }
            });
            return maxInt;
        },
    },
    async created() {
        await this.getWidgetTemplates();
    },
};
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.widget_template_table {
    width: 100%;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.widget_link {
    color: $blue;
    text-decoration: none;
}
.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $red;
        }
    }
    .clone-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
    .edit-btn {
        margin-right: 6px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
}
.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;

    &:first-of-type {
        margin-bottom: 28px;
    }
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 12px;
        width: 150px;
    }
}
</style>
