<template>
    <MetadataOverview title="General" :metadata="metadata" />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed } from "vue";

const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
});
const metadata = computed(() => {
    return [
        {
            key: "Teamleader ID",
            value: props.company.teamleaderId ? props.company.teamleaderId : "−",
            empty: !props.company.teamLeaderId,
        },
        {
            key: "Teamleader UUID",
            value: props.company.teamleaderUuid ? props.company.teamleaderUuid : "−",
            empty: !props.company.teamleaderUuid,
        },
        {
            key: "Nr of Divisions",
            value: props.company.divisions.length,
        },
    ];
});
</script>

<style lang="scss" scoped />
