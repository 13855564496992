/* eslint-disable class-methods-use-this */
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";

export default class WidgetService {
    constructor() {
        this.widgets = {};
    }

    getWidget(widgetId, reload) {
        return new Promise((resolve, reject) => {
            if (widgetId in this.widgets && !reload) {
                resolve(this.widgets[widgetId]);
            } else {
                dashboardApiClient
                    .get(`/widgets/${widgetId}`)
                    .then((response) => {
                        this.widgets[widgetId] = response.data;
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    getTextWidget(widgetId) {
        return dashboardApiClient.get(`/text-widgets/${widgetId}`);
    }

    getWidgetDataSet(widgetId, widgetTemplate, reload) {
        return new Promise((resolve) => {
            const widgetData = {
                hasData: false,
                exists: true,
                errorMessage: null,
            };

            let apiRequest;
            if (widgetId != null && reload) {
                apiRequest = dashboardApiClient.get(`/widgets/${widgetId}/dataset/reload`);
            } else if (widgetId != null) {
                apiRequest = dashboardApiClient.get(`/widgets/${widgetId}/dataset`);
            } else {
                apiRequest = dashboardApiClient.post("/widgets/preview", widgetTemplate);
            }

            apiRequest
                .then((response) => {
                    widgetData.dataSet = response.data;

                    if (widgetData.dataSet.tooFewResults) {
                        widgetData.errorMessage = "TOO_FEW_RESULTS";
                    } else if (widgetData.dataSet.series.length > 0) {
                        $.each(widgetData.dataSet.series, (index, series) => {
                            if (series.data.length > 0) {
                                widgetData.hasData = true;
                                return false;
                            }
                        });
                        if (!widgetData.hasData) {
                            widgetData.errorMessage = "NO_DATA";
                        }
                    } else {
                        widgetData.errorMessage = "NO_DATA";
                    }

                    resolve(widgetData);
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        widgetData.exists = false;
                    } else {
                        widgetData.errorMessage = "NO_DATA";
                    }
                    resolve(widgetData);
                });
        });
    }

    getWidgetTemplate(templateId) {
        return dashboardApiClient.get(`/widget-templates/${templateId}`);
    }

    getPreviewWidgetDataSet(widgetTemplate) {
        return dashboardApiClient.post("/widgets/preview", widgetTemplate);
    }

    updateWidget(widget) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .put(`/widgets/${widget.id}`, widget)
                .then(() => {
                    this.widgets[widget.id] = widget;
                    resolve(widget);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateTextWidget(widget) {
        return dashboardApiClient.put(`/text-widgets/${widget.id}`, widget);
    }

    deleteWidget(widgetId) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .delete(`/widgets/${widgetId}`)
                .then((response) => {
                    delete this.widgets[widgetId];
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteTextWidget(widgetId) {
        return dashboardApiClient.delete(`/text-widgets/${widgetId}`);
    }

    addWidgetToDashboard(dashboardViewId, widgetTemplateId) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .post(`/dashboard-views/${dashboardViewId}/widget/`, widgetTemplateId, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.widgets[response.data.id] = response.data;
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    addTextWidgetToDashboard(dashboardViewId, text) {
        return dashboardApiClient.post(`/dashboard-views/${dashboardViewId}/text-widget/`, {
            text,
        });
    }

    saveWidgetTemplate(widgetTemplate) {
        return new Promise((resolve, reject) => {
            let apiRequest;
            if (widgetTemplate.id) {
                // edit
                apiRequest = dashboardApiClient.put("/widget-templates", widgetTemplate);
            } else {
                // add
                apiRequest = dashboardApiClient.post("/widget-templates", widgetTemplate);
            }

            apiRequest
                .then((response) => {
                    const updatedWidgetTemplate = response.data;
                    if (widgetTemplate.id) {
                        // edit
                        $.each(this.widgets, (widgetId, widget) => {
                            if (
                                widget.widgetTemplate &&
                                widget.widgetTemplate.id === updatedWidgetTemplate.id
                            ) {
                                widget.widgetTemplate = updatedWidgetTemplate;
                            }
                        });
                    }
                    resolve(updatedWidgetTemplate);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteWidgetTemplate(widgetTemplateId) {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .delete(`/widget-templates/${widgetTemplateId}`)
                .then(() => {
                    const widgetsToDelete = [];
                    $.each(this.widgets, (widgetId, widget) => {
                        if (
                            widget.widgetTemplate &&
                            widget.widgetTemplate.id === widgetTemplateId
                        ) {
                            widgetsToDelete.push(widget.id);
                        }
                    });
                    $.each(widgetsToDelete, (index, widgetId) => {
                        delete this.widgets[widgetId];
                    });
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
