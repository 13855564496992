<template>
    <MetadataOverview title="User" :metadata="metadata" />
</template>

<script setup>
import MetadataOverview from "@/components/layout/MetadataOverview.vue";
import { computed, ref } from "vue";
import { format } from "date-fns";

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
});

function formatDate(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm:ss");
}

const metadata = computed(() => {
    const lastImpersonateLogin = ref(formatDate(props.user.lastImpersonateLogin));
    const defaultImpersonatedData = ref("01 Jan 1970 01:00:00");
    const data = [
        {
            key: "Created at",
            value: formatDate(props.user.createdTimestamp),
            isEmpty: "",
        },
        {
            key: "User enabled",
            value: props.user.isEnabled ? "yes" : "no",
            isEmpty: "",
        },
        {
            key: "Email verified",
            value: props.user.isEmailVerified ? "yes" : "no",
            isEmpty: "",
        },
        {
            key: "Last impersonated",
            value:
                lastImpersonateLogin.value === defaultImpersonatedData.value
                    ? "Not impersonated yet"
                    : lastImpersonateLogin.value,
            isEmpty: "",
        },
    ];
    if (props.user.lastLogin) {
        data.push({
            key: "Last login",
            value: formatDate(props.user.lastLogin),
            isEmpty: "",
        });
    }
    return data;
});
</script>
