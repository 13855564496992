<template>
    <div class="user_header_button" @click="toggleAccountSwitcher">
        <AccountImage :size="28" :initial="'given_name' in user ? user.given_name[0] : '-'" />
        <font-awesome-icon
            class="user_header_button__icon"
            :class="classes"
            :icon="['fas', 'chevron-down']"
        />
    </div>
</template>

<script setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";
import { computed } from "vue";
import { useStore } from "@/helpers/composition-helper";

library.add(faChevronDown);
const store = useStore();

const trayOpenPercentage = computed(() => {
    return store.state.UserAccountStore.logoutTrayOpenPercentage;
});

const classes = computed(() => {
    return {
        "user_header_button__icon--flipped": trayOpenPercentage.value,
    };
});

const user = computed(() => {
    return store.getters["UserAccountStore/getCurrentUser"];
});

function closeAccountSwitcher() {
    store.commit("UserAccountStore/logoutTrayOpenPercentage", {
        openPercentage: 0,
    });
}

function openAccountSwitcher() {
    store.commit("UserAccountStore/logoutTrayOpenPercentage", {
        openPercentage: 1,
    });
}

function toggleAccountSwitcher() {
    if (trayOpenPercentage.value === 1) {
        closeAccountSwitcher();
    } else {
        openAccountSwitcher();
    }
}
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.user_header_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 49px;
    &:hover {
        cursor: pointer;
        .user_header_button__icon {
            @media (min-width: 1001px) {
                font-size: 16px;
                transition: all 0.3s;
                transform: rotateZ(180deg);
            }
        }
    }
    z-index: 9;
}
.user_header_button__icon {
    color: $grey_mineshaft;
    flex-shrink: 0;
    font-size: 14px;
    margin-left: 8px;
}
.user_header_button__icon--flipped {
    transform: rotateZ(-180deg);
}
</style>
