export default {
    install: (Vue) => {
        const storagePrefix = "evDashboards-";

        Vue.prototype.$storage = {
            setItem(key, value) {
                localStorage.setItem(storagePrefix + key, JSON.stringify(value));
            },
            getItem(key, defaultValue) {
                const itemToParse = localStorage.getItem(storagePrefix + key);
                if (itemToParse != null) {
                    let result;
                    try {
                        result = JSON.parse(itemToParse);
                    } catch (e) {
                        result = itemToParse;
                    }
                    return result;
                }

                if (defaultValue) {
                    return defaultValue;
                }

                return null;
            },
            removeItem(key) {
                const itemToParse = localStorage.removeItem(storagePrefix + key);
                let result;
                try {
                    result = JSON.parse(itemToParse);
                } catch (e) {
                    result = itemToParse;
                }
                return result;
            },
        };
    },
};
