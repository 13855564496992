/* eslint-disable prefer-object-spread */
import util from "@/helpers/evaluation/util";

const DashboardStore = {
    namespaced: true,
    state: {
        navItems: [],
        actionBarItems: [],

        dashboards: [],
        myDashboardViews: [],

        activeDashboard: null,
        activeDashboardView: null,
        dashboardEditMode: false,

        applyDashboardTemplate: null,

        editorDatabaseStructure: null,

        filterTemplates: [],
        filterTemplatesLoaded: false,

        splitByPropertiesLoaded: false,

        desktopSubNavigationClosed: false,

        configSnapshot: null,
    },
    mutations: {
        setNavItems(state, navItems) {
            state.navItems = Object.assign([], navItems);
        },
        setActionBarItems(state, items) {
            state.actionBarItems = items;
        },
        setDashboards(state, dashboards) {
            state.dashboards = dashboards;
        },
        setMyDashboardViews(state, dashboardViews) {
            state.myDashboardViews = dashboardViews;
        },
        addDashboardView(state, dashboard) {
            state.myDashboardViews.push(dashboard);
        },
        removeDashboardView(state, dashboardView) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < state.myDashboardViews.length; i++) {
                if (state.myDashboardViews[i].id === dashboardView.id) {
                    state.myDashboardViews.splice(i, 1);
                    break;
                }
            }
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < state.myDashboardViews.length; i++) {
                if (state.myDashboardViews[i].position > dashboardView.position) {
                    state.myDashboardViews[i].position -= 1;
                }
            }
        },
        moveDashboard(state, data) {
            util.methods.moveElementToPosition(
                state.dashboards,
                data.id,
                data.oldPosition,
                data.newPosition
            );
        },
        moveMyDashboardView(state, data) {
            util.methods.moveElementToPosition(
                state.myDashboardViews,
                data.id,
                data.oldPosition,
                data.newPosition
            );
        },
        setActiveDashboard(state, dashboard) {
            state.activeDashboard = dashboard;
        },
        setActiveDashboardView(state, dashboardView) {
            state.activeDashboardView = dashboardView;
        },
        updateActiveDashboardView(state, configuration) {
            state.activeDashboardView.title = configuration.title;
            state.activeDashboardView.configuration = Object.assign({}, configuration);
        },
        updateActiveDashboardViewFilterSettings(state, filterSettings) {
            state.activeDashboardView.allowedAllFilterTemplates = filterSettings.allowedAll;
            state.activeDashboardView.allowedFilterTemplateIds = Object.assign(
                [],
                filterSettings.allowedFilterTemplates
            );
        },
        setDashboardEditMode(state, editMode) {
            state.dashboardEditMode = editMode;
        },
        setConfigSnapshot(state, snapshot) {
            state.configSnapshot = snapshot;
        },
        setApplyDashboardTemplate(state, template) {
            state.applyDashboardTemplate = template;
        },
        setEditorDatabaseStructure(state, databaseStructure) {
            state.editorDatabaseStructure = databaseStructure;
        },
        setFilterTemplates(state, filterTemplates) {
            filterTemplates.sort(util.methods.objectSortFunc("name"));
            state.filterTemplates = filterTemplates;
            state.filterTemplatesLoaded = true;
        },
        addFilterTemplate(state, filterTemplate) {
            state.filterTemplates.push(filterTemplate);
            state.filterTemplates.sort(util.methods.objectSortFunc("name"));
        },
        removeFilterTemplate(state, filterTemplateId) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < state.filterTemplates.length; i++) {
                if (state.filterTemplates[i].id === filterTemplateId) {
                    state.filterTemplates.splice(i, 1);
                    break;
                }
            }
        },
        renameFilterTemplate(state, data) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < state.filterTemplates.length; i++) {
                if (state.filterTemplates[i].id === data.id) {
                    state.filterTemplates[i].name = data.name;
                    break;
                }
            }
            state.filterTemplates.sort(util.methods.objectSortFunc("name"));
        },
        setSplitByPropertiesLoaded(state, loaded) {
            state.splitByPropertiesLoaded = loaded;
        },
        setDesktopSubNavigationClosed(state, closed) {
            state.desktopSubNavigationClosed = closed;
        },
    },
    getters: {
        getTable: (state) => (name) =>
            name && name !== "" && state.editorDatabaseStructure != null
                ? state.editorDatabaseStructure.tables.find((table) => table.name === name)
                : null,
    },
};

export default DashboardStore;
