<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Divisions',
            subtitle: 'An overview to divide and honor.',
            disableBackButton: true,
        }"
    >
        <Table
            class="divisions_table"
            title="Divisions overview"
            :columns="[
                { label: 'Language', property: 'language' },
                { label: 'Name', property: 'name' },
                { label: 'URL', property: 'link' },
                { label: 'Registration date', property: 'creationDate' },
                { label: 'Service', property: 'displayedServices' },
                { label: '', property: '' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="visibleData.length === 0"
            :sortableColumns="['language', 'name', 'link', 'creationDate']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="[
                'language',
                'name',
                'link',
                'formattedCreationDate',
                'displayedServices',
            ]"
            :searching="searchTerm !== null"
            :searchTerm="searchTermInit"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in visibleData"
                v-bind:key="index"
                v-bind:isLast="visibleData.length - 1 === index"
                bordered
            >
                <TableCell>{{ row.language }}</TableCell>
                <TableCell>
                    <router-link :to="getDivisionUrl(row)" class="url">
                        {{ row.name }}
                    </router-link>
                </TableCell>
                <TableCell>
                    <a class="url" :href="row.link" target="_blank">
                        {{ row.link }}
                    </a>
                </TableCell>
                <TableCell>{{ row.formattedCreationDate }}</TableCell>
                <TableCell>{{ row.displayedServices }}</TableCell>
                <TableCell class="controls">
                    <IconButton label="">
                        <router-link :to="getDivisionUrl(row)" v-tippy="'Edit division'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="true" class="loading-and-messages"> No results </span>
                <span v-else class="loading-and-messages"> No divisions </span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="visibleData.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="divisions"
                    ofString="of"
                />
            </template>
        </Table>
    </GenericPage>
</template>

<script setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { format, parseISO } from "date-fns";
import {
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    LoaderCircular,
    IconButton,
    useTableStateServerSide,
} from "@feedbackcompany/feedback-company-vue-components";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import { useLazyQuery } from "@vue/apollo-composable";
import { ref, onMounted, watch } from "vue";
import { useStore } from "@/helpers/composition-helper";
import getDivisions from "../graphql/overview/getDivisions.gql";

const store = useStore();

library.add(faEdit);

const isLoading = ref(true);
const {
    setData,
    sort,
    search,
    searchTerm,
    setPage,
    setPaginationSize,
    state,
    visibleData,
    setState,
} = useTableStateServerSide();
const tableState = state;
const searchTermInit = ref(null);

function onPageChange(page) {
    setPage(page);
}
function formatDate(date) {
    return format(parseISO(date), "dd-MM-yyyy", "en");
}
function getDivisionUrl(row) {
    return `/division/${row.id}`;
}
function mapServices(services) {
    return services
        .map((element) => {
            if (element === "APPRECIATION") {
                return "AP";
            }
            return "EV";
        })
        .join("/");
}

const {
    load: callLoadDivisionsQuery,
    onResult: onLoadDivisionsResult,
    onError: onLoadDivisionsError,
} = useLazyQuery(getDivisions);

onLoadDivisionsResult((result) => {
    if (result.loading) return;
    const divisions = cloneDeep(result.data.divisions.content);
    divisions.forEach((element) => {
        element.formattedCreationDate = formatDate(element.creationDate);
        element.displayedServices = mapServices(element.services);
    });

    setData(divisions, result.data.divisions.total, { searchTermThreshold: 0.1 });
    isLoading.value = false;
});

onLoadDivisionsError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while loading divisions",
    });
});

function loadDivision(isInitialLoad) {
    let localState = state.value;
    if (isInitialLoad) {
        localState = store.getters["OverviewStore/getState"]("divisions");
        setState(localState);
    }
    const pagination = {
        page: localState.currentPage,
        perPage: localState.paginationSize,
    };
    if (localState.searchTerm) {
        if (isInitialLoad) {
            searchTermInit.value = localState.searchTerm;
        }
        pagination.search = {
            text: localState.searchTerm,
            fields: [],
        };
    }
    if (localState.currentSorted) {
        pagination.sort = {
            field: localState.currentSorted,
            direction: state.value.currentSortDirection.toUpperCase(),
        };
    }
    callLoadDivisionsQuery(getDivisions, { pagination });
}

onMounted(async () => {
    loadDivision(true);
});

watch(state, () => {
    store.commit("OverviewStore/setState", { page: "divisions", newState: tableState.value });
    loadDivision(false);
});
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.divisions_table {
    width: 100%;
}
.controls {
    display: flex;
    justify-content: flex-end;
}
.fa-edit {
    color: #4389f8;
    &:hover {
        color: $blue_sapphire;
    }
}
.url {
    color: #4389f8;
    text-decoration: none;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.divisions_table::v-deep .table_header__cell {
    padding: 36px 0px 32px 24px;
}
</style>
