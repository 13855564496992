/* eslint-disable class-methods-use-this */
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";

export default class FilterService {
    save(filter) {
        return dashboardApiClient.post("/filters", filter);
    }

    delete(filterId) {
        return dashboardApiClient.delete(`/filters/${filterId}`);
    }

    deleteAll(dashboardViewId, widgetId, deleteFromWidgets) {
        const data = {
            dashboardViewId,
            widgetId,
            removeFiltersFromWidgets: deleteFromWidgets,
        };
        return dashboardApiClient.post("/filters/remove-all", data);
    }
}
