import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClient } from "@apollo/client/core";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "@apollo/client/cache";

// Cache implementation
const cache = new InMemoryCache();

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_URL;

// Files URL root
export const filesRoot =
    process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

const httpLink = createHttpLink({
    uri: httpEndpoint,
});

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,
    link: httpLink,
    cache,
    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: httpLink,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
    const uploadLink = createUploadLink({
        uri: httpEndpoint,
        credentials: "include",
    });

    const authLink = setContext((_, { headers }) => {
        if (options.vuexStore?.state.keycloak.isTokenExpired(5)) {
            options.vuexStore.state.keycloak.logout();
        }

        const token = localStorage.getItem("accessToken");

        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        };
    });

    const clientOptions = {
        // eslint-disable-next-line new-cap
        link: authLink.concat(uploadLink),
        defaultHttpLink: false,
    };

    const apolloClient = new ApolloClient({
        ...defaultOptions,
        ...clientOptions,
        ...options,
    });

    const vueApolloOptions = {
        clients: {
            default: apolloClient,
        },
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                // fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log(
                "%cError",
                "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
                error.message
            );
        },
    };

    // Set the mock server in dev
    if (process.env.NODE_ENV === "development") {
        const mockClientOptions = { httpEndpoint: "http://localhost:4000/graphql" };

        vueApolloOptions.clients.mock = new ApolloClient({
            ...defaultOptions,
            ...mockClientOptions,
            ...options,
        });
    }

    return vueApolloOptions;
}
