<template>
    <LoaderCircular v-if="isLoading" class="loader-circular" />
    <SettingsBlock v-else title="Division settings">
        <div class="settings">
            <Checkbox
                v-model="divisionSettings.allowDuplicateReviews"
                label="Allow reviews to be written with the same emailaddress"
            />
        </div>
    </SettingsBlock>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import { Checkbox, LoaderCircular } from "@feedbackcompany/feedback-company-vue-components";
import deepEqual from "deep-equal";
import cloneDeep from "lodash.clonedeep";
import { ref, computed, watch } from "vue";
import { useStore } from "@/helpers/composition-helper";
import { useMutation } from "@vue/apollo-composable";
import updateDivisionSettings from "../graphql/updateDivisionSettings.gql";

const store = useStore();

const props = defineProps({
    division: {
        type: Object,
        required: true,
    },
    isLoading: {
        type: Boolean,
        reqired: false,
    },
});

const divisionSettings = ref(cloneDeep(props.division));
const originalDivisionSettings = ref(cloneDeep(props.division));
const emit = defineEmits(["isDirty"]);

const isDirty = computed(() => {
    return !deepEqual(originalDivisionSettings.value, divisionSettings.value);
});

watch(isDirty, () => {
    emit("isDirty", isDirty.value);
});

// mutations
const { mutate, onDone, onError } = useMutation(updateDivisionSettings);
onDone((response) => {
    // eslint-disable-next-line no-throw-literal
    if (!response.data.updateDivisionSettings) throw false;
    store.dispatch("pushNotification", {
        type: "success",
        title: "Success",
        message: "You've successfully updated Division settings",
    });
    props.division.allowDuplicateReviews = divisionSettings.value.allowDuplicateReviews;
    divisionSettings.value = cloneDeep(props.division);
    originalDivisionSettings.value = cloneDeep(props.division);
});
onError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Something went wrong while updating settings.",
    });
});

function save() {
    const variables = {
        divisionId: divisionSettings.value.id,
        allowDuplicateReviews: divisionSettings.value.allowDuplicateReviews,
    };
    mutate(variables);
}

// expose
defineExpose({
    save,
});
</script>

<style lang="scss" scoped>
.settings {
    margin-top: 20px;
}
</style>
