<template>
    <Table
        class="connections_table"
        title="Kids Konnect"
        :columns="[
            { label: 'Connection', property: 'name' },
            { label: 'Status', property: 'formattedStatus' },
            { label: 'Created on', property: 'formattedCreationDate' },
        ]"
        :disableHeaderTextWrap="true"
        sortable
        :empty="tableState.data.length === 0"
        :sortableColumns="['name', 'formattedStatus', 'formattedCreationDate']"
        :currentPage="tableState.currentPage"
        :currentSorted="tableState.currentSorted"
        :currentSortDirection="tableState.currentSortDirection"
        :searchable="tableState.searchEnabled"
        :searchableColumns="['name', 'formattedStatus', 'formattedCreationDate']"
        :searching="tableState.searchTerm !== null"
        :paginate="
            tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
        "
        :defaultPaginationSize="tableState.paginationSize"
        searchbarPlaceholder="Search"
        :pages="tableState.pages"
        @sort="sort($event)"
        @search="search($event)"
        @pageChange="onPageChange"
        @onPaginationSizeChange="setPaginationSize($event)"
    >
        <template v-slot:header-icon>
            <div class="header_logo_container">
                <img src="@/assets/connections/kidskonnect-logo.png" alt="header logo" />
            </div>
        </template>
        <TableRow
            v-for="(row, index) in tableState.data"
            :key="index"
            :isLast="tableState.data.length - 1 === index"
            bordered
        >
            <TableCell>{{ row.name }}</TableCell>
            <TableCell>{{ row.formattedStatus }}</TableCell>
            <TableCell>{{ row.formattedCreationDate }}</TableCell>
            <TableCell class="controls">
                <IconButton
                    label=""
                    class="delete-btn"
                    v-tippy="'Delete connection'"
                    @click.native="
                        $emit('deleteConnection', { ...row, integrationType: 'KIDS_KONNECT' })
                    "
                >
                    <font-awesome-icon :icon="['far', 'trash-alt']" />
                </IconButton>
                <IconButton label="" class="edit-btn">
                    <router-link
                        :to="{
                            name: 'CreateConnection',
                            params: { provider: 'kids-konnect', connectionId: row.id },
                        }"
                        v-tippy="'Edit connection'"
                    >
                        <font-awesome-icon :icon="['far', 'edit']" />
                    </router-link>
                </IconButton>
            </TableCell>
        </TableRow>
        <template v-slot:actions>
            <div class="table_actions">
                <div class="table_actions__items">
                    <div>
                        <!-- Loading status (and click event) will be handled here -->
                        <button
                            :disabled="!hasPlatform"
                            type="button"
                            class="action_button_add"
                            @click="onAddConnection"
                        >
                            <span class="create_action">Create connection</span>
                            <font-awesome-icon :icon="['far', 'plus']" />
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:empty>
            <div v-if="isLoading" class="loading-and-messages">
                <LoaderCircular />
            </div>
            <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                No results
            </span>
            <span v-else class="loading-and-messages"> No connections </span>
        </template>
        <template v-slot:pagination-indicator>
            <PaginationIndicator
                :pages="tableState.pages"
                :currentPage="tableState.currentPage"
                :currentPageItemCount="tableState.data.length"
                :current-page-range-start="tableState.currentPageRangeStart"
                :current-page-range-end="tableState.currentPageRangeEnd"
                :itemCount="tableState.itemCount"
                itemDescription="connections"
                ofString="of"
            />
        </template>
    </Table>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, watch } from "vue";
import {
    LoaderCircular,
    IconButton,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { format, parseISO } from "date-fns";
import { cloneDeep } from "@apollo/client/utilities";

library.add(faTrashAlt, faEdit, faPlus);

// props & helpers
const props = defineProps({
    data: {
        type: Object,
    },
    hasPlatform: {
        type: Boolean,
    },
});
const emit = defineEmits(["addConnections"]);

// data
const isLoading = ref(true);

// General table state
const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = ref(data);

// methods
function onPageChange(page) {
    setPage(page);
}
function setStatus(status) {
    if (status === true) return "Enabled";
    if (status === false) return "Disabled";
    return "";
}
function onAddConnection() {
    // event isn't being handled yet, simply emitted
    emit("addConnections");
}
function formatDate(date) {
    return format(parseISO(date), "dd-MM-yyyy", "en");
}

function setTableData() {
    // code formerly inside created() hook
    const tableData = cloneDeep(props.data.integrations);
    tableData.forEach((conn) => {
        conn.formattedStatus = setStatus(conn.enabled);
        conn.formattedCreationDate = formatDate(conn.createdAt);
    });
    setData(tableData, {
        paginationSize: 10,
        paginationThreshold: 11,
        paginationEnabled: true,
    });
    isLoading.value = false;
}

watch(
    () => props.data,
    () => {
        setTableData();
    }
);

setTableData();
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "../style_variables/style_variables";

.connections_table {
    width: 100%;
    margin-bottom: 60px;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.fa-edit {
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
}
.fa-trash-alt {
    color: $blue;
    &:hover {
        color: $red;
    }
}
.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $red;
        }
    }
    .edit-btn {
        margin-right: 6px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
    .delete-btn-disabled {
        color: $grey_french;
        cursor: default;
        &:hover {
            cursor: default;
            color: $grey_french;
        }
    }
}

.table_actions {
    width: 100%;
}
.table_actions__items {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create_action {
    padding-right: 8px;
}

.action_button_add {
    @extend %button_typography_style;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0;
}

.action_button_add {
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
    &:disabled {
        color: $grey_french;
        cursor: default;
    }
}

.disabled {
    color: $grey_bombay;
    cursor: default;
    &:hover {
        color: $grey_bombay;
    }
}
</style>
