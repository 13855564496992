<template>
    <GenericPage
        :headerProps="{
            title: 'Fraud detection',
            subtitle: 'Turn your attention to detection for prevention of devastation.',
            disableBackButton: true,
        }"
    >
        <div class="fraud_detection">
            <Table
                class="fraud_detection_table"
                title="Fraud detection overview"
                :columns="[
                    { label: 'Case id', property: 'id' },
                    { label: 'Violated rule', property: 'rule' },
                    { label: 'Reviews', property: 'reviewCount' },
                    { label: 'Detected date', property: 'dateCreated' },
                    { label: 'Last updated', property: 'dateModified' },
                    { label: 'Status', property: 'statusDescription' },
                ]"
                :disableHeaderTextWrap="true"
                sortable
                :empty="tableState.data.length === 0"
                :sortableColumns="[
                    'id',
                    'rule',
                    'reviewCount',
                    'dateCreated',
                    'dateModified',
                    'statusDescription',
                ]"
                :currentPage="tableState.currentPage"
                :currentSorted="tableState.currentSorted"
                :currentSortDirection="tableState.currentSortDirection"
                :searchable="tableState.searchEnabled"
                :searchableColumns="[
                    'id',
                    'rule',
                    'reviewCount',
                    'formattedDetectionDate',
                    'formattedUpdateDate',
                    'statusDescription',
                ]"
                :searching="tableState.searchTerm !== null"
                :paginate="
                    tableState.pages > 1 ||
                    (tableState.currentPage === 1 && tableState.itemCount > 10)
                "
                :defaultPaginationSize="tableState.paginationSize"
                searchbarPlaceholder="Search"
                :pages="tableState.pages"
                @sort="sort($event)"
                @search="search($event)"
                @pageChange="onPageChange"
                @onPaginationSizeChange="setPaginationSize($event)"
            >
                <TableRow
                    v-for="(row, index) in tableState.data"
                    v-bind:key="index"
                    v-bind:isLast="tableState.data.length - 1 === index"
                    bordered
                >
                    <TableCell>
                        <router-link
                            :to="{ name: 'FraudDetectionCase', params: { caseId: row.id } }"
                            class="fraud_detection_table_link"
                        >
                            {{ row.formattedId }}
                        </router-link>
                    </TableCell>
                    <TableCell>{{ row.rule }}</TableCell>
                    <TableCell>{{ row.reviewCount }}</TableCell>
                    <TableCell>{{ row.formattedDetectionDate }}</TableCell>
                    <TableCell>{{ row.formattedUpdateDate }}</TableCell>
                    <TableCell>
                        <CaseStatusIndicator :status="row.status">{{
                            row.statusDescription
                        }}</CaseStatusIndicator>
                    </TableCell>
                </TableRow>
                <template v-slot:empty>
                    <div v-if="isLoading" class="loading-and-messages">
                        <LoaderCircular />
                    </div>
                    <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                        No results
                    </span>
                    <span v-else class="loading-and-messages"> No fraud detection </span>
                </template>
                <template v-slot:pagination-indicator>
                    <PaginationIndicator
                        :pages="tableState.pages"
                        :currentPage="tableState.currentPage"
                        :currentPageItemCount="tableState.data.length"
                        :current-page-range-start="tableState.currentPageRangeStart"
                        :current-page-range-end="tableState.currentPageRangeEnd"
                        :itemCount="tableState.itemCount"
                        itemDescription="detected cases"
                        ofString="of"
                    />
                </template>
                <template v-slot:filter>
                    <FilterButton
                        id="fraud-detection-filter"
                        @toggleFilter="openFilterTray"
                        :active="filtersApplied"
                    />
                    <Tray
                        :desktopWidth="380"
                        :left="left"
                        targetElementSelector="#fraud-detection-filter"
                        :open-percentage="filterTrayOpenPercentage"
                        @openPercentageChangeRequest="
                            ($e) => setFilterOpenPercentage($e.percentage)
                        "
                    >
                        <div class="filter__content__wrapper">
                            <div class="filter__buttons">
                                <Button
                                    button-style="secondary"
                                    @click.native="clearFraudDetectionFilters"
                                >
                                    Clear all filters
                                </Button>
                                <Button @click.native="applyFraudDetectionFilters"> Apply </Button>
                            </div>
                            <div class="filter__input">
                                <label>Status</label>

                                <div class="checkbox_filters">
                                    <div class="checkbox_filters_group">
                                        <Checkbox v-model="detected" label="Detected" />
                                        <Checkbox v-model="investigating" label="Investigating" />
                                    </div>
                                    <div class="checkbox_filters_group">
                                        <Checkbox
                                            v-model="confirmedFraud"
                                            label="Confirmed fraud"
                                        />
                                        <Checkbox v-model="declinedFraud" label="Declined fraud" />
                                    </div>
                                </div>
                                <label>Detected date</label>

                                <DateInput
                                    v-model="detectionDateRange"
                                    mode="date"
                                    isRange
                                    placeholder="Detected date"
                                    locale="en-GB"
                                    class="detected_date"
                                />
                                <label>Last updated</label>

                                <DateInput
                                    v-model="updateDateRange"
                                    mode="date"
                                    isRange
                                    placeholder="Last updated"
                                    locale="en-GB"
                                />
                            </div>
                        </div>
                    </Tray>
                </template>
            </Table>
        </div>
    </GenericPage>
</template>

<script>
import {
    useTableState,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    FilterButton,
    Button,
    Checkbox,
    LoaderCircular,
    Tray,
    DateInput,
} from "@feedbackcompany/feedback-company-vue-components";
import { ref } from "vue";
import { isAfter, isBefore, parseISO, set, format } from "date-fns";
import CaseStatusIndicator from "@/components/fraud-detection/CaseStatusIndicator.vue";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import { getFraudDetectionCases } from "@/graphql/fraud-detection/getFraudDetectionCases.gql";

const STATUS = {
    CREATED: "CREATED",
    UNDER_INVESTIGATION: "UNDER_INVESTIGATION",
    CONFIRMED_FRAUD: "CONFIRMED_FRAUD",
    NO_FRAUD: "NO_FRAUD",
};

const allStatuses = [
    STATUS.CREATED,
    STATUS.UNDER_INVESTIGATION,
    STATUS.CONFIRMED_FRAUD,
    STATUS.NO_FRAUD,
];

export default {
    name: "FraudDetection",
    components: {
        GenericPage,
        Table,
        TableRow,
        TableCell,
        PaginationIndicator,
        FilterButton,
        Button,
        Checkbox,
        LoaderCircular,
        Tray,
        DateInput,
        CaseStatusIndicator,
    },
    watch: {
        filterTrayOpenPercentage() {
            const filterButton = document.getElementById("fraud-detection-filter");
            this.left = filterButton.offsetLeft - 335;
        },
    },
    data() {
        return {
            left: 0,
        };
    },
    setup() {
        const isLoading = true;

        // General table state
        const {
            data,
            setData,
            sort,
            search,
            searchTerm,
            setPage,
            setFilters,
            filtersApplied,
            applyFilters,
            clearFilters,
            setPaginationSize,
        } = useTableState();

        // Filter state
        const filterTrayOpenPercentage = ref(0);
        const setFilterOpenPercentage = (percentage) => {
            filterTrayOpenPercentage.value = percentage;
        };

        const detected = ref(true);
        const investigating = ref(true);
        const confirmedFraud = ref(false);
        const declinedFraud = ref(false);

        const detectionDateRange = ref({ start: null, end: null });
        const updateDateRange = ref({ start: null, end: null });

        const detectedLocal = ref(false);
        const investigatingLocal = ref(false);
        const confirmedFraudLocal = ref(false);
        const declinedFraudLocal = ref(false);

        const detectionDateRangeLocal = ref({ start: null, end: null });
        const updateDateRangeLocal = ref({ start: null, end: null });

        const applyFraudDetectionFilters = () => {
            detectedLocal.value = detected.value;
            investigatingLocal.value = investigating.value;
            confirmedFraudLocal.value = confirmedFraud.value;
            declinedFraudLocal.value = declinedFraud.value;
            detectionDateRangeLocal.value = detectionDateRange.value;
            updateDateRangeLocal.value = updateDateRange.value;

            if (
                detectedLocal.value ||
                investigatingLocal.value ||
                confirmedFraudLocal.value ||
                declinedFraudLocal.value ||
                (detectionDateRangeLocal.value.start && detectionDateRangeLocal.value.end) ||
                (updateDateRangeLocal.value.start && updateDateRangeLocal.value.end)
            ) {
                applyFilters();
            } else {
                clearFilters();
            }
            filterTrayOpenPercentage.value = 0;
        };
        const clearFraudDetectionFilters = () => {
            clearFilters();
            detected.value = false;
            investigating.value = false;
            confirmedFraud.value = false;
            declinedFraud.value = false;
            detectionDateRange.value = { start: null, end: null };
            updateDateRange.value = { start: null, end: null };
            filterTrayOpenPercentage.value = 0;
        };

        // Set filter handler to be used by useTableState
        setFilters([
            ({ dateCreated, dateModified, status }) => {
                const normalisedDetectionDate = set(parseISO(dateCreated), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });

                const normalisedUpdateDate = set(parseISO(dateModified), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });

                let allowedStatuses = [];
                if (detectedLocal.value) {
                    allowedStatuses.push(STATUS.CREATED);
                }
                if (investigatingLocal.value) {
                    allowedStatuses.push(STATUS.UNDER_INVESTIGATION);
                }
                if (confirmedFraudLocal.value) {
                    allowedStatuses.push(STATUS.CONFIRMED_FRAUD);
                }
                if (declinedFraudLocal.value) {
                    allowedStatuses.push(STATUS.NO_FRAUD);
                }

                if (allowedStatuses.length === 0) {
                    allowedStatuses = allStatuses;
                }

                const detectionDateRule =
                    detectionDateRangeLocal.value.start && detectionDateRangeLocal.value.end
                        ? isAfter(normalisedDetectionDate, detectionDateRangeLocal.value.start) &&
                          isBefore(normalisedDetectionDate, detectionDateRangeLocal.value.end)
                        : true;

                const updateDateRule =
                    updateDateRangeLocal.value.start && updateDateRangeLocal.value.end
                        ? isAfter(normalisedUpdateDate, updateDateRangeLocal.value.start) &&
                          isBefore(normalisedUpdateDate, updateDateRangeLocal.value.end)
                        : true;

                return allowedStatuses.includes(status) && detectionDateRule && updateDateRule;
            },
        ]);

        return {
            isLoading,
            tableState: data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            filterTrayOpenPercentage,
            setFilterOpenPercentage,
            filtersApplied,
            clearFraudDetectionFilters,
            applyFraudDetectionFilters,
            detected,
            investigating,
            declinedFraud,
            confirmedFraud,
            detectionDateRange,
            updateDateRange,
            setPaginationSize,
        };
    },
    async mounted() {
        await this.getFraudDetectionCases();
        this.applyFraudDetectionFilters();
    },
    methods: {
        async getFraudDetectionCases() {
            this.isLoading = true;
            const response = await this.$apollo.query({
                query: getFraudDetectionCases,
            });
            const cases = cloneDeep(response.data.fraudCases);
            cases.forEach(
                // eslint-disable-next-line no-return-assign
                (fraud) => {
                    fraud.formattedDetectionDate = this.formatDate(fraud.dateCreated);
                    fraud.formattedUpdateDate = this.formatDate(fraud.dateModified);
                    fraud.statusDescription = this.statusDescription(fraud.status);
                    fraud.formattedId = String(fraud.id).padStart(4, "0");
                }
            );

            this.setData(cases, {
                paginationSize: 10,
                paginationThreshold: 11,
                paginationEnabled: true,
            });
            this.isLoading = false;
        },
        formatDate(date) {
            if (!date) return "";
            return format(new Date(date), "dd-MM-yyyy");
        },
        openFilterTray() {
            this.setFilterOpenPercentage(1);
        },
        onPageChange(page) {
            this.setPage(page);
        },
        statusDescription(status) {
            const statusDescriptionMap = {
                CREATED: "detected",
                UNDER_INVESTIGATION: "investigating",
                NO_FRAUD: "declined fraud",
                CONFIRMED_FRAUD: "confirmed fraud",
            };
            return statusDescriptionMap[status];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.fraud_detection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: $grey_bg;
    padding-bottom: 18px;
    margin-bottom: 40px;
    max-width: 950px;
}

.fraud_detection_table {
    width: 100%;
}
.fraud_detection_table_link {
    color: $blue;
    text-decoration: none;
}

.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}

.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 12px;
    }
}
.detected_date {
    margin-bottom: 28px;
}
</style>
