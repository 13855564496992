import qs from "querystring";

export const useHttp = () => {
    const httpGet = () => {
        /* eslint-disable no-console */
        console.error("[FC Error]: Method not yet implemented!");
    };

    const httpPost = ({ endPoint, body, headers = {}, noAuthHeader = false }) =>
        new Promise((resolve, reject) => {
            const CONTENT_TYPE_FORM_URLENCODED = "application/x-www-form-urlencoded";
            const defaultHeaders = { "Content-Type": CONTENT_TYPE_FORM_URLENCODED };
            if (!noAuthHeader) {
                defaultHeaders.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            }
            const requestHeaders = { ...defaultHeaders, ...headers };

            fetch(endPoint, {
                method: "POST",
                headers: requestHeaders,
                credentials: "include",
                body: qs.stringify(body),
            })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });

    const httpPut = () => {
        /* eslint-disable no-console */
        console.error("[FC Error]: Method not yet implemented!");
    };

    return {
        httpGet,
        httpPost,
        httpPut,
    };
};
