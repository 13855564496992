/**
 * This dictionary uses the name of the predicate/validator as a key.
 * The value's are the errorMessages that need to be shown when the predicate returned false.
 * Or functions which invocation shall provide those errorMessages.
 */
export const errorMessages = {
    required: "This field is required",
    minValue: (params) => `This field requires a minimum value of ${params.min}`,
    email: "This email address is not valid.",
    numeric: "This field requires a number value",
    isOneOf: (params) => {
        if (params.optionsLabels === null) {
            return "This field requires one of the presented values.";
        }

        return `This field needs to be one of the values: ${params.optionsLabels.join(", ")}.`;
    },
    qualityMarkURLRequired: "Enter a url to activate the quality mark",
    validURL: "Enter a valid url",
    maxLength: (params) => `This field can only be a ${params.max} characters long.`,
    minLength: (params) => `Minimum length of this field is ${params.min} characters.`,
};
