var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Table,{staticClass:"divisions_table divisions_table--data-tab",attrs:{"title":"Divisions","columns":[
        { label: 'Connection', property: 'status' },
        { label: 'Division', property: 'name' },
        { label: 'Invitations this month', property: 'invitationsThisMonth' },
        { label: 'Reviews this month', property: 'reviewsThisMonth' },
        { label: 'Registration date', property: 'registrationDate' },
    ],"disableHeaderTextWrap":true,"sortable":"","empty":_setup.tableState.data.length === 0,"sortableColumns":[
        'status',
        'name',
        'invitationsThisMonth',
        'reviewsThisMonth',
        'registrationDate',
    ],"currentPage":_setup.tableState.currentPage,"currentSorted":_setup.tableState.currentSorted,"currentSortDirection":_setup.tableState.currentSortDirection,"searchable":_setup.tableState.searchEnabled,"searchableColumns":[
        'status',
        'name',
        'invitationsThisMonth',
        'reviewsThisMonth',
        'registrationDate',
    ],"searching":_setup.searchTerm !== null,"paginate":_setup.tableState.pages > 1 || (_setup.tableState.currentPage === 1 && _setup.tableState.itemCount > 10),"defaultPaginationSize":_setup.tableState.paginationSize,"searchbarPlaceholder":"Search","pages":_setup.tableState.pages},on:{"sort":function($event){return _setup.sort($event)},"search":function($event){return _setup.search($event)},"pageChange":_setup.onPageChange,"onPaginationSizeChange":function($event){return _setup.setPaginationSize($event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_setup.isLoading)?_c('div',{staticClass:"loading-and-messages"},[_c(_setup.LoaderCircular)],1):(true)?_c('span',{staticClass:"loading-and-messages"},[_vm._v(" No results ")]):_c('span',{staticClass:"loading-and-messages"},[_vm._v(" No divisions ")])]},proxy:true},{key:"pagination-indicator",fn:function(){return [_c(_setup.PaginationIndicator,{attrs:{"pages":_setup.tableState.pages,"currentPage":_setup.tableState.currentPage,"currentPageItemCount":_setup.tableState.data.length,"current-page-range-start":_setup.tableState.currentPageRangeStart,"current-page-range-end":_setup.tableState.currentPageRangeEnd,"itemCount":_setup.tableState.itemCount,"itemDescription":"divisions","ofString":"of"}})]},proxy:true}])},_vm._l((_setup.tableState.data),function(row,index){return _c(_setup.TableRow,{key:index,attrs:{"isLast":_setup.tableState.data.length - 1 === index,"bordered":""}},[_c(_setup.TableCell,[_c(_setup.ConnectionDivisionIndicator,{attrs:{"status":row.status}})],1),_c(_setup.TableCell,[_c('router-link',{staticClass:"url",attrs:{"to":_setup.getDivisionUrl(row)}},[_vm._v(" "+_vm._s(row.name)+" ")])],1),_c(_setup.TableCell,[_vm._v(" "+_vm._s(row.invitationsThisMonth)+" ")]),_c(_setup.TableCell,[_vm._v(" "+_vm._s(row.reviewsThisMonth)+" ")]),_c(_setup.TableCell,[_vm._v(" "+_vm._s(row.registrationDate)+" ")])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }