/* eslint-disable class-methods-use-this */
import { dashboardApiClient, dashboardApiV2Client } from "@/helpers/evaluation/apiclients";
import store from "@/store";

export default class SplitByService {
    constructor() {
        this.splitBySymbols = {};
        this.storedSplitBySymbols = {}; // currently stored in DB

        this.availableSymbols = ["circle", "square", "diamond", "triangle", "triangle-down"];

        this.splitByColors = {};
        this.storedSplitByColors = {}; // currently stored in DB
        this.availableColors = [];

        this.propertiesLoaded = false;
        this.loadingProperties = false;
    }

    save(widgetId, splitBy) {
        return new Promise((resolve, reject) => {
            let url = `/widgets/${widgetId}/split-bys`;
            if (splitBy.id) {
                url += `/${splitBy.id}`;
            }
            let apiRequest;
            if (splitBy.id) {
                apiRequest = dashboardApiClient.put(url, splitBy);
            } else {
                apiRequest = dashboardApiClient.post(url, splitBy);
            }
            apiRequest
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    delete(widgetId, splitById) {
        return dashboardApiClient.delete(`/widgets/${widgetId}/split-bys/${splitById}`);
    }

    getColor(name) {
        if (name in this.splitByColors) {
            return this.splitByColors[name];
        }
        let nextColorIndex = Object.keys(this.splitByColors).length;
        if (nextColorIndex >= this.availableColors.length) {
            nextColorIndex %= this.availableColors.length;
        }
        const color = this.availableColors[nextColorIndex];
        this.splitByColors[name] = color.hex;
        return color.hex;
    }

    getSymbol(name) {
        if (name in this.splitBySymbols) {
            return this.splitBySymbols[name];
        }
        const symbol =
            this.availableSymbols[Math.floor(Math.random() * this.availableSymbols.length)];
        this.splitBySymbols[name] = symbol;
        return symbol;
    }

    storeProperties(encryptedWidgetId) {
        this.storeColors(encryptedWidgetId);
        this.storeSymbols(encryptedWidgetId);
    }

    storeColors(encryptedWidgetId) {
        if (JSON.stringify(this.storedSplitByColors) !== JSON.stringify(this.splitByColors)) {
            dashboardApiV2Client
                .post(this.getSplitByColorsUrl(encryptedWidgetId), this.splitByColors)
                .then((response) => {
                    this.storedSplitByColors = response.data;
                });
        }
    }

    storeSymbols(encryptedWidgetId) {
        if (JSON.stringify(this.storedSplitBySymbols) !== JSON.stringify(this.splitBySymbols)) {
            dashboardApiV2Client
                .post(this.getSplitBySymbolsUrl(encryptedWidgetId), this.splitBySymbols)
                .then((response) => {
                    this.storedSplitBySymbols = response.data;
                });
        }
    }

    initProperties(encryptedWidgetId) {
        if (!this.loadingProperties && !this.propertiesLoaded) {
            this.loadingProperties = true;

            const promises = [];
            promises.push(this.getSplitBySymbols(encryptedWidgetId));
            promises.push(this.getAvailableColors());
            promises.push(this.getSplitByColors(encryptedWidgetId));

            Promise.all(promises).then(() => {
                this.propertiesLoaded = true;
                this.loadingProperties = false;
                store.commit("DashboardStore/setSplitByPropertiesLoaded", true);
            });
        }
    }

    getSplitBySymbols(encryptedWidgetId) {
        return new Promise((resolve, reject) => {
            dashboardApiV2Client
                .get(this.getSplitBySymbolsUrl(encryptedWidgetId))
                .then((response) => {
                    if (response.data) {
                        this.splitBySymbols = response.data;
                        this.storedSplitBySymbols = { ...response.data };
                    }
                    resolve(this.splitBySymbols);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getAvailableColors() {
        return new Promise((resolve, reject) => {
            dashboardApiClient
                .get("/axis-colors")
                .then((response) => {
                    response.data.sort((color1, color2) => {
                        if (color1.order < color2.order) return -1;
                        if (color1.order > color2.order) return 1;
                        return 0;
                    });
                    this.availableColors = response.data;
                    resolve(this.availableColors);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getSplitByColors(encryptedWidgetId) {
        return new Promise((resolve, reject) => {
            dashboardApiV2Client
                .get(this.getSplitByColorsUrl(encryptedWidgetId))
                .then((response) => {
                    if (response.data) {
                        this.splitByColors = response.data;
                        this.storedSplitByColors = { ...response.data };
                    }
                    resolve(this.splitByColors);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getSplitBySymbolsUrl(encryptedWidgetId) {
        let url = "/splitby-properties/symbols";
        if (encryptedWidgetId) {
            url += `/${encryptedWidgetId}`;
        }
        return url;
    }

    getSplitByColorsUrl(encryptedWidgetId) {
        let url = "/splitby-properties/colors";
        if (encryptedWidgetId) {
            url += `/${encryptedWidgetId}`;
        }
        return url;
    }
}
