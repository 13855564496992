<template>
    <Table
        class="divisions_table divisions_table--data-tab"
        title="Divisions"
        :columns="[
            { label: 'Connection', property: 'status' },
            { label: 'Division', property: 'name' },
            { label: 'Invitations this month', property: 'invitationsThisMonth' },
            { label: 'Reviews this month', property: 'reviewsThisMonth' },
            { label: 'Registration date', property: 'registrationDate' },
        ]"
        :disableHeaderTextWrap="true"
        sortable
        :empty="tableState.data.length === 0"
        :sortableColumns="[
            'status',
            'name',
            'invitationsThisMonth',
            'reviewsThisMonth',
            'registrationDate',
        ]"
        :currentPage="tableState.currentPage"
        :currentSorted="tableState.currentSorted"
        :currentSortDirection="tableState.currentSortDirection"
        :searchable="tableState.searchEnabled"
        :searchableColumns="[
            'status',
            'name',
            'invitationsThisMonth',
            'reviewsThisMonth',
            'registrationDate',
        ]"
        :searching="searchTerm !== null"
        :paginate="
            tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
        "
        :defaultPaginationSize="tableState.paginationSize"
        searchbarPlaceholder="Search"
        :pages="tableState.pages"
        @sort="sort($event)"
        @search="search($event)"
        @pageChange="onPageChange"
        @onPaginationSizeChange="setPaginationSize($event)"
    >
        <TableRow
            v-for="(row, index) in tableState.data"
            v-bind:key="index"
            v-bind:isLast="tableState.data.length - 1 === index"
            bordered
        >
            <TableCell>
                <ConnectionDivisionIndicator :status="row.status" />
            </TableCell>

            <TableCell>
                <router-link :to="getDivisionUrl(row)" class="url">
                    {{ row.name }}
                </router-link>
            </TableCell>
            <TableCell> {{ row.invitationsThisMonth }} </TableCell>
            <TableCell> {{ row.reviewsThisMonth }} </TableCell>
            <TableCell> {{ row.registrationDate }} </TableCell>
        </TableRow>

        <template v-slot:empty>
            <div v-if="isLoading" class="loading-and-messages">
                <LoaderCircular />
            </div>
            <span v-else-if="true" class="loading-and-messages"> No results </span>
            <span v-else class="loading-and-messages"> No divisions </span>
        </template>
        <template v-slot:pagination-indicator>
            <PaginationIndicator
                :pages="tableState.pages"
                :currentPage="tableState.currentPage"
                :currentPageItemCount="tableState.data.length"
                :current-page-range-start="tableState.currentPageRangeStart"
                :current-page-range-end="tableState.currentPageRangeEnd"
                :itemCount="tableState.itemCount"
                itemDescription="divisions"
                ofString="of"
            />
        </template>
    </Table>
</template>

<script setup>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { format, parseISO } from "date-fns";
import {
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    LoaderCircular,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import ConnectionDivisionIndicator from "@/components/DivisionDataTab/ConnectionDivisionIndicator.vue";
import getDivisions from "@/graphql/overview/getDivisions.gql";
import cloneDeep from "lodash.clonedeep";
import { ref, onMounted } from "vue";
import { useQuery } from "@vue/apollo-composable";

library.add(faEdit);

const isLoading = ref(true);
const { data, setData, sort, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = ref(data);

function onPageChange(page) {
    setPage(page);
}
function formatDate(date) {
    return format(parseISO(date), "dd-MM-yyyy");
}
function getDivisionUrl(row) {
    return `/division/${row.id}/?activeTab=general`;
}

function handleResult(result) {
    const divisions = cloneDeep(result.divisions);
    // TODO: changes this mocked test data when ticket for making it real comes
    divisions.forEach((element, index) => {
        element.invitationsThisMonth = 111 + 11 * index;
        element.reviewsThisMonth = 251 + 16 * index;
        element.registrationDate = formatDate(
            new Date(+new Date() - Math.floor(Math.random() * 10000000000))
                .toISOString()
                .replace(/T.*/, "")
        );
        element.status = index % 2 === 1 ? "active" : "inactive";
    });
    divisions.sort((item1, item2) => (item1.name > item2.name ? 1 : -1));
    isLoading.value = false;
    setData(divisions);
}
onMounted(async () => {
    const { result, onResult } = useQuery(getDivisions);
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
});
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.divisions_table.divisions_table--data-tab {
    .url {
        color: $blue;
        text-decoration: none;
    }

    .loading-and-messages {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
