<script setup>
import { useLazyQuery } from "@vue/apollo-composable";
import { ref, watch, onMounted } from "vue";
import {
    useTableStateServerSide,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    IconButton,
    LoaderCircular,
    FilterButton,
    Tray,
    Button,
    Checkbox,
} from "@feedbackcompany/feedback-company-vue-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import GenericPage from "@/components/layout/GenericPage.vue";
import getCompanies from "@/graphql/overview/getCompanies.gql";
import cloneDeep from "lodash.clonedeep";
import { useStore } from "@/helpers/composition-helper";

library.add(faEdit);

const store = useStore();

const isLoading = ref(true);
const left = ref(0);
const filterTrayOpenPercentage = ref(0);
const searchTermInit = ref(null);

const {
    setData,
    sort,
    search,
    searchTerm,
    setPage,
    setPaginationSize,
    setFilters,
    state,
    visibleData,
    setState,
} = useTableStateServerSide();

const setFilterOpenPercentage = (percentage) => {
    filterTrayOpenPercentage.value = percentage;
};

const showAP = ref(false);
const showEV = ref(false);

const showAPLocal = ref(false);
const showEVLocal = ref(false);

const applyServiceFilter = () => {
    showAPLocal.value = showAP.value;
    showEVLocal.value = showEV.value;

    const services = [];
    if (showAPLocal.value) services.push("APPRECIATION");
    if (showEVLocal.value) services.push("EVALUATION");

    if (services.length) {
        setFilters([{ services }]);
    } else {
        setFilters([]);
    }
    filterTrayOpenPercentage.value = 0;
};

const clearServiceFilter = () => {
    setFilters([]);
    showAP.value = false;
    showEV.value = false;
};

const tableState = state;

function openFilterTray() {
    setFilterOpenPercentage(1);
}

function onPageChange(page) {
    setPage(page);
}

function getCompanyUrl(row) {
    return `/company/${row.id}`;
}

function mapServices(services) {
    return services.map((element) => (element === "APPRECIATION" ? "AP" : "EV")).join("/");
}

const {
    load: callLoadCompaniesQuery,
    onResult: onLoadCompaniesResult,
    onError: onLoadCompaniesError,
} = useLazyQuery(getCompanies);

onLoadCompaniesResult((result) => {
    if (result.loading) return;
    const companies = cloneDeep(result.data.companies.content);
    companies.forEach((item) => {
        item.displayedServices = mapServices(item.services);
    });

    setData(companies, result.data.companies.total, {
        searchTermThreshold: 0.1,
    });

    isLoading.value = false;
});

onLoadCompaniesError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while loading companies",
    });
});

function loadCompanies(isInitialLoad) {
    let localState = state.value;
    if (isInitialLoad) {
        localState = store.getters["OverviewStore/getState"]("companies");
        setState(localState);
    }
    const pagination = {
        page: localState.currentPage,
        perPage: localState.paginationSize,
    };
    if (localState.searchTerm) {
        if (isInitialLoad) {
            searchTermInit.value = localState.searchTerm;
        }
        pagination.search = {
            text: localState.searchTerm,
            fields: [],
        };
    }
    if (localState.currentSorted) {
        pagination.sort = {
            field: localState.currentSorted,
            direction: localState.currentSortDirection.toUpperCase(),
        };
    }

    if (isInitialLoad) {
        showAP.value = localState.showAP;
        showEV.value = localState.showEV;
        applyServiceFilter();
    }
    if (state.value.filters.length) {
        pagination.filters = [];
        state.value.filters.forEach((filter) => {
            pagination.filters.push({ field: "service", values: filter.services });
        });
    }
    callLoadCompaniesQuery(getCompanies, { pagination });
}

// React when companies get loaded.
watch(filterTrayOpenPercentage, () => {
    const filterButton = document.getElementById("dashboards-filter");
    left.value = filterButton.offsetLeft - 335;
});
watch(state, () => {
    const newTableState = cloneDeep(tableState.value);
    newTableState.showAP = showAP.value;
    newTableState.showEV = showEV.value;
    store.commit("OverviewStore/setState", { page: "companies", newState: newTableState });
    loadCompanies(false);
});

onMounted(async () => {
    loadCompanies(true);
});
</script>

<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Companies',
            subtitle: 'You are always in good company with this overview.',
            disableBackButton: true,
        }"
    >
        <Table
            class="companies_table"
            title="Companies overview"
            :columns="[
                { label: 'Name', property: 'name' },
                { label: 'Teamleader UUID', property: 'teamleaderUuid' },
                { label: 'Service', property: 'displayedServices' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="visibleData.length === 0"
            :sortableColumns="['name', 'teamleaderUuid']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="['name', 'teamleaderUuid', 'displayedServices']"
            :searching="searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            :searchTerm="searchTermInit"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in visibleData"
                v-bind:key="index"
                v-bind:isLast="visibleData.length - 1 === index"
                bordered
            >
                <TableCell>
                    <router-link :to="getCompanyUrl(row)" class="company_link">
                        {{ row.name }}
                    </router-link>
                </TableCell>
                <TableCell>{{ row.teamleaderUuid }}</TableCell>
                <TableCell>{{ row.displayedServices }}</TableCell>
                <TableCell class="controls">
                    <IconButton label="">
                        <router-link :to="getCompanyUrl(row)" v-tippy="'Edit company'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="true" class="loading-and-messages"> No results </span>
                <span v-else class="loading-and-messages"> No companies </span>
            </template>
            <template v-if="!isLoading && tableState.itemCount > 10" v-slot:filter>
                <FilterButton
                    id="dashboards-filter"
                    @toggleFilter="openFilterTray"
                    :active="showAPLocal || showEVLocal"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#dashboards-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button button-style="secondary" @click.native="clearServiceFilter">
                                Clear all filters
                            </Button>
                            <Button @click.native="applyServiceFilter"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Service</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="showAP" label="AP" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="showEV" label="EV" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="visibleData.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="companies"
                    ofString="of"
                />
            </template>
        </Table>
    </GenericPage>
</template>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors.scss";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.companies_table {
    width: 100%;
}
.controls {
    display: flex;
    justify-content: flex-end;
}
.fa-edit {
    color: #4389f8;
    &:hover {
        color: $blue_sapphire;
    }
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.company_link {
    color: $blue;
    text-decoration: none;
}

.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 20px 32px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 12px;
        width: 160px;
    }
}
</style>
