import { required } from "@/validation/validators";

export const getCreateFilterSchema = () => ({
    filter: {
        name: { required },
        platform: { required },
        category: { required },
        subcategory: { required },
    },
});
