<template>
    <div
        class="metadata-overview-status-header"
        :class="`metadata-overview-status-header-${statusData.status.toLowerCase()}`"
    >
        <div>
            <div class="metadata-overview-status-header-title">{{ formattedStatus }}</div>
            <div class="metadata-overview-status-header-subtitle">
                <span v-if="statusData.status === 'UNDER_INVESTIGATION'"
                    >Started by {{ statusData.creator }}</span
                >
                <span v-else>{{ dateLabel }}</span>
            </div>
        </div>
        <div>
            <FontAwesomeIcon :icon="['fas', availableIcons[statusData.status]]" />
        </div>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faClipboardList,
    faCommentAltExclamation,
    faTimesCircle,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { formatDistanceStrict } from "date-fns";
import { ref, computed } from "vue";

library.add(faClipboardList, faCommentAltExclamation, faTimesCircle, faCheckCircle);

const availableIcons = ref({
    CREATED: "message-exclamation",
    UNDER_INVESTIGATION: "clipboard-list",
    NO_FRAUD: "times-circle",
    CONFIRMED_FRAUD: "circle-check",
});

const props = defineProps({
    statusData: {
        type: Object,
        required: true,
        validator(statusData) {
            const statuses = ["CREATED", "UNDER_INVESTIGATION", "NO_FRAUD", "CONFIRMED_FRAUD"];
            if (!statuses.includes(statusData.status)) {
                /* eslint-disable no-console */
                console.error(
                    `[FC Warn] invalid status passed, supported statuses are: "${statuses.join(
                        ", "
                    )}".`
                );
                return false;
            }
            return true;
        },
    },
});

// computed
const formattedStatus = computed(() => {
    if (props.statusData.status === "CREATED") return "Detected";
    return (
        props.statusData.status.charAt(0).toUpperCase() +
        props.statusData.status.toLowerCase().replace(/_/g, " ").slice(1)
    );
});
const dateLabel = computed(() => {
    const baseDate = props.statusData.dateCreated;
    const formattedDate = formatDistanceStrict(new Date(baseDate), new Date());
    return `${formattedDate} ago`;
});
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.metadata-overview-status {
    &-header {
        padding: 8px 16px;
        color: $white;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-created {
            background-color: $blue;
        }
        &-under_investigation {
            background-color: $navy;
        }
        &-no_fraud {
            background-color: $green;
        }
        &-confirmed_fraud {
            background-color: $red;
        }
        &-title {
            font-weight: 600;
        }
        &-subtitle {
            font-weight: 300;
        }
    }
}
</style>
