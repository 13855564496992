import { errorMessages } from "./errorMessages";

/**
 * Opinionated way for resolving error messages based on Vuelidate's state provided as arg.
 * An error message is only resolved whenever $error yields true.
 * @see https://vuelidate.js.org/#sub-v-values
 * @param vuelidateItem
 * @returns {string}
 */
export const resolveErrorMessage = (vuelidateItem) => {
    if (!vuelidateItem.$error) return "";

    // The object keys from vuelidateItem that don't start with '$' are being extracted.
    // When a key carries a false value, that means that the validation predicate returned false.
    // Which means the field is invalid.
    const predicatesThatReturnedFalse = Object.keys(vuelidateItem).filter(
        (key) => key.charAt(0) !== "$" && vuelidateItem[key].$invalid
    );

    const correspondingErrorMessages = predicatesThatReturnedFalse.map((predicate) => {
        const correspondingErrorMessage = errorMessages[predicate];

        if (typeof correspondingErrorMessage === "undefined") {
            /* eslint-disable no-console */
            console.error(
                `[FC Warn]: Attempt to resolve errorMessage for '${predicate}' failed. Make sure it has been defined.`
            );
            return "";
        }

        if (typeof correspondingErrorMessage === "function" && correspondingErrorMessage.length) {
            const paramsForPredicate = vuelidateItem
                .$flattenParams()
                .reduce((prevVal, currentVal) => {
                    if (currentVal.name !== predicate) return null;
                    return { ...currentVal.params };
                });

            if (!paramsForPredicate) {
                /* eslint-disable no-console */
                console.error(
                    `[FC Warn]: There are no params set for predicate '${predicate}'. Make sure they are set by the validator. Alternatively, when the errormessage does not need params, remove the parameter from the errorMessage function signature.`
                );
                return "";
            }

            return correspondingErrorMessage(
                // custom validators built with the useParams helper nest params an extra level deep.
                typeof paramsForPredicate.params === "undefined"
                    ? paramsForPredicate
                    : paramsForPredicate.params
            );
        }

        return typeof correspondingErrorMessage === "string"
            ? correspondingErrorMessage
            : correspondingErrorMessage();
    });

    return correspondingErrorMessages[0];
};
