<template>
    <div>
        <h1 class="title">Edit service identifiers</h1>
        <div class="fields">
            <TextInput
                :value="identifiers.externalId"
                :label="isCompany ? 'Company ID' : 'Division ID'"
                disabled
            />
            <Dropdown
                v-model="identifiers.platform"
                placeholder="Choose a platform"
                :searchAble="platforms.length > 3"
                :options="platforms"
                enableExternalValidation
                :externalValidationError="v$.identifiers.platform.$error"
                externalValidationErrorMessage=""
            />
            <Dropdown
                v-model="identifiers.subPlatform"
                :disabled="!identifiers.platform"
                placeholder="Choose a custom platform"
                :searchAble="subPlatforms.length > 3"
                :options="subPlatforms"
            />
            <Dropdown
                v-model="identifiers.entityType"
                placeholder="Entity type"
                :searchAble="types.length > 3"
                :options="types"
                enableExternalValidation
                :externalValidationError="v$.identifiers.entityType.$error"
                externalValidationErrorMessage=""
            />
            <TextInput
                v-model="identifiers.entityId"
                label="Entity ID"
                enableExternalValidation
                :externalValidationError="v$.identifiers.entityId.$error"
            />
            <strong
                v-if="
                    v$.identifiers.platform.$error ||
                    v$.identifiers.entityId.$error ||
                    v$.identifiers.entityType.$error
                "
                class="custom-validation-message"
            >
                All fields need to be filled in.
            </strong>
        </div>
        <div class="actions">
            <Button @click.native="$emit('close')" buttonStyle="secondary" class="actions_cancel">
                <span>Cancel</span>
            </Button>
            <Button
                @click.native="validate"
                buttonStyle="primary"
                :loading="loading"
                :disabled="!isDirty"
            >
                <span>Save</span>
            </Button>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, computed, watch, onMounted } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { TextInput, Dropdown, Button } from "@feedbackcompany/feedback-company-vue-components";
import cloneDeep from "lodash.clonedeep";
import deepEqual from "deep-equal";
import { resolveErrorMessage, validationMixin } from "@/validation";
import { useVuelidate } from "@vuelidate/core";
import getPlatforms from "../graphql/getPlatforms.gql";
import { editIdentifiersSchema } from "../validation/schemas/editIdentifiers";

const emit = defineEmits(["save"]);

const props = defineProps({
    data: {
        type: Object,
    },
    loading: {
        type: Boolean,
    },
    type: {
        type: String,
        default: "company",
    },
});

const platforms = ref([]);
const subPlatforms = ref([]);
const subPlatformsMap = ref(new Map());
const identifiers = ref(cloneDeep(props.data));
const originalIdentifiers = ref(cloneDeep(props.data));

const types = ref([
    { value: "HOLDING", displayValue: "holding" },
    { value: "ORGANISATION", displayValue: "organisation" },
    { value: "LOCATION", displayValue: "location" },
]);

const v$ = useVuelidate(editIdentifiersSchema(), { identifiers });

const isDirty = computed(() => {
    return !deepEqual(originalIdentifiers.value, identifiers.value);
});

const isCompany = computed(() => {
    return props.type === "company";
});

function validate() {
    v$.value.$touch();
    if (v$.value.$error) return;
    emit("save", identifiers.value);
}

function mapSubPlatforms(platform) {
    subPlatformsMap.value.set(
        platform.name,
        platform.subPlatforms.map((subPlatform) => ({
            value: subPlatform.name,
            displayValue: subPlatform.name,
        }))
    );
}
function setSubPlatforms() {
    if (!identifiers.value.platform) subPlatforms.value = [];
    const localSubPlatforms = subPlatformsMap.value.get(identifiers.value.platform);
    if (localSubPlatforms) subPlatforms.value = localSubPlatforms;
}

function setPlatforms(response) {
    const localPlatforms = cloneDeep(response);
    platforms.value = localPlatforms.map((platform) => {
        mapSubPlatforms(platform);
        return {
            value: platform.name,
            displayValue: platform.name,
        };
    });
    setSubPlatforms();
}

onMounted(async () => {
    const { result, onResult } = useQuery(getPlatforms);
    if (result.value) setPlatforms(result.value.platforms);
    onResult((response) => {
        setPlatforms(response.data.platforms);
    });
});

watch(
    () => identifiers.value.platform,
    () => {
        setSubPlatforms();
    }
);
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables";

.title {
    @extend %headline5_style;
    margin-bottom: 30px;
}
.fields {
    * {
        margin-bottom: 12px;
    }
}
.actions {
    display: flex;
    justify-content: flex-end;
    .actions_cancel {
        margin-right: 12px;
    }
}
.custom-validation-message {
    @extend %caption_style;
    margin-left: 13px;
    display: block;
    color: $red;
}
</style>
