<template>
    <div class="access_contaier">
        <div v-if="isLoading" class="loading-and-messages">
            <LoaderCircular />
        </div>
        <SettingsBlock v-else class="settings_block" title="Access">
            <div class="access_subtitle">
                You can grant this user extra access by adding items back to the menu. Select the
                item that you want to add to the menu in the Customer Portal for this user OR
                unselect an item to not have it in the Customer Portal menu anymore.
            </div>
            <div class="checkbox">
                <Checkbox
                    class="checkbox"
                    label="Review form"
                    v-model="accessPermissions.reviewForm"
                />
            </div>
            <div class="checkbox">
                <Checkbox
                    class="checkbox"
                    label="Review invitations"
                    v-model="accessPermissions.reviewInvitations"
                />
            </div>
            <div class="checkbox">
                <Checkbox
                    class="checkbox"
                    label="Statistics"
                    v-model="accessPermissions.statistics"
                />
            </div>
            <div class="checkbox">
                <Checkbox class="checkbox" label="Inbox" v-model="accessPermissions.inbox" />
            </div>
        </SettingsBlock>
        <portal to="editUserFooter">
            <div class="footer">
                <Button :loading="isSaving" :disabled="!isDirty" @click.native="save">
                    Save
                </Button>
                <Button
                    class="cancel-button"
                    buttonStyle="secondary"
                    @click.native="router.push(previousPage)"
                >
                    Cancel
                </Button>
            </div>
        </portal>
    </div>
</template>

<script setup>
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import { Checkbox, Button, LoaderCircular } from "@feedbackcompany/feedback-company-vue-components";
import { ref, onMounted, computed } from "vue";
import { useRouter, useStore } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import getAccessPermissions from "@/graphql/accessPermissions.gql";
import updateAccessPermissions from "@/graphql/updateAccessPermissions.gql";
import cloneDeep from "lodash.clonedeep";

import deepEqual from "deep-equal";

const router = useRouter();
const store = useStore();

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    previousPage: {
        type: String,
        default: "/users",
    },
});
const emptyData = {
    reviewForm: false,
    reviewInvitations: false,
    statistics: false,
    inbox: false,
};
const accessPermissions = ref(cloneDeep(emptyData));
const originalAccessPermissions = ref(cloneDeep(emptyData));
const isSaving = ref(false);
const isLoading = ref(true);

const isDirty = computed(() => {
    return !deepEqual(accessPermissions.value, originalAccessPermissions.value);
});

function handleAccessPermissionsResult(result) {
    accessPermissions.value = cloneDeep(result.accessPermissions);
    originalAccessPermissions.value = cloneDeep(result.accessPermissions);
    isLoading.value = false;
}

function getUserAccessRights() {
    const { result: accessPermissionsResults, onResult: onAccessPermissionsResult } = useQuery(
        getAccessPermissions,
        {
            userId: props.data.id,
        }
    );
    if (accessPermissionsResults.value)
        handleAccessPermissionsResult(accessPermissionsResults.value);
    onAccessPermissionsResult((response) => {
        handleAccessPermissionsResult(response.data);
    });
}

// mutation
const { mutate: mutateAccess, onDone, onError } = useMutation(updateAccessPermissions);
onDone((result) => {
    if (result.data.updateAccessPermissions) {
        store.dispatch("pushNotification", {
            type: "success",
            title: "Updated",
            message: "User access permissions succesfully updated!",
        });
    }
});
onError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while trying to update user access permissions.",
    });
});

function save() {
    delete accessPermissions.value.__typename;
    const variables = {
        userId: props.data.id,
        input: accessPermissions.value,
    };
    mutateAccess(variables, {
        refetchQueries: [{ query: getAccessPermissions, variables: { userId: props.data.id } }],
    });
}

onMounted(() => {
    getUserAccessRights();
});

defineExpose({
    isDirty,
    save,
});
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.access_subtitle {
    @extend %body2_style;
    margin-top: 30px;
    margin-bottom: 24px;
    max-width: 575px;
}
.checkbox {
    margin-bottom: 10px;
}
.access_contaier {
    margin-bottom: 60px;
}
.footer {
    width: 100%;
    display: flex;
    .cancel-button {
        margin-left: 12px;
    }
}
</style>
