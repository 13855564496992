<template>
    <div class="container">
        <h2 class="popup_title">Create a new questionnaire</h2>
        <SettingsForm class="popup_form" ref="formEl">
            <label class="popup_form_label">Name of questionnaire</label>
            <TextInput
                label="Name"
                class="input"
                v-model="newQuestionnaire.name"
                enableExternalValidation
                :externalValidationError="v$.newQuestionnaire.name.$error"
                externalValidationErrorMessage="Please choose a name"
            />
            <div class="divider"></div>

            <label class="popup_form_label">Logo</label>
            <div class="popup_form_helptext">
                Upload the logo of the customer that’s shown on the form.
            </div>
            <FileInput
                type="image"
                placeholderText="Choose an image"
                class="fullwidth_input"
                v-model="newQuestionnaire.logo"
            />

            <h3 class="popup_form_heading">Settings</h3>

            <label class="popup_form_label">Formal or informal</label>
            <div class="popup_form_helptext">
                Should the form communicate to the respondent in a formal way or informal?
            </div>
            <RadioList
                class="input"
                v-model="newQuestionnaire.formal"
                :options="[
                    { value: false, displayValue: 'Informal (Je / Jij)' },
                    { value: true, displayValue: 'Formal (U / Uw)' },
                ]"
                required
            />
            <div class="divider"></div>

            <label class="popup_form_label">Language</label>
            <div class="popup_form_helptext">
                Select the language of the questionnaire. The selected language only affects default
                messages.
            </div>
            <Dropdown
                class="fullwidth_input input"
                v-model="newQuestionnaire.defaultLanguage"
                placeholder="Select a language"
                showRequiredAsterisk
                :options="[
                    { value: 'en', displayValue: 'English' },
                    { value: 'nl', displayValue: 'Dutch' },
                    { value: 'es', displayValue: 'Spanish' },
                    { value: 'fr', displayValue: 'French' },
                ]"
                enableExternalValidation
                :externalValidationError="v$.newQuestionnaire.defaultLanguage.$error"
                externalValidationErrorMessage="Please choose a language"
            />
            <div class="divider"></div>

            <div class="popup_form_switches">
                <div class="switch_group">
                    <SwitchInput class="switch" v-model="newQuestionnaire.showDonate" small />
                    <label class="switch_group_label">
                        Donate button for Elemental Water Foundation is present on thank-you page
                    </label>
                </div>
                <div class="switch_group">
                    <SwitchInput class="switch" v-model="newQuestionnaire.showProgressBar" small />
                    <label class="switch_group_label">Progress bar present on form</label>
                </div>
                <div class="switch_group">
                    <SwitchInput class="switch" v-model="newQuestionnaire.kioskMode" small />
                    <label class="switch_group_label">
                        The form reloads automatically 15 seconds after ending up on the thank-you
                        page
                    </label>
                </div>
            </div>
        </SettingsForm>
        <div class="popup_footer">
            <Button @click.native="createNewQuestionnaire">Save</Button>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import {
    Button,
    TextInput,
    RadioList,
    SwitchInput,
    Dropdown,
    FileInput,
} from "@feedbackcompany/feedback-company-vue-components";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import { ref, nextTick } from "vue";
import { useStore } from "@/helpers/composition-helper";
import cloneDeep from "lodash.clonedeep";
import createQuestionnaire from "@/graphql/createQuestionnaire.gql";
import { useVuelidate } from "@vuelidate/core";
import { useMutation } from "@vue/apollo-composable";
import { scrollToFirstError } from "@/validation";
import { newQuestionnaireSchema } from "../validation/schemas/newQuestionnaire";

const store = useStore();

const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
});

const formEl = ref(null);
const emit = defineEmits(["saveQuestionnaire"]);

const initialQuestData = ref({
    companyId: props.company.id,
    type: "EVALUATION",
    name: "",
    defaultLanguage: "",
    anonymous: true,
    formal: true,
    showDonate: true,
    showProgressBar: true,
    kioskMode: true,
    logo: null,
    serviceIdentifiers: {
        platform: props.company.platform,
        entityType: props.company.entityType,
        entityId: props.company.entityId,
    },
});

const newQuestionnaire = ref(cloneDeep(initialQuestData.value));

const v$ = useVuelidate(newQuestionnaireSchema(), { newQuestionnaire });

const { mutate, onDone, onError } = useMutation(createQuestionnaire);
onDone((res) => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "Success",
        message: "The new questionnaire has been created.",
    });
    emit("saveQuestionnaire", res.data.createQuestionnaire.id);
    newQuestionnaire.value = cloneDeep(initialQuestData.value);
});
onError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "The questionnaire could not be created.",
    });
});

function createNewQuestionnaire() {
    v$.value.$touch();
    if (v$.value.$error || !!formEl.value.$el.querySelector("[class*=--invalid]")) {
        nextTick(() => {
            scrollToFirstError(formEl.value.$el);
        });
        return;
    }
    mutate({ input: newQuestionnaire.value });
}
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";

.container {
    width: 800px;
    max-width: 100%;
    max-height: calc(100vh - 96px);
    overflow-y: auto;
    font-family: "Open Sans", sans-serif;
}
.popup {
    &_title {
        margin: 0;
        margin-bottom: 28px;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        padding: 8px 0 24px;
        border-bottom: 1px solid $grey_alabaster;
    }
    &_form {
        max-width: 400px;
        background-color: $white;
        align-items: flex-start;

        &_label {
            font-weight: 600;
            margin-bottom: 4px;
        }
        &_helptext {
            font-size: 14px;
            margin-bottom: 8px;
        }
        &_heading {
            width: 100%;
            margin: 32px 0 24px;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            padding: 8px 0 24px;
            border-bottom: 1px solid $grey_alabaster;
        }
        &_switches {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
    &_footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }
}
.divider {
    height: 1px;
    width: 100%;
    background-color: $grey_alabaster;
    margin: 24px 0;
}
.input {
    margin-top: 8px;
}
.fullwidth_input {
    margin-top: 8px;
    width: 100%;
}
.switch_group {
    display: flex;
    align-items: center;
    gap: 16px;

    &_label {
        font-size: 14px;
    }
    .switch {
        flex-shrink: 0;
    }
}
</style>
