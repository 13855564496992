/* eslint-disable func-names, no-plusplus */
import moment from "moment";
import store from "@/store";

export default {
    methods: {
        getPortalUrl() {
            return process.env.VUE_APP_EV_API_BASE_URL;
        },
        getDashboardUrl() {
            return `${this.getPortalUrl()}/dashboard`;
        },
        getDashboardApiUrl() {
            return `${this.getDashboardUrl()}/api/v1`;
        },
        getDashboardApiV2Url() {
            return `${this.getDashboardUrl()}/api/v2`;
        },
        getDataApiUrl() {
            return `${this.getPortalUrl()}/data-api/api/v1`;
        },
        getUsersApiUrl() {
            return `${this.getPortalUrl()}/users-api/api/v1`;
        },
        displayMessage(message, type) {
            if (["success", "error"].includes(type))
                store.dispatch("pushNotification", { type, message });
            else this.$message({ message, type });
        },
        displayAlert(title, message, type, isHtml, confirmBtnText, callbackFunc) {
            confirmBtnText = confirmBtnText || this.translate("OK");
            callbackFunc = callbackFunc || function () {};
            this.$alert(message, title, {
                type,
                dangerouslyUseHTMLString: isHtml,
                confirmButtonText: confirmBtnText,
                closeOnClickModal: true,
                closeOnPressEscape: true,
                callback: callbackFunc,
            });
        },
        translate(key, params) {
            if (params) {
                return this.$i18n.t(key, params);
            }
            return this.$i18n.t(key);
        },
        isValueNumeric(value) {
            // eslint-disable-next-line no-restricted-globals
            return value != null && !isNaN(value);
        },
        removeEmptyStrings(stringArray) {
            const result = [];
            for (let i = 0; i < stringArray.length; i++) {
                if (stringArray[i].length > 0) {
                    result.push(stringArray[i]);
                }
            }
            return result;
        },
        toggleDropdown(event) {
            const dropdown = event.currentTarget;
            if (dropdown.classList.contains("is-active")) {
                dropdown.classList.remove("is-active");
                dropdown.nextElementSibling.classList.remove("is-open");
            } else {
                dropdown.classList.add("is-active");
                dropdown.nextElementSibling.classList.add("is-open");
            }
        },
        closeDropdown(event, dropdown) {
            dropdown.classList.remove("is-active");
            dropdown.nextElementSibling.classList.remove("is-open");
        },
        copyObject(obj) {
            return obj != null ? JSON.parse(JSON.stringify(obj)) : null;
        },
        getMaxTableHeight() {
            let tableHeight = window.innerHeight - 335;
            if (tableHeight < 100) {
                tableHeight = 100;
            }
            return tableHeight;
        },
        isEmptyObject(obj) {
            if (typeof obj !== "object") {
                return false;
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(key)) return false;
            }
            return true;
        },
        stringToBoolean(value) {
            if (value) {
                const stringValue = value.toString().toLowerCase();
                if (stringValue === "true") return true;
                if (stringValue === "false") return false;
            }
            return null;
        },
        rowHasNestedRows(row) {
            let hasNestedRows = false;
            $.each(row.columns, (index, column) => {
                if (column.rows && column.rows.length > 0) {
                    hasNestedRows = true;
                    return false;
                }
            });
            return hasNestedRows;
        },
        rowHasWidgets(row) {
            let hasWidgets = false;
            $.each(row.columns, (index, column) => {
                if (column.widgets && column.widgets.length > 0) {
                    hasWidgets = true;
                    return false;
                }
            });
            return hasWidgets;
        },
        hasEmptyColumn(columns) {
            let hasEmptyColumn = false;
            $.each(columns, (index, column) => {
                if (column.widgets.length === 0) {
                    hasEmptyColumn = true;
                    return false;
                }
            });
            return hasEmptyColumn;
        },
        findWidget(configuration, widgetId, widgetType) {
            let widget = null;
            $.each(configuration.rows, (rowIndex, row) => {
                widget = this.findWidgetInRow(row, widgetId, widgetType);
                if (widget != null) {
                    return false;
                }
            });
            return widget;
        },
        findWidgetInRow(row, widgetId, widgetType) {
            let widget = null;
            $.each(row.columns, (columnIndex, column) => {
                $.each(column.widgets, (widgetIndex, columnWidget) => {
                    if (
                        columnWidget.config.widgetId === widgetId &&
                        columnWidget.type === widgetType
                    ) {
                        widget = columnWidget;
                        return false;
                    }
                });
                // eslint-disable-next-line no-mixed-operators
                if (widget == null || (column.rows && column.rows.length > 0)) {
                    $.each(column.rows, (rowIndex, columnRow) => {
                        widget = this.findWidgetInRow(columnRow, widgetId, widgetType);
                        if (widget != null) {
                            return false;
                        }
                    });
                }
                if (widget != null) {
                    return false;
                }
            });
            return widget;
        },
        getColumnWidth(columns, columnIndex) {
            if (columns[columnIndex].styleClass) {
                return Number(columns[columnIndex].styleClass.slice(7));
            }
            return 12;
        },
        setColumnWidth(column, width) {
            column.styleClass = `col-md-${width}`;
        },
        getSortProperty(name, order) {
            return (order === "descending" ? "-" : "") + name;
        },
        objectSortFunc(field) {
            let sortOrder = 1;
            if (field[0] === "-") {
                sortOrder = -1;
                field = field.substr(1);
            }

            return function (a, b) {
                let af = null;
                let bf = null;
                let result = 0;

                // If we are sorting by the main table axis (first column)  - we look at the sort value of the field, as its a complex object.
                if (!field || field === 0) {
                    field = 0;
                    af =
                        typeof a[field].sortValue === "string"
                            ? a[field].sortValue.trim()
                            : a[field].sortValue;
                    bf =
                        typeof b[field].sortValue === "string"
                            ? b[field].sortValue.trim()
                            : b[field].sortValue;
                } else {
                    // Otherwise we look at just the field itself, as its a simple object
                    af = a[field];
                    bf = b[field];
                }

                // General regex for recognising numbers.
                const numberPattern = /^(-?)(\d+)([.](\d+))?$/; // Regex: optional minus, n numbers, optional point, n numbers

                // Regex setup for recognising dates, simple (YYYY MM) and full (YYYY-MM-DD)
                const datePatternSimple = /^(\d{4})\s(\d{2})$/; // Regex: YYYY MM
                const datePatternFull = /^(\d{4})-(\d{2})-(\d{2})$/; // Regex: YYYY-MM-DD

                // The same pattern as in the above regex, written to be understood by moment.js
                const momentPatternSimple = "YYYY MM";
                const momentPatternFull = "YYYY-MM-DD";

                // Dates
                // If both fields pass the date pattern test we convert to moment and compare.
                if (datePatternSimple.test(af) && datePatternSimple.test(bf)) {
                    const dateA = moment(af, momentPatternSimple);
                    const dateB = moment(bf, momentPatternSimple);

                    result = dateA.isBefore(dateB) ? 1 : -1;
                    // If our "a" field doesn't pass the pattern test but "b" does.
                } else if (!datePatternSimple.test(af) && datePatternSimple.test(bf)) {
                    result = 1;
                    // If our "b" field doesn't pass the pattern test but "a" does.
                } else if (datePatternSimple.test(af) && !datePatternSimple.test(bf)) {
                    result = -1;
                } else if (datePatternFull.test(af) && datePatternFull.test(bf)) {
                    const dateA = moment(af, momentPatternFull);
                    const dateB = moment(bf, momentPatternFull);

                    result = dateA.isBefore(dateB) ? 1 : -1;
                    // If our "a" field doesn't pass the pattern test but "b" does.
                } else if (!datePatternFull.test(af) && datePatternFull.test(bf)) {
                    result = 1;
                    // If our "b" field doesn't pass the pattern test but "a" does.
                } else if (datePatternFull.test(af) && !datePatternFull.test(bf)) {
                    result = -1;

                    // Numbers
                    // If both field pass the pattern test.
                } else if (numberPattern.test(af) && numberPattern.test(bf)) {
                    try {
                        const parsedA = parseFloat(af);
                        const parsedB = parseFloat(bf);
                        result = parsedA > parsedB ? 1 : -1;
                    } catch (e) {
                        result = af > bf ? 1 : -1;
                    }
                    // If our "a" field doesn't pass the pattern test but "b" does.
                } else if (!numberPattern.test(af) && numberPattern.test(bf)) {
                    result = 1;
                    // If our "b" field doesn't pass the pattern test but "a" does.
                } else if (numberPattern.test(af) && !numberPattern.test(bf)) {
                    result = -1;

                    // String
                    // If our "a" field is empty but the "b" field is not
                } else if (af === "" && !(bf === "")) {
                    result = 1;
                    // If our "b" field is empty but the "a" field is not
                } else if (!(af === "") && bf === "") {
                    result = -1;
                } else {
                    // If both fields pass the pattern test we consider them strings (and convert them to strings with (+ "") just in case)
                    result = `${af}`.toLowerCase() > `${bf}`.toLowerCase() ? 1 : -1;
                }

                return result * sortOrder;
            };
        },
        moveElementToPosition(elements, elementId, oldPosition, newPosition) {
            let elementToMove = null;
            $.each(elements, (index, element) => {
                if (element.id === elementId) {
                    elementToMove = element;
                }
                if (
                    newPosition > oldPosition &&
                    element.position > oldPosition &&
                    element.position <= newPosition
                ) {
                    element.position -= 1;
                } else if (element.position >= newPosition && element.position < oldPosition) {
                    element.position += 1;
                }
            });
            if (elementToMove != null) {
                elementToMove.position = newPosition;
            }
            elements.sort(this.objectSortFunc("position"));
        },
    },
};
