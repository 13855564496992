<template>
    <div id="app">
        <LogoutTray id="account-switch-tray" />
        <NotificationRail :notificationList="pushNotificationList" />
        <portal-target name="popups_container" />
        <header class="main_header">
            <DesktopMenuBar gradient><UserHeaderButton /></DesktopMenuBar>
        </header>
        <section class="main_section">
            <MainNavigation>
                <NavigationList>
                    <NavigationListItem
                        url="/companies"
                        type="link"
                        :isActive="
                            [
                                'Companies',
                                'Company',
                                'DashboardsBridge',
                                !!$route.query.companyId && 'CreateUser',
                            ].indexOf($route.name) !== -1
                        "
                    >
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'building']" />
                        </template>
                        Companies
                    </NavigationListItem>
                    <NavigationListItem
                        url="/divisions"
                        type="link"
                        :isActive="
                            [
                                'Divisions',
                                'Division',
                                !!$route.query.divisionId && 'CreateUser',
                            ].indexOf($route.name) !== -1
                        "
                    >
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'sitemap']" />
                        </template>
                        Divisions
                    </NavigationListItem>
                    <NavigationListItem
                        url="/users"
                        type="link"
                        :isActive="['Users', 'EditUser'].indexOf($route.name) !== -1"
                    >
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'user']" />
                        </template>
                        Users
                    </NavigationListItem>
                    <NavigationNestedList
                        :isActive="
                            ['WidgetTemplates', 'WidgetEditorBridge', 'ManageFilters'].indexOf(
                                $route.name
                            ) !== -1
                        "
                    >
                        <template v-slot:title>Research</template>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'chart-bar']" />
                        </template>
                        <NavigationNestedListItem type="link" url="/manage-filters">
                            Manage filters
                        </NavigationNestedListItem>
                        <NavigationNestedListItem type="link" url="/widget-templates">
                            Widget templates
                        </NavigationNestedListItem>
                    </NavigationNestedList>
                    <NavigationListItem
                        url="/fraud-detection"
                        type="link"
                        :isActive="['FraudDetection'].indexOf($route.name) !== -1"
                    >
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'comment-alt-exclamation']" />
                        </template>
                        Fraud detection
                    </NavigationListItem>
                    <NavigationListItem
                        url="/anonymise-user"
                        type="link"
                        :isActive="['AnonymiseUser'].indexOf($route.name) !== -1"
                    >
                        <template v-slot:icon>
                            <font-awesome-icon :icon="['fal', 'eye-slash']" />
                        </template>
                        Anonymise consumer
                    </NavigationListItem>
                </NavigationList>
            </MainNavigation>
            <main class="main_content">
                <router-view />
            </main>
        </section>
    </div>
</template>

<script setup>
import {
    MainNavigation,
    NavigationList,
    NavigationListItem,
    NavigationNestedList,
    NavigationNestedListItem,
    NotificationRail,
    DesktopMenuBar,
} from "@feedbackcompany/feedback-company-vue-components";
import {
    faBuilding,
    faSitemap,
    faUser,
    faCommentAltExclamation,
    faChartBar,
} from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import UserHeaderButton from "@/components/layout/Atoms/UserHeaderButton.vue";
import LogoutTray from "@/components/layout/Molecules/LogoutTray.vue";
import { computed } from "vue";
import { useStore } from "@/helpers/composition-helper";

const store = useStore();
library.add(faBuilding, faSitemap, faUser, faCommentAltExclamation, faChartBar);

const pushNotificationList = computed(() => store.state.PushNotificationsStore.all);
</script>

<style lang="scss">
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/style_variables.scss";

html,
body {
    margin: 0px;
    padding: 0px;
    overscroll-behavior: none;
    overflow: hidden;
    height: 100%;
}

* {
    box-sizing: border-box;
}

input {
    -webkit-appearance: none;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $grey_bg;

    .main_header {
        width: 100%;
    }

    .main_section {
        display: flex;
        height: 100%;
        overflow-y: hidden;

        nav {
            height: 100%;
            flex-basis: $nav_width;
        }

        .main_content {
            height: 100%;
            flex-grow: 1;
            overflow: auto;
        }
    }
}

.tippy-tooltip {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
}

.navigation_item__icon {
    text-align: center;
}
</style>
