<template>
    <SettingsLayout
        :headerProps="{
            title: 'Anonymise consumer',
            disableBackButton: true,
        }"
    >
        <template v-slot:main>
            <SettingsForm class="settings_form">
                <SettingsBlock class="settings_block" title="Search consumer">
                    <SettingsFieldSet
                        class="settings_fieldset"
                        label="Anonymise consumer by emailaddress"
                    >
                        <label class="body2">
                            Type the emailaddress of the consumer you want to anonymise.
                        </label>
                        <label class="subtitle3">Email</label>
                        <TextInput label="Email" v-model="consumer.consumerEmail" />
                    </SettingsFieldSet>
                </SettingsBlock>
            </SettingsForm>
        </template>
        <template v-slot:footer>
            <SettingsFooter>
                <Button
                    :loading="isSearching"
                    :disabled="!consumer.consumerEmail"
                    @click.native="searchConsumer"
                >
                    Search
                </Button>
            </SettingsFooter>
        </template>
    </SettingsLayout>
</template>

<script setup>
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useStore } from "@/helpers/composition-helper";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Sentry from "@sentry/vue";
import { TextInput, Button } from "@feedbackcompany/feedback-company-vue-components";
import SettingsLayout from "@/components/layout/SettingsLayout.vue";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import SettingsFooter from "@/components/layout/SettingsFooter.vue";
import { anonymiseConsumer } from "@/graphql/anonymiseConsumer.gql";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";

const store = useStore();
library.add(faEyeSlash);

const isSearching = ref(false);
const consumer = ref({
    consumerEmail: "",
});
const prevRoute = ref("");

defineExpose({
    prevRoute,
});

const { mutate, onDone, onError } = useMutation(anonymiseConsumer);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "Consumer anonymised",
        message: "If the emailaddress was found in our database, the data is now anonymised.",
    });
    isSearching.value = false;
});
onError((error) => {
    Sentry.captureException(error);
    if (resolveGraphQLErrors(error)) {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "Something went wrong.",
        });
    }
    isSearching.value = false;
});

function searchConsumer() {
    isSearching.value = true;
    const { consumerEmail } = consumer.value;
    const variables = {
        input: {
            email: consumerEmail,
        },
    };
    mutate(variables);
}
</script>

<script>
export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
};
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 20px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }
}
.settings_fieldset * {
    display: inline-block;
    margin-bottom: 12px;
}
.settings_fieldset .subtitle3 {
    margin-top: 18px;
}
.settings_fieldset::v-deep .formgroup__header {
    padding: 0 0 0 0;
}
.text_input_holder {
    width: 100%;
}
.header__subtitle {
    display: flex;
    align-items: center;
}
.header__subtitle__icon {
    width: 14px;
    height: 14px;
    margin: 0 10px 0 2px;
}
.settings_form::v-deep .formgroup__header {
    margin-bottom: 0;
}
</style>
