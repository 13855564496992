/**
 * Extracting the custom errors as defined by the GraphQL server.
 * @link https://bitbucket.org/feedbackcompany/feedback-company-graphql/src/develop/src/main/java/com/feedbackcompany/graphql/exceptions/ErrorType.java
 * @param {Error} error - The ApolloError object
 * @param {string} error.message - The message describing the error.
 * @param {Object[]} error.graphQLErrors - The errors output by the GraphQL server. The same as seen in playground.
 * @param {null|Error} error.networkError - Potential networking errors
 * @returns {null|string[]}
 */
export const resolveGraphQLErrors = (error) => {
    if (!error.graphQLErrors) return null;
    if (!error.graphQLErrors.length) return null;
    return error.graphQLErrors.map((err) => err.extensions?.classification);
};
