<template>
    <div class="metadata-overview-container">
        <div class="metadata-overview" :class="{ 'metadata-overview-status': statusData }">
            <MetadataStatusHeader v-if="statusData" :statusData="statusData" />
            <div v-else class="metadata-overview-title-container">
                <div class="metadata-overview-title">
                    {{ title }}
                </div>
                <div v-if="showEdit">
                    <IconButton
                        @click.native="$emit('onClick')"
                        class="metadata-overview-icon"
                        label=""
                    >
                        <FontAwesomeIcon :icon="['far', 'edit']" />
                    </IconButton>
                </div>
            </div>
            <table
                class="metadata-overview-table"
                :class="{ 'metadata-overview-status-table': statusData }"
            >
                <tr v-for="field in metadata" :key="`metadata_field_${field.key}}`">
                    <td>
                        {{ field.key }}
                    </td>
                    <td v-if="field.isLink" class="parent-company-link">
                        <router-link :to="field.link">
                            {{ field.value }}
                        </router-link>
                    </td>
                    <td v-else :class="{ empty: field.isEmpty }">
                        {{ field.value }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import MetadataStatusHeader from "@/components/fraud-detection/MetadataStatusHeader.vue";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { IconButton } from "@feedbackcompany/feedback-company-vue-components";

library.add(faEdit);

defineProps({
    title: {
        type: String,
    },
    metadata: {
        type: Array,
        required: true,
    },
    showEdit: {
        type: Boolean,
    },
    statusData: {
        type: Object,
    },
});
</script>

<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.metadata-overview-container {
    padding-bottom: 15px;
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $grey_alabaster;
    margin-bottom: 40px;
    overflow: hidden;

    .metadata-overview {
        margin-top: 24px;
        margin-left: 24px;
        margin-right: 24px;

        &-status {
            margin: 0;
            &-table {
                margin: 0 16px;
            }
        }

        .metadata-overview-title-container {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $grey_alabaster;
        }

        .metadata-overview-icon {
            height: 24px;
        }

        .metadata-overview-title {
            font-family: "Montserrat", sans-serif;
            font-size: 1.25rem;
            font-weight: 700;
            padding-bottom: 20px;
        }

        .metadata-overview-table {
            padding-top: 8px;
            tr {
                color: black;
                font-family: "Open Sans", sans-serif;
                font-size: 0.75rem;
                font-weight: 400;
                letter-spacing: 0;
                width: 122px;

                .parent-company-link {
                    a {
                        color: $blue;
                        text-decoration: none;
                    }

                    a:hover {
                        text-decoration: underline;
                    }
                }

                td {
                    padding: 5px 0;
                }
                td:first-of-type {
                    color: $grey_manatee;
                    padding-right: 10px;
                }

                td.empty {
                    color: $grey_manatee;
                }
            }
        }
    }

    .fa-edit {
        color: $blue;
        &:hover {
            color: $blue_sapphire;
        }
    }
}
</style>
