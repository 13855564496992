import { render, staticRenderFns } from "./MetadataOverview.vue?vue&type=template&id=1c6c102a&scoped=true&"
import script from "./MetadataOverview.vue?vue&type=script&setup=true&lang=js&"
export * from "./MetadataOverview.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./MetadataOverview.vue?vue&type=style&index=0&id=1c6c102a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c6c102a",
  null
  
)

export default component.exports