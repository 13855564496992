var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"popup_title"},[_vm._v("Create a new questionnaire")]),_c(_setup.SettingsForm,{ref:"formEl",staticClass:"popup_form"},[_c('label',{staticClass:"popup_form_label"},[_vm._v("Name of questionnaire")]),_c(_setup.TextInput,{staticClass:"input",attrs:{"label":"Name","enableExternalValidation":"","externalValidationError":_setup.v$.newQuestionnaire.name.$error,"externalValidationErrorMessage":"Please choose a name"},model:{value:(_setup.newQuestionnaire.name),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "name", $$v)},expression:"newQuestionnaire.name"}}),_c('div',{staticClass:"divider"}),_c('label',{staticClass:"popup_form_label"},[_vm._v("Logo")]),_c('div',{staticClass:"popup_form_helptext"},[_vm._v(" Upload the logo of the customer that’s shown on the form. ")]),_c(_setup.FileInput,{staticClass:"fullwidth_input",attrs:{"type":"image","placeholderText":"Choose an image"},model:{value:(_setup.newQuestionnaire.logo),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "logo", $$v)},expression:"newQuestionnaire.logo"}}),_c('h3',{staticClass:"popup_form_heading"},[_vm._v("Settings")]),_c('label',{staticClass:"popup_form_label"},[_vm._v("Formal or informal")]),_c('div',{staticClass:"popup_form_helptext"},[_vm._v(" Should the form communicate to the respondent in a formal way or informal? ")]),_c(_setup.RadioList,{staticClass:"input",attrs:{"options":[
                { value: false, displayValue: 'Informal (Je / Jij)' },
                { value: true, displayValue: 'Formal (U / Uw)' },
            ],"required":""},model:{value:(_setup.newQuestionnaire.formal),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "formal", $$v)},expression:"newQuestionnaire.formal"}}),_c('div',{staticClass:"divider"}),_c('label',{staticClass:"popup_form_label"},[_vm._v("Language")]),_c('div',{staticClass:"popup_form_helptext"},[_vm._v(" Select the language of the questionnaire. The selected language only affects default messages. ")]),_c(_setup.Dropdown,{staticClass:"fullwidth_input input",attrs:{"placeholder":"Select a language","showRequiredAsterisk":"","options":[
                { value: 'en', displayValue: 'English' },
                { value: 'nl', displayValue: 'Dutch' },
                { value: 'es', displayValue: 'Spanish' },
                { value: 'fr', displayValue: 'French' },
            ],"enableExternalValidation":"","externalValidationError":_setup.v$.newQuestionnaire.defaultLanguage.$error,"externalValidationErrorMessage":"Please choose a language"},model:{value:(_setup.newQuestionnaire.defaultLanguage),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "defaultLanguage", $$v)},expression:"newQuestionnaire.defaultLanguage"}}),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"popup_form_switches"},[_c('div',{staticClass:"switch_group"},[_c(_setup.SwitchInput,{staticClass:"switch",attrs:{"small":""},model:{value:(_setup.newQuestionnaire.showDonate),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "showDonate", $$v)},expression:"newQuestionnaire.showDonate"}}),_c('label',{staticClass:"switch_group_label"},[_vm._v(" Donate button for Elemental Water Foundation is present on thank-you page ")])],1),_c('div',{staticClass:"switch_group"},[_c(_setup.SwitchInput,{staticClass:"switch",attrs:{"small":""},model:{value:(_setup.newQuestionnaire.showProgressBar),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "showProgressBar", $$v)},expression:"newQuestionnaire.showProgressBar"}}),_c('label',{staticClass:"switch_group_label"},[_vm._v("Progress bar present on form")])],1),_c('div',{staticClass:"switch_group"},[_c(_setup.SwitchInput,{staticClass:"switch",attrs:{"small":""},model:{value:(_setup.newQuestionnaire.kioskMode),callback:function ($$v) {_vm.$set(_setup.newQuestionnaire, "kioskMode", $$v)},expression:"newQuestionnaire.kioskMode"}}),_c('label',{staticClass:"switch_group_label"},[_vm._v(" The form reloads automatically 15 seconds after ending up on the thank-you page ")])],1)])],1),_c('div',{staticClass:"popup_footer"},[_c(_setup.Button,{nativeOn:{"click":function($event){return _setup.createNewQuestionnaire.apply(null, arguments)}}},[_vm._v("Save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }