import { requiredIf } from "@/validation/validators";

export const editIdentifiersSchema = () => ({
    identifiers: {
        platform: {
            required: requiredIf(
                (identifiers) => !!identifiers.entityId || !!identifiers.entityType
            ),
        },
        entityType: {
            required: requiredIf((identifiers) => !!identifiers.entityId),
        },
        entityId: {
            required: requiredIf((identifiers) => !!identifiers.entityType),
        },
    },
});
