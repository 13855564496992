import { email, required, isOneOf, requiredIf } from "@/validation/validators";

export const getCreateUserSettingsSchema = () => ({
    newUser: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        keycloakLanguage: { required, isOneOf: isOneOf(["nl", "en"], ["Dutch", "English"]) },
        divisions: {
            $each: {
                id: {
                    required,
                },
            },
        },
    },
    newDivisionId: {
        required: requiredIf((component) => {
            return (
                component.enableSelectDivision.value &&
                (component.usersDivisions.value.length === 0 || component.addingNewDivision.value)
            );
        }),
    },
});
