/* eslint-disable class-methods-use-this, func-names, no-plusplus */
import Highcharts from "highcharts";
import { chartService } from "@/services/Evaluation";

export default class ChartExportService {
    getChartsSvgData(charts) {
        const chartsData = {};
        if (Object.keys(charts).length > 0) {
            $.each(charts, (widgetId, chart) => {
                chartsData[widgetId] = this.getChartSvgData(chart);
            });
        }
        return chartsData;
    }

    getChartsBase64Data(charts, resultHandler) {
        const chartsData = {};
        const numOfCharts = Object.keys(charts).length;
        if (numOfCharts > 0) {
            let chartCounter = 0;
            $.each(charts, (widgetId, chart) => {
                const svgData = this.getChartSvgData(chart);
                const svgUrl = Highcharts.svgToDataUrl(svgData);
                Highcharts.imageToDataUrl(svgUrl, "image/jpeg", {}, 2, (imageURL) => {
                    chartsData[widgetId] = imageURL.replace(/^data:[^;]*;base64,/, "");
                    chartCounter++;
                    if (chartCounter === numOfCharts) {
                        resultHandler(chartsData);
                    }
                });
            });
        } else {
            resultHandler(chartsData);
        }
    }

    getChartSvgData(chart) {
        const chartExportConfig = {};
        jQuery.extend(true, chartExportConfig, chart.options);
        jQuery.extend(true, chartExportConfig, {
            chart: {
                width: 900,
                height: 600,
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: "10px",
                        color: "black",
                    },
                },
            },
        });

        const chartType = chart.options.chart.type;
        if (chartType === "bar") {
            // This so we don't lose too much space with bar charts
            chartExportConfig.chart.spacingLeft = 30;
            chartExportConfig.chart.marginLeft = 300;
            chartExportConfig.xAxis.labels.step = 1;
        } else if (chartType === "solidgauge") {
            if (chartExportConfig.yAxis.length > 1) {
                chartExportConfig.yAxis[1].labels.x = -5;
                chartExportConfig.yAxis[1].labels.y = 20;
                chartExportConfig.yAxis[1].labels.formatter = function () {
                    return chartService.getGaugeSideLabelFormat(
                        this.value,
                        chart.userOptions.decimals,
                        chartExportConfig.yAxis[0].min,
                        chartExportConfig.yAxis[0].max,
                        "col-md-6"
                    );
                };

                chartExportConfig.series = [
                    {
                        dataLabels: {
                            format: `<span style="text-align:center; font-size:48px; margin-top: -34px; color:#369AE2;">{y:.${chart.userOptions.decimals}f}</span>`,
                        },
                    },
                ];
            } else {
                chartExportConfig.title = {
                    text: "Most chosen emoji",
                };
                chartExportConfig.exporting.allowHTML = false;
                chartExportConfig.series = [
                    {
                        dataLabels: {
                            backgroundColor: "white",
                            borderColor: "white",
                            y: 5,
                        },
                    },
                ];
            }
        } else if (chartType !== "pie") {
            // config for charts that have labels on the bottom
            // This is to kill the whitespace under the charts
            chartExportConfig.chart.marginBottom = 250;
            // This is to better display the leftmost label
            chartExportConfig.chart.spacingLeft = 30;
            // These are so we have more room to display labels
            chartExportConfig.xAxis.labels.padding = 0;
            chartExportConfig.xAxis.labels.rotation = -70;
        }

        return chart.getSVG(chartExportConfig);
    }
}
