<template>
    <Tray
        :top="68"
        :right="24"
        :open-percentage="logoutTrayOpenPercentage"
        @openPercentageChangeRequest="setLogoutTrayOpenPercentage"
        :responsive="false"
    >
        <div class="user_tray__content">
            <header>
                <div class="user_tray__info">
                    <AccountImage
                        class="user_tray__info__image"
                        :initial="'given_name' in user ? user.given_name[0] : '-'"
                    />
                </div>
                <div class="user_tray__info__name">
                    {{ "given_name" in user ? user.given_name : "---" }}
                </div>
                <div class="user_tray__devider" />
            </header>
            <div class="user_tray__actions">
                <button @click="submitLogout">Log out</button>
            </div>
        </div>
    </Tray>
</template>

<script setup>
import { Tray } from "@feedbackcompany/feedback-company-vue-components";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";
import { computed } from "vue";
import { useStore } from "@/helpers/composition-helper";

const store = useStore();

const logoutTrayOpenPercentage = computed(() => {
    return store.state.UserAccountStore.logoutTrayOpenPercentage;
});

const user = computed(() => {
    return store.getters["UserAccountStore/getCurrentUser"];
});

function setLogoutTrayOpenPercentage({ percentage }) {
    store.commit("UserAccountStore/logoutTrayOpenPercentage", {
        openPercentage: percentage,
    });
}

function closeUserTray() {
    store.commit("UserAccountStore/logoutTrayOpenPercentage", {
        openPercentage: 0,
    });
}

function submitLogout() {
    closeUserTray();
    store.state.keycloak.logout();
}
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

$spacing: 16px;

.user_tray__info {
    width: 100%;
    padding-bottom: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-of-type {
        padding-top: 24px;
    }
}
.user_tray__actions {
    display: grid;
    padding: 24px;
    padding-top: 0px;
    justify-content: center;
    button {
        @extend %body2_style;
        background-color: $white;
        font-size: 0.75em;
        font-weight: 700;
        width: 202px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 8px 0;
        border: 1px solid $grey_alabaster;
        border-radius: 4px;
        &:hover {
            cursor: pointer;
            background: $grey_alabaster;
            border-color: $grey_athens;
        }
    }
}
.user_tray__info__image {
    flex-shrink: 0;
    border: 1px solid $grey_alabaster;
    width: 56px;
    height: 56px;
    overflow: hidden;
}
.user_tray__info__name {
    @extend %body2_style;
    font-weight: 600;
    padding: 0px 24px 0px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}
.user_tray__content {
    height: inherit;
}
.user_tray__devider {
    margin: 24px;
    border-top: 1px solid $grey_alabaster;
}
.user_tray__actions_logout {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}
.user_tray__actions_feedback_company {
    padding-top: 32px;
    justify-content: center;
    color: rgb(199, 199, 204);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
}
</style>
