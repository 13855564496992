<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: title,
            subtitle: subtitle,
            disableBackButton: false,
            backButtonTo: { path: backButtonUrl },
        }"
    >
        <StickyTable
            class="users_table"
            title="Users overview"
            :columns="[
                { label: 'Name', property: 'fullName' },
                { label: 'Username', property: 'username' },
                { label: 'Email', property: 'email' },
                { label: 'Company', property: 'company.name' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="['fullName', 'username', 'email', 'company.name']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="['fullName', 'username', 'email', 'company.name']"
            :searching="tableState.searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            :selectHeaderCheckbox="tableState.numberOfSelectedItems > 0"
            :partiallySelected="tableState.numberOfSelectedItems > 0 && !tableState.allSelected"
            :filteredItemsSelected="tableState.allFilteredSelected"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @selectAll="selectAll($event)"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <StickyTableRow
                class="table_row"
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <StickyTableCell first><Checkbox v-model="row.isSelected" /></StickyTableCell>
                <StickyTableCell>{{ row.fullName }}</StickyTableCell>
                <StickyTableCell>{{ row.username }}</StickyTableCell>
                <StickyTableCell>{{ row.email }}</StickyTableCell>
                <StickyTableCell>{{ row.company ? row.company.name : "" }}</StickyTableCell>
            </StickyTableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No users </span>
            </template>
            <template v-slot:actions>
                <div class="selected_items">
                    {{ tableState.numberOfSelectedItems }} selected items
                </div>
            </template>
            <template v-if="!isLoading" v-slot:filter>
                <FilterButton
                    id="clone-dashboard-filter"
                    @toggleFilter="openFilterTray"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#clone-dashboard-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button button-style="secondary" @click.native="clearDashboardFilters">
                                Clear all filters
                            </Button>
                            <Button @click.native="applyDashboardFilters"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Company</label>
                            <div class="checkbox_filters">
                                <Checkbox
                                    v-model="onlySameCompany"
                                    label="Show only users from the same company"
                                />
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="users"
                    ofString="of"
                />
            </template>
        </StickyTable>
        <template v-slot:footer>
            <Button @click.native="triggerClone" :loading="cloningDashboard">Clone</Button>
            <Button @click.native="goBack" class="cancel_button" buttonStyle="secondary"
                >Cancel</Button
            >
        </template>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            :message="confirmMessage"
            confirm-btn="Confirm"
            :confirmBtnIsLoading="isCloningAll"
            typeToConfirmString="Confirm overwrite"
            @confirm="cloneAllDashboards"
            @cancel="closeConfirmDialog"
        />
    </GenericPage>
</template>

<script>
import {
    LoaderCircular,
    PaginationIndicator,
    StickyTableRow,
    StickyTableCell,
    StickyTable,
    Button,
    Checkbox,
    ConfirmPopup,
    Tray,
    FilterButton,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import * as Sentry from "@sentry/vue";
import { ref } from "vue";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import getPlatformUsers from "../../graphql/getPlatformUsers.gql";
import cloneDashboard from "../../graphql/dashboards/cloneDashboard.gql";
import cloneUserDashboards from "../../graphql/dashboards/cloneUserDashboards.gql";
import getDashboardViewOwner from "../../graphql/dashboards/getDashboardViewOwner.gql";

export default {
    name: "CloneDashboard",
    components: {
        GenericPage,
        StickyTable,
        StickyTableRow,
        StickyTableCell,
        PaginationIndicator,
        LoaderCircular,
        Checkbox,
        Button,
        FilterButton,
        Tray,
        ConfirmPopup,
    },
    data() {
        return {
            platform: null,
            user: null,
            left: 0,
            prevRoute: "",
            cloningDashboard: false,
            showConfirmPopup: false,
            username: "",
            isCloningAll: false,
        };
    },
    computed: {
        title() {
            if (this.isResponseOverview) {
                return "Clone response overview";
            }
            if (this.isCloneAll) {
                return "Clone all dashboards";
            }
            return "Clone dashboard";
        },
        subtitle() {
            return this.isCloneAll
                ? `🧑‍💼 ${this.user?.username} - All dashboards from ${this.user?.username}`
                : `🧑‍💼 ${this.user?.username} - ${this.$route.query.name}`;
        },
        backButtonUrl() {
            return `/company/${this.$route.query.company}?activeTab=dashboards`;
        },
        selectedItems() {
            return this.tableState.rawData.filter((element) => element.isSelected);
        },
        successMessage() {
            return (
                `${this.isResponseOverview ? "Response overview" : "Dashboard"}` +
                " has successfully been cloned to " +
                `${
                    this.selectedItems.length > 1
                        ? "selected users"
                        : this.selectedItems[0].username
                }` +
                "."
            );
        },
        isResponseOverview() {
            return this.$route.query.type === "RESPONSE_OVERVIEW";
        },
        isCloneAll() {
            return this.$route.query.type === "ALL";
        },
        confirmMessage() {
            return `You are about to clone all dashboards from ${this.user.username}
                    to ${this.selectedItems.length} selected users. This means you
                    will <strong>overwrite</strong> all current user dashboards
                    and response overviews.
                    <br/>
                    <br/>
                    Type 'Confirm overwrite' to clone and overwrite the dashboards`;
        },
    },
    watch: {
        filterTrayOpenPercentage() {
            const filterButton = document.getElementById("clone-dashboard-filter");
            this.left = filterButton.offsetLeft - 335;
        },
    },
    setup() {
        const isLoading = true;
        // General table state
        const {
            data,
            setData,
            sort,
            search,
            searchTerm,
            setPage,
            setPaginationSize,
            selectAll,
            filtersApplied,
            applyFilters,
            clearFilters,
            setFilters,
        } = useTableState();

        const filterTrayOpenPercentage = ref(0);
        const setFilterOpenPercentage = (percentage) => {
            filterTrayOpenPercentage.value = percentage;
        };

        const onlySameCompany = ref(false);
        const onlySameCompanyLocal = ref(false);
        const ownersCompany = ref("");

        const applyDashboardFilters = () => {
            onlySameCompanyLocal.value = onlySameCompany.value;

            if (onlySameCompanyLocal.value) {
                applyFilters();
            } else {
                clearFilters();
            }
            filterTrayOpenPercentage.value = 0;
        };

        const clearDashboardFilters = () => {
            clearFilters();
            onlySameCompany.value = false;
        };

        setFilters([({ company }) => company?.id === ownersCompany.value]);

        return {
            isLoading,
            tableState: data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            setPaginationSize,
            selectAll,
            setFilterOpenPercentage,
            filterTrayOpenPercentage,
            onlySameCompany,
            applyDashboardFilters,
            clearDashboardFilters,
            filtersApplied,
            ownersCompany,
        };
    },
    methods: {
        async loadUsers() {
            this.isLoading = true;
            const results = await this.$apollo.query({
                query: getPlatformUsers,
                variables: {
                    platform: this.platform,
                },
            });
            this.setData(cloneDeep(results.data.platformUsers));
            this.isLoading = false;
        },
        onPageChange(page) {
            this.setPage(page);
        },
        openFilterTray() {
            this.setFilterOpenPercentage(1);
        },
        async cloneDashboard() {
            this.cloningDashboard = true;
            try {
                const payload = {
                    mutation: cloneDashboard,
                    variables: {
                        input: {
                            platform: this.platform,
                            userIds: this.selectedItems.map((element) => element.id),
                            dashboardViewId: this.$route.params.id,
                            dashboardType: this.$route.query.type,
                        },
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.cloneDashboard) {
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Cloned",
                        message: this.successMessage,
                    });
                    this.goBack();
                } else {
                    await this.pushError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushError();
            }
            this.cloningDashboard = false;
        },
        async cloneAllDashboards() {
            this.isCloningAll = true;
            try {
                const payload = {
                    mutation: cloneUserDashboards,
                    variables: {
                        input: {
                            platform: this.platform,
                            userIds: this.selectedItems.map((element) => element.id),
                            sourceUserId: this.user.evPlatformUserId,
                        },
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.cloneUserDashboards) {
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Cloned",
                        message: `Dashboards of ${this.user.username} has successfully been cloned to selected users.`,
                    });
                    this.goBack();
                } else {
                    await this.pushError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushError();
            }
            this.isCloningAll = false;
        },
        async pushError() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Error occured while trying to clone dashboard.",
            });
        },
        goBack() {
            this.$router.push({ path: this.backButtonUrl });
        },
        openConfirmDialog() {
            this.showConfirmPopup = true;
        },
        closeConfirmDialog() {
            this.showConfirmPopup = false;
        },
        triggerClone() {
            if (this.isCloneAll) {
                this.openConfirmDialog();
            } else {
                this.cloneDashboard();
            }
        },
    },
    async created() {
        const response = await this.$apollo.query({
            query: getDashboardViewOwner,
            variables: { id: this.$route.params.id },
        });
        this.platform = cloneDeep(response.data.dashboardViewOwner.platform);
        this.user = cloneDeep(response.data.dashboardViewOwner.user);
        this.ownersCompany = this.user.company?.id;
        this.onlySameCompany = true;
        this.applyDashboardFilters();
        await this.loadUsers();
    },
};
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.users_table {
    width: 100%;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.users_table::v-deep .edit_button {
    display: none;
}
.selected_items {
    @extend %button_typography_style;
    margin: 24px;
    color: $blue;
}
.cancel_button {
    margin-left: 12px;
}
.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;
}
.table_row {
    height: 37px;
}
</style>
