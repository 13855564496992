<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Manage filters',
            subtitle: 'Manage and navigate easily through filters.',
            disableBackButton: true,
        }"
    >
        <Table
            class="filters_table"
            title="Filters overview"
            :columns="[
                { label: 'Filter name', property: 'name' },
                { label: 'Category', property: 'type' },
                { label: 'Subcategory', property: 'attribute' },
                { label: 'Parent platform', property: 'platform' },
            ]"
            disableHeaderTextWrap
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="['name', 'type', 'attribute', 'platform']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            :searchable="tableState.searchEnabled"
            :searchableColumns="['name', 'type', 'attribute', 'platform']"
            :searching="tableState.searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                v-bind:key="index"
                v-bind:isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell>
                    <router-link :to="`/manage-filters/${row.id}`" class="filter_link">{{
                        row.name
                    }}</router-link>
                </TableCell>
                <TableCell>{{ row.type }}</TableCell>
                <TableCell>{{ row.attribute }}</TableCell>
                <TableCell>{{ row.platform }}</TableCell>
                <TableCell class="controls">
                    <IconButton
                        label=""
                        v-tippy="'Delete filter'"
                        class="delete-btn"
                        @click.native="openDeleteDialog(row)"
                    >
                        <font-awesome-icon :icon="['far', 'trash-alt']" />
                    </IconButton>
                    <IconButton class="edit-btn" label="">
                        <router-link :to="`/manage-filters/${row.id}`" v-tippy="'Edit filter'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No filters </span>
            </template>
            <template v-if="!isLoading" v-slot:filter>
                <FilterButton
                    id="filters-filter"
                    @toggleFilter="openFilterTray"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#filters-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button
                                button-style="secondary"
                                @click.native="clearWidgetTemplatesFilters"
                            >
                                Clear all filters
                            </Button>
                            <Button @click.native="applyWidgetTemplateFilters"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Category</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="date" label="Date" />
                                    <Checkbox v-model="question" label="Question" />
                                    <Checkbox v-model="recipient" label="Recipient" />
                                    <Checkbox v-model="related" label="Related" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="option" label="Option" />
                                    <Checkbox v-model="questionnaire" label="Questionnaire" />
                                    <Checkbox v-model="recipientFull" label="Recipient Full" />
                                </div>
                            </div>
                            <label>Parent platform</label>
                            <Dropdown
                                v-model="parentPlatform"
                                placeholder="Parent platform"
                                :searchAble="parentPlatforms.length > 3"
                                :options="parentPlatforms"
                            />
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="templates"
                    ofString="of"
                />
            </template>
        </Table>
        <template v-slot:footer>
            <Button
                @click.native="
                    $router.push({
                        path: `/manage-filters/new`,
                    })
                "
                buttonType="primary"
                >Create new</Button
            >
        </template>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            message="Are you sure you want to continue? Once a filter has been deleted, <b>this cannot be undone.</b>"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            :confirmBtnIsLoading="deleting"
            @confirm="deleteFilter"
            @cancel="closeConfirmDialog"
        />
    </GenericPage>
</template>

<script>
import * as Sentry from "@sentry/vue";
import {
    LoaderCircular,
    Table,
    TableRow,
    TableCell,
    PaginationIndicator,
    Button,
    Checkbox,
    FilterButton,
    Tray,
    IconButton,
    Dropdown,
    ConfirmPopup,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt, faClone } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import GenericPage from "@/components/layout/GenericPage.vue";
import cloneDeep from "lodash.clonedeep";
import deleteFilterTemplate from "../graphql/research/deleteFilterTemplate.gql";
import getFilterTemplates from "../graphql/research/getFilterTemplates.gql";

library.add(faTrashAlt, faEdit, faClone);

const CATEGORY = {
    DATE: "Date",
    OPTION: "Option",
    QUESTION: "Question",
    QUESTIONNAIRE: "Questionnaire",
    RECIPIENT: "Recipient",
    RECIPIENT_FULL: "RecipientFull",
    RELATED: "Related",
};

export default {
    name: "FilterTemplates",
    components: {
        GenericPage,
        Table,
        TableRow,
        TableCell,
        PaginationIndicator,
        LoaderCircular,
        FontAwesomeIcon,
        FilterButton,
        Tray,
        Button,
        IconButton,
        Checkbox,
        Dropdown,
        ConfirmPopup,
    },
    data() {
        return {
            parentPlatforms: [],
            left: 0,
            showConfirmPopup: false,
            deletionFilter: null,
            deleting: false,
        };
    },
    watch: {
        filterTrayOpenPercentage() {
            const filterButton = document.getElementById("filters-filter");
            this.left = filterButton.offsetLeft - 335;
        },
    },
    setup() {
        // General table state
        const {
            data,
            setData,
            sort,
            search,
            searchTerm,
            setPage,
            setPaginationSize,
            filtersApplied,
            applyFilters,
            clearFilters,
            setFilters,
        } = useTableState();

        // Filter state
        const filterTrayOpenPercentage = ref(0);
        const setFilterOpenPercentage = (percentage) => {
            filterTrayOpenPercentage.value = percentage;
        };

        const date = ref(false);
        const option = ref(false);
        const question = ref(false);
        const questionnaire = ref(false);
        const recipient = ref(false);
        const recipientFull = ref(false);
        const related = ref(false);
        const parentPlatform = ref(null);

        const dateLocal = ref(false);
        const optionLocal = ref(false);
        const questionLocal = ref(false);
        const questionnaireLocal = ref(false);
        const recipientLocal = ref(false);
        const recipientFullLocal = ref(false);
        const relatedLocal = ref(false);
        const parentPlatformLocal = ref(null);

        const applyWidgetTemplateFilters = () => {
            dateLocal.value = date.value;
            optionLocal.value = option.value;
            questionLocal.value = question.value;
            questionnaireLocal.value = questionnaire.value;
            recipientLocal.value = recipient.value;
            recipientFullLocal.value = recipientFull.value;
            relatedLocal.value = related.value;
            parentPlatformLocal.value = parentPlatform.value;

            if (
                dateLocal.value ||
                optionLocal.value ||
                questionLocal.value ||
                questionnaireLocal.value ||
                recipientLocal.value ||
                recipientFullLocal.value ||
                relatedLocal.value ||
                parentPlatformLocal.value
            ) {
                applyFilters();
            } else {
                clearFilters();
            }
            filterTrayOpenPercentage.value = 0;
        };

        const clearWidgetTemplatesFilters = () => {
            clearFilters();
            date.value = false;
            option.value = false;
            question.value = false;
            questionnaire.value = false;
            recipient.value = false;
            recipientFull.value = false;
            related.value = false;
            parentPlatform.value = false;
        };

        setFilters([
            ({ type, platform }) => {
                let allowedCategories = [];

                if (optionLocal.value) {
                    allowedCategories.push(CATEGORY.OPTION);
                }

                if (questionLocal.value) {
                    allowedCategories.push(CATEGORY.QUESTION);
                }

                if (dateLocal.value) {
                    allowedCategories.push(CATEGORY.DATE);
                }

                if (questionnaireLocal.value) {
                    allowedCategories.push(CATEGORY.QUESTIONNAIRE);
                }

                if (recipientLocal.value) {
                    allowedCategories.push(CATEGORY.RECIPIENT);
                }

                if (recipientFullLocal.value) {
                    allowedCategories.push(CATEGORY.RECIPIENT_FULL);
                }

                if (relatedLocal.value) {
                    allowedCategories.push(CATEGORY.RELATED);
                }

                if (allowedCategories.length === 0) {
                    allowedCategories = [
                        CATEGORY.DATE,
                        CATEGORY.OPTION,
                        CATEGORY.QUESTION,
                        CATEGORY.QUESTIONNAIRE,
                        CATEGORY.RECIPIENT,
                        CATEGORY.RECIPIENT_FULL,
                        CATEGORY.RELATED,
                    ];
                }

                return (
                    allowedCategories.includes(type) &&
                    (parentPlatformLocal.value ? parentPlatformLocal.value === platform : true)
                );
            },
        ]);

        return {
            tableState: data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            setPaginationSize,
            setFilterOpenPercentage,
            filtersApplied,
            filterTrayOpenPercentage,
            date,
            option,
            question,
            questionnaire,
            recipient,
            recipientFull,
            related,
            parentPlatform,
            applyWidgetTemplateFilters,
            clearWidgetTemplatesFilters,
        };
    },
    methods: {
        onPageChange(page) {
            this.setPage(page);
        },
        async getFilterTemplates() {
            this.isLoading = true;
            const results = await this.$apollo.query({
                query: getFilterTemplates,
                fetchPolicy: "network-only",
            });
            const filterTemplates = cloneDeep(results.data.filterTemplates);
            filterTemplates.forEach((element) => {
                element.type = element.type.replace("dim", "");
            });
            this.setData(filterTemplates);
            this.isLoading = false;
        },
        openFilterTray() {
            this.setFilterOpenPercentage(1);
        },
        async setPlatformsList() {
            this.tableState.rawData.forEach((element) => {
                if (!this.parentPlatforms.includes(element.platform)) {
                    this.parentPlatforms.push(element.platform);
                }
            });
            this.parentPlatforms = this.parentPlatforms.map((platform) => ({
                value: platform,
                displayValue: platform,
            }));
        },
        openDeleteDialog(user) {
            this.showConfirmPopup = true;
            this.deletionFilter = user;
        },
        closeConfirmDialog() {
            this.showConfirmPopup = false;
            this.deletionFilter = null;
            this.deleting = false;
        },
        async deleteFilter() {
            try {
                this.deleting = true;
                const response = await this.$apollo.mutate({
                    mutation: deleteFilterTemplate,
                    variables: {
                        templateId: this.deletionFilter.id,
                    },
                });
                if (response.data.deleteFilterTemplate) {
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Filter deleted",
                        message: `You've successfully deleted filter "${this.deletionFilter.name}"`,
                    });
                    this.closeConfirmDialog();
                    await this.getFilterTemplates();
                } else {
                    this.showError();
                }
            } catch (error) {
                Sentry.captureException(error);
                this.showError();
            }
        },
        async showError() {
            this.deleting = false;
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Error occured while trying to delete filter.",
            });
        },
    },
    async created() {
        await this.getFilterTemplates();
        this.setPlatformsList();
    },
};
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.filters_table {
    width: 100%;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.filter_link {
    color: $blue;
    text-decoration: none;
}
.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $red;
        }
    }
    .clone-btn {
        margin-right: 8px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
    .edit-btn {
        margin-right: 6px;
        color: $blue;
        &:hover {
            cursor: pointer;
            color: $blue_sapphire;
        }
    }
}
.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;

    &:first-of-type {
        margin-bottom: 28px;
    }
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > * {
        margin-bottom: 12px;
        width: 170px;
    }
}
</style>
