import Vue from "vue";
import VueRouter from "vue-router";
import Company from "@/views/Company.vue";
import Division from "@/views/Division.vue";
import Unauthorized from "@/views/status/Unauthorized.vue";
import NotFound from "@/views/status/NotFound.vue";
import Divisions from "@/views/Divisions.vue";
import Users from "@/views/Users.vue";
import Companies from "@/views/CompaniesView.vue";
import CreateUser from "@/views/CreateUser.vue";
import AnonymiseUser from "@/views/AnonymiseUser.vue";
import CreateConnection from "@/views/CreateConnection.vue";
import FraudDetection from "@/views/fraud-detection/FraudDetection.vue";
import FraudDetectionCase from "@/views/fraud-detection/FraudDetectionCase.vue";
import User from "@/views/User.vue";
import WidgetTemplates from "@/views/WidgetTemplates.vue";
import CloneDashboard from "@/views/dashboards/CloneDashboard.vue";
import ManageFilters from "@/views/ManageFilters.vue";
import CreateFilter from "@/views/CreateFilter.vue";
import EditFilter from "@/views/EditFilter.vue";

const FormBuilder = () => import(/* webpackChunkName: "FormBuilder" */ "@/views/FormBuilder.vue");
const WidgetEditorBridge = () =>
    import(/* webpackChunkName: "WidgetEditorBridge" */ "@/views/WidgetEditorBridge.vue");
const DashboardsBridge = () =>
    import(/* webpackChunkName: "DashboardsBridge" */ "@/views/DashboardsBridge.vue");

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/companies",
    },
    {
        path: "/company/:id",
        name: "Company",
        component: Company,
    },
    {
        path: "/division/:id",
        name: "Division",
        component: Division,
    },
    {
        path: "/401",
        name: "Unauthorized",
        component: Unauthorized,
    },
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
    },
    {
        path: "/divisions",
        name: "Divisions",
        component: Divisions,
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/companies",
        name: "Companies",
        component: Companies,
    },
    {
        path: "/anonymise-user",
        name: "AnonymiseUser",
        component: AnonymiseUser,
    },
    {
        path: "/company/:companyId/form-builder/:questId",
        name: "FormBuilder",
        component: FormBuilder,
    },
    {
        path: "/create-user",
        name: "CreateUser",
        component: CreateUser,
        props: (route) => ({
            companyId: route.query.companyId,
            companyName: route.query.companyName || null,
            divisionId: route.query.divisionId || null,
            divisionName: route.query.divisionName || null,
            platforms: route.query.platforms || null,
        }),
    },
    {
        path: "/edit-user/:userId",
        name: "EditUser",
        component: User,
    },
    {
        path: "/company/:id/create-connection/:provider/:connectionId?",
        name: "CreateConnection",
        component: CreateConnection,
    },
    {
        path: "/fraud-detection",
        name: "FraudDetection",
        component: FraudDetection,
    },
    {
        path: "/fraud-detection/:caseId",
        name: "FraudDetectionCase",
        component: FraudDetectionCase,
    },
    {
        path: "/widget-templates",
        name: "WidgetTemplates",
        component: WidgetTemplates,
    },
    {
        path: "/widget-templates/:id",
        name: "WidgetEditorBridge",
        component: WidgetEditorBridge,
    },
    {
        path: "/manage-filters",
        name: "ManageFilters",
        component: ManageFilters,
    },
    {
        path: "/manage-filters/new",
        name: "CreateFilter",
        component: CreateFilter,
    },
    {
        path: "/manage-filters/:id",
        name: "EditFilter",
        component: EditFilter,
    },
    {
        path: "/dashboards/:id",
        name: "DashboardsBridge",
        component: DashboardsBridge,
    },
    {
        path: "/dashboards/:id/edit",
        name: "DashboardsBridgeEdit",
        component: DashboardsBridge,
    },
    {
        path: "/dashboards/:id/clone",
        name: "CloneDashboard",
        component: CloneDashboard,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
