import Vue from "vue";
import Vuex from "vuex";
import PushNotificationsStore from "@feedbackcompany/feedback-company-vue-components/src/stores/notificationStore";
import DashboardStore from "@/store/evaluation/DashboardStore";
import UserAccountStore from "@/store/UserAccountStore";
import OverviewStore from "@/store/OverviewStore";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        keycloak: null,
    },
    actions: {
        pushNotification({ commit }, { type, message, title = null }) {
            commit("pushNotification", {
                type,
                message,
                customTitle: title,
            });
        },
    },
    modules: {
        PushNotificationsStore,
        DashboardStore,
        UserAccountStore,
        OverviewStore,
    },
});
