<template>
    <div class="status-indicator">
        <font-awesome-icon
            v-if="status === 'active'"
            class="status-icon status-icon--active-connection"
            :icon="['fas', 'circle-check']"
        />
        <font-awesome-icon
            v-if="status === 'inactive'"
            class="status-icon status-icon--inactive-connection"
            :icon="['fas', 'times-circle']"
        >
        </font-awesome-icon>

        <span class="status-description"
            ><slot>{{ status }}</slot></span
        >
    </div>
</template>
<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faClipboardList,
    faCommentAltExclamation,
    faTimesCircle,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faClipboardList, faCommentAltExclamation, faTimesCircle, faCheckCircle);

defineProps({
    status: {
        type: String,
    },
});
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.status-indicator {
    display: flex;
    align-items: center;
    width: max-content;
}
.status-icon {
    font-size: 1rem;
    margin-right: 8px;

    &.status-icon--active-connection {
        color: $green;
        font-size: 12px;
        font-weight: bold;
    }

    &.status-icon--inactive-connection {
        color: $red;
        font-size: 12px;
        font-weight: bold;
    }
}
.status-description {
    @extend %body1_style;
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 17px;
}
</style>
