<template>
    <div class="edit_parent_container">
        <SettingsBlock title="Edit user's parent">
            <div class="edit_parent_subtitle">Parent</div>
            <div class="edit_parent_text">
                Users may have incorrect or outdated access to a parent (company or division). This
                can be reconnected or adjusted here.
            </div>
            <div class="edit_parent_selection">
                <div class="subtitle3">Selected parent</div>
                <div class="body1 selection_indicator">
                    <span v-if="selectedParent.name">
                        <font-awesome-icon
                            class="parent__icon"
                            :icon="['far', 'building']"
                            size="sm"
                        />
                        <span>{{
                            selectedCompany.name ? selectedCompany.name : selectedParent.name
                        }}</span>
                    </span>
                    <span v-else class="selection_indicator--no-selection">No parent selected</span>
                </div>
            </div>
            <SearchTree
                :empty="data.data.length === 0"
                :currentPage="data.currentPage"
                :currentSorted="data.currentSorted"
                :currentSortDirection="data.currentSortDirection"
                searchable
                :searching="searchTerm !== null"
                :paginate="data.pages > 1 || (data.currentPage === 1 && data.itemCount > 10)"
                :pages="data.pages"
                :treeData="mappedTreeData"
                :selectItemHandler="selectItemHandler"
                @search="search($event)"
                @pageChange="setPage($event)"
                @onPaginationSizeChange="setPaginationSize($event)"
            >
                <template v-slot:empty>
                    <div v-if="isLoading" class="loading-indicator">
                        <LoaderCircular :height="48" />
                    </div>
                    <span v-else class="empty-table-message">No results.</span>
                </template>
                <template v-slot:pagination-indicator>
                    <PaginationIndicator
                        :pages="data.pages"
                        :currentPage="data.currentPage"
                        :currentPageItemCount="data.data.length"
                        :current-page-range-start="data.currentPageRangeStart"
                        :current-page-range-end="data.currentPageRangeEnd"
                        :itemCount="data.itemCount"
                        itemDescription="companies"
                        ofString="of"
                    />
                </template>
            </SearchTree>
        </SettingsBlock>
        <portal to="editUserFooter">
            <div class="footer">
                <Button :loading="isSaving" :disabled="!isDirty" @click.native="save">
                    Save
                </Button>
                <Button
                    class="cancel-button"
                    buttonStyle="secondary"
                    @click.native="router.push(previousPage)"
                >
                    Cancel
                </Button>
            </div>
        </portal>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import {
    LoaderCircular,
    Button,
    PaginationIndicator,
    useTableState,
} from "@feedbackcompany/feedback-company-vue-components";
import SearchTree from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/SearchTree.vue";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import cloneDeep from "lodash.clonedeep";
import { ref, onMounted, computed, watch } from "vue";
import { useRouter, useStore, useRoute } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { getUser } from "@/graphql/getUser.gql";
import updateUserAccess from "../../graphql/updateUserAccess.gql";
import getCompaniesHierarchy from "../../graphql/getCompaniesHierarchy.gql";

const router = useRouter();
const route = useRoute();
const store = useStore();

// props
const props = defineProps({
    selectedParent: {
        type: Object,
        required: true,
    },
    selectedDivisions: {
        type: Array,
        required: false,
    },
    username: {
        type: String,
        required: true,
    },
    previousPage: {
        type: String,
        default: "/users",
    },
    isParentCompany: {
        type: Boolean,
        required: true,
    },
});

const isLoading = ref(true);
const isSaving = ref(false);
const selection = ref(props.isParentCompany ? [props.selectedParent] : props.selectedDivisions);
const selectedCompany = ref({});

const { data, setData, search, searchTerm, setPage, setPaginationSize } = useTableState();
const tableState = data;

function clearSearchState() {
    tableState.value.data.forEach((element) => {
        element.isRootMatched = false;
        element.children.forEach((child) => {
            delete child.match;
        });
    });
}

const mappedTreeData = computed(() => {
    clearSearchState();
    tableState.value.data.forEach((element) => {
        if (element.matches) {
            element.isRootMatched = !!element.matches.find((match) => match.key === "name");
            element.matches.forEach((match) => {
                if (match.key !== "name") {
                    element.children[match.refIndex].match = match;
                    element.isOpen = true;
                }
            });
        }
    });
    return tableState.value.data;
});
const isDirty = computed(() => {
    if (props.isParentCompany) {
        return props.selectedParent.id !== selection.value[0].id;
    }
    if (props.selectedDivisions.length === 1) {
        return props.selectedDivisions[0].id !== selection.value[0].id;
    }
    return props.selectedDivisions.length != selection.value.length;
});

function selectItemHandler(event) {
    tableState.value.rawData.forEach((element) => {
        element.isActive = false;
        if (element.id === event.id) {
            selectedCompany.value = element;
        }
        element.children.forEach((child) => {
            if (child.id === event.id) {
                selectedCompany.value = element;
            }
            child.isActive = false;
        });
    });
    event.isActive = true;
    selection.value = [];
    selection.value.push(event);
}
function mapCompanyOrDivision(element) {
    element.children = element.divisions;
    if (props.isParentCompany) {
        element.isActive = element.id === selection.value[0].id;
    } else {
        element.isActive = props.selectedDivisions.map((el) => el.id).includes(element.id);
    }
    element.isSelected = false;
    element.isOpen = false;
}

function showError() {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while trying to edit parent.",
    });
    isSaving.value = false;
}

function handleResult(response) {
    const companiesHierarchy = cloneDeep(response.companiesHierarchy);
    const mappedValues = companiesHierarchy.map((element) => {
        mapCompanyOrDivision(element);
        element.divisions.forEach((division) => {
            mapCompanyOrDivision(division);
            if (division.isActive) {
                element.isOpen = true;
            }
        });
        return element;
    });
    setData(mappedValues, {
        searchThreshold: 11,
        paginationEnabled: true,
        paginationThreshold: 11,
        selectable: true,
        searchTermThreshold: 0.3,
        ignoreSearchLocation: true,
    });
    isLoading.value = false;
}

// mutation
const { mutate, onDone, onError } = useMutation(updateUserAccess);
onDone((result) => {
    if (result.data.updateUserAccess) {
        store.dispatch("pushNotification", {
            type: "success",
            title: "Saved",
            message: "Your changes have been saved.",
        });

        isSaving.value = false;
    } else {
        showError();
    }
});

onError(() => {
    showError();
});

function save() {
    const variables = {
        username: props.username,
        companyId: selection.value[0].children ? selection.value[0].id : null,
        divisions: selection.value[0].children ? null : selection.value[0].id,
    };
    mutate(variables, {
        refetchQueries: [{ query: getUser, variables: { id: route.params.userId } }],
    });
}

onMounted(async () => {
    const { result, onResult } = useQuery(getCompaniesHierarchy);
    if (result.value) handleResult(result.value);
    onResult((response) => {
        handleResult(response.data);
    });
});

watch(
    () => props.selectedParent,
    () => {
        selection.value = props.isParentCompany ? [props.selectedParent] : props.selectedDivisions;
    }
);

// expose
defineExpose({
    isDirty,
    save,
});
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

.edit_parent_container {
    margin-bottom: 140px;
}
.edit_parent_subtitle {
    @extend %subtitle3_style;
    margin-top: 30px;
    margin-bottom: 4px;
}
.edit_parent_text {
    @extend %body2_style;
    width: 430px;
    letter-spacing: 0;
    margin-bottom: 30px;
}
.edit_parent_selection {
    padding: 24px;
    border: 1px solid $grey_alabaster;
    border-radius: 4px;
    margin-bottom: 30px;
}
.selection_indicator {
    display: flex;
    align-items: center;

    :first-child {
        margin-right: 12px;
    }

    .selection_indicator--no-selection {
        color: $grey_bombay;
    }
}
.empty-table-message {
    padding: 10px 24px;
}
.loading-indicator {
    width: 100%;
    display: flex;
    justify-content: center;
}
.footer {
    width: 100%;
    display: flex;
    .cancel-button {
        margin-left: 12px;
    }
}
.parent__icon {
    width: 14px;
    height: 14px;
}
</style>
