var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[_c(_setup.LogoutTray,{attrs:{"id":"account-switch-tray"}}),_c(_setup.NotificationRail,{attrs:{"notificationList":_setup.pushNotificationList}}),_c('portal-target',{attrs:{"name":"popups_container"}}),_c('header',{staticClass:"main_header"},[_c(_setup.DesktopMenuBar,{attrs:{"gradient":""}},[_c(_setup.UserHeaderButton)],1)],1),_c('section',{staticClass:"main_section"},[_c(_setup.MainNavigation,[_c(_setup.NavigationList,[_c(_setup.NavigationListItem,{attrs:{"url":"/companies","type":"link","isActive":[
                            'Companies',
                            'Company',
                            'DashboardsBridge',
                            !!_vm.$route.query.companyId && 'CreateUser',
                        ].indexOf(_vm.$route.name) !== -1},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'building']}})]},proxy:true}])},[_vm._v(" Companies ")]),_c(_setup.NavigationListItem,{attrs:{"url":"/divisions","type":"link","isActive":[
                            'Divisions',
                            'Division',
                            !!_vm.$route.query.divisionId && 'CreateUser',
                        ].indexOf(_vm.$route.name) !== -1},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'sitemap']}})]},proxy:true}])},[_vm._v(" Divisions ")]),_c(_setup.NavigationListItem,{attrs:{"url":"/users","type":"link","isActive":['Users', 'EditUser'].indexOf(_vm.$route.name) !== -1},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'user']}})]},proxy:true}])},[_vm._v(" Users ")]),_c(_setup.NavigationNestedList,{attrs:{"isActive":['WidgetTemplates', 'WidgetEditorBridge', 'ManageFilters'].indexOf(
                            _vm.$route.name
                        ) !== -1},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Research")]},proxy:true},{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'chart-bar']}})]},proxy:true}])},[_c(_setup.NavigationNestedListItem,{attrs:{"type":"link","url":"/manage-filters"}},[_vm._v(" Manage filters ")]),_c(_setup.NavigationNestedListItem,{attrs:{"type":"link","url":"/widget-templates"}},[_vm._v(" Widget templates ")])],1),_c(_setup.NavigationListItem,{attrs:{"url":"/fraud-detection","type":"link","isActive":['FraudDetection'].indexOf(_vm.$route.name) !== -1},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'comment-alt-exclamation']}})]},proxy:true}])},[_vm._v(" Fraud detection ")]),_c(_setup.NavigationListItem,{attrs:{"url":"/anonymise-user","type":"link","isActive":['AnonymiseUser'].indexOf(_vm.$route.name) !== -1},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_setup.FontAwesomeIcon,{attrs:{"icon":['fal', 'eye-slash']}})]},proxy:true}])},[_vm._v(" Anonymise consumer ")])],1)],1),_c('main',{staticClass:"main_content"},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }