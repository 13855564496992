/* eslint-disable import/no-import-module-exports */
// The support portal only has support for english translation. However, the ported EV modules rely on i18n to be installed.

import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.yaml";
import nl from "./nl.yaml";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    warnHtmlInMessage: "off",
    silentTranslationWarn: true,
    messages: {
        nl,
        en,
    },
});

if (module.hot) {
    module.hot.accept(["./en.yaml", "./nl.yaml"], () => {
        // eslint-disable-next-line global-require
        i18n.setLocaleMessage("en", require("./en.yaml"));
        // eslint-disable-next-line global-require
        i18n.setLocaleMessage("nl", require("./nl.yaml"));
    });
}

export default i18n;
