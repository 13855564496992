<template>
    <GenericPage
        fullWidth
        :headerProps="{
            title: 'Users',
            subtitle: 'Use this overview wisely.',
            disableBackButton: true,
        }"
    >
        <Table
            class="users_table"
            title="User overview"
            :columns="[
                { label: 'Username', property: 'username' },
                { label: 'First name', property: 'firstName' },
                { label: 'Last name', property: 'lastName' },
                { label: 'Email', property: 'email' },
                { label: 'Company', property: 'companyName' },
                { label: 'Services', property: 'companyDisplayedServices' },
                { label: 'Creation date', property: 'createdTimestamp' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="visibleData.length === 0"
            :sortableColumns="[
                'username',
                'company.name',
                'firstName',
                'lastName',
                'email',
                'createdTimestamp',
            ]"
            :currentPage="state.currentPage"
            :currentSorted="state.currentSorted"
            :currentSortDirection="state.currentSortDirection"
            :searchable="state.searchEnabled"
            :searchableColumns="[
                'username',
                'company.name',
                'firstName',
                'lastName',
                'email',
                'companyDisplayedServices',
            ]"
            :searching="searchTerm !== null"
            :searchTerm="searchTermInit"
            :paginate="state.pages > 1 || (state.currentPage === 1 && state.itemCount > 10)"
            :defaultPaginationSize="state.paginationSize"
            searchbarPlaceholder="Search"
            :pages="state.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="onSetPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in visibleData"
                v-bind:key="index"
                v-bind:isLast="visibleData.length - 1 === index"
                bordered
            >
                <TableCell>
                    <router-link :to="getEditUserUrl(row.id)" class="edit_user_link">
                        {{ row.username }}
                    </router-link>
                </TableCell>
                <TableCell>{{ row.firstName }}</TableCell>
                <TableCell>{{ row.lastName }}</TableCell>
                <TableCell>{{ row.email }}</TableCell>
                <TableCell>
                    <router-link
                        v-if="row.company"
                        :to="getEditCompanyUrl(row.company.id)"
                        class="edit_user_link"
                    >
                        {{ row.companyName }}
                    </router-link>
                </TableCell>
                <TableCell>
                    {{ row.companyDisplayedServices }}
                </TableCell>
                <TableCell>
                    {{ row.formattedDate }}
                </TableCell>
                <TableCell class="controls">
                    <IconButton
                        label=""
                        v-tippy="'Delete user'"
                        class="delete-btn"
                        @click.native="confirmDelete(row)"
                    >
                        <font-awesome-icon :icon="['far', 'trash-alt']" />
                    </IconButton>
                    <IconButton class="edit-btn" label="">
                        <router-link :to="getEditUserUrl(row.id)" v-tippy="'Edit user'">
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                    <IconButton
                        label=""
                        v-tippy="
                            row.isEmailVerified === true
                                ? 'Impersonate user'
                                : 'User not verified yet'
                        "
                        @click.native="row.isEmailVerified === true ? impersonate(row.id) : ''"
                    >
                        <ImpersonateIcon :disabledIcon="row.isEmailVerified" />
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="state.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No users </span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="state.pages"
                    :currentPage="state.currentPage"
                    :currentPageItemCount="visibleData.length"
                    :current-page-range-start="state.currentPageRangeStart"
                    :current-page-range-end="state.currentPageRangeEnd"
                    :itemCount="state.itemCount"
                    itemDescription="users"
                    ofString="of"
                />
            </template>
        </Table>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            message="Are you sure you want to continue? Once a user has been deleted, <b>this cannot be undone.</b>"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            @confirm="deleteUser"
            @cancel="closeConfirmDialog"
        />
    </GenericPage>
</template>

<script setup>
import {
    LoaderCircular,
    IconButton,
    PaginationIndicator,
    TableCell,
    TableRow,
    Table,
    ConfirmPopup,
    useTableStateServerSide,
} from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import GenericPage from "@/components/layout/GenericPage.vue";
import { useHttp } from "@/helpers/useHttp";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
import cloneDeep from "lodash.clonedeep";
import { ref, watch, onMounted } from "vue";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import { useStore } from "@/helpers/composition-helper";
import { format, parseISO } from "date-fns";
import ImpersonateIcon from "../components/icons/ImpersonateIcon.vue";
import getUsers from "../graphql/overview/getUsers.gql";
import deleteUserMutation from "../graphql/deleteUser.gql";

library.add(faTrashAlt, faEdit);

const { httpPost } = useHttp();
const realm = process.env.VUE_APP_KEYCLOAK_CUSTOMER_REALM;
const showConfirmPopup = ref(false);
const deletionUser = ref(null);
const searchTermInit = ref(null);
const store = useStore();

// General table state
const {
    setData,
    sort,
    search,
    searchTerm,
    setPage,
    setPaginationSize,
    state,
    visibleData,
    setState,
} = useTableStateServerSide();

function mapServices(services) {
    return services.map((element) => (element === "APPRECIATION" ? "AP" : "EV")).join("/");
}

const {
    load: callLoadUsersQuery,
    onResult: onLoadUsersResult,
    onError: onLoadUserError,
    loading: isLoading,
} = useLazyQuery(getUsers);

onLoadUsersResult((result) => {
    if (result.loading) return;
    const users = cloneDeep(result.data.users.content);
    users.forEach((usr) => {
        // bringing sortable props to root level of user object
        if (usr.company) {
            usr.companyDisplayedServices = mapServices(usr.company.services);
            usr.companyName = usr.company.name;
        }
        usr.formattedDate = format(parseISO(usr.createdTimestamp), "dd-MM-yyyy", "en");
    });
    setData(users, result.data.users.total, { searchTermThreshold: 0.1 });
});

onLoadUserError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "Error occured while loading users",
    });
});

function firstLoadUsers() {
    const oldTableState = store.getters["OverviewStore/getState"]("users");
    setState(oldTableState);

    const pagination = {
        page: oldTableState.currentPage,
        perPage: oldTableState.paginationSize,
    };
    if (oldTableState.searchTerm) {
        searchTermInit.value = oldTableState.searchTerm;
        pagination.search = {
            text: oldTableState.searchTerm,
            fields: [],
        };
    }
    if (oldTableState.currentSorted) {
        pagination.sort = {
            field: oldTableState.currentSorted,
            direction: oldTableState.currentSortDirection.toUpperCase(),
        };
    }
    callLoadUsersQuery(getUsers, { pagination });
}

function loadUsers() {
    const pagination = {
        page: state.value.currentPage,
        perPage: state.value.paginationSize,
    };
    if (state.value.searchTerm) {
        pagination.search = {
            text: state.value.searchTerm,
            fields: [],
        };
    }
    if (state.value.currentSorted) {
        pagination.sort = {
            field: state.value.currentSorted,
            direction: state.value.currentSortDirection.toUpperCase(),
        };
    }
    callLoadUsersQuery(getUsers, { pagination });
}

function onSetPaginationSize(event) {
    setPage(1);
    setPaginationSize(event);
}

watch(state, () => {
    store.commit("OverviewStore/setState", { page: "users", newState: state.value });
    loadUsers();
});

function onPageChange(page) {
    setPage(page);
}
function impersonate(userId) {
    const headers = { "Content-Type": "application/json;charset=utf-8" };

    const body = {
        realm,
        user: userId,
    };

    httpPost({
        endPoint: `${process.env.VUE_APP_KEYCLOAK_URL}/admin/realms/${realm}/users/${userId}/impersonation`,
        body,
        headers,
    })
        .then(() => {
            window.open(process.env.VUE_APP_IMPERSONATION_REDIRECT_URL);
        })
        .catch(() => {
            store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Failed to impersonate user",
            });
        });
}

function getEditUserUrl(userId) {
    return {
        path: `/edit-user/${userId}`,
    };
}
function getEditCompanyUrl(companyId) {
    return {
        path: `/company/${companyId}`,
    };
}
function confirmDelete(user) {
    showConfirmPopup.value = true;
    deletionUser.value = user;
}
function closeConfirmDialog() {
    showConfirmPopup.value = false;
    deletionUser.value = null;
}

// mutation
const { mutate: deleteSelectedUser, onDone, onError } = useMutation(deleteUserMutation);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "User deleted",
        message: `You've successfully deleted user "${deletionUser.value.username}"`,
    });
    deletionUser.value = null;
});

onError((error) => {
    Sentry.captureException(error);
    deletionUser.value = null;
    if (resolveGraphQLErrors(error)[0] === "USER_NOT_FOUND") {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Unknown user",
            message: "There is no user with this id.",
        });
    } else {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "User could not be deleted.",
        });
    }
});
function deleteUser() {
    const variables = {
        userId: deletionUser.value.id,
    };
    showConfirmPopup.value = false;
    deleteSelectedUser(variables, {
        refetchQueries: [{ query: getUsers }],
    });
}

onMounted(async () => {
    firstLoadUsers();
});
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";

.users_table {
    width: 100%;
}
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .delete-btn {
        margin-right: 8px;
    }
    .edit-btn {
        margin-right: 6px;
    }
}
.edit_user_link {
    color: $blue;
    text-decoration: none;
}
.fa-edit {
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
}
.fa-trash-alt {
    color: $blue;
    &:hover {
        color: $red;
    }
}
</style>
