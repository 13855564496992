import { render, staticRenderFns } from "./CompanyGeneralSettings.vue?vue&type=template&id=60373410&scoped=true&"
import script from "./CompanyGeneralSettings.vue?vue&type=script&setup=true&lang=js&"
export * from "./CompanyGeneralSettings.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CompanyGeneralSettings.vue?vue&type=style&index=0&id=60373410&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60373410",
  null
  
)

export default component.exports