<template>
    <div>
        <h1 class="title">{{ title }}</h1>
        <div class="fields">
            <TextInput
                v-model="dashboard.name"
                label="Name"
                :disabled="!isPlatformSet"
                enableExternalValidation
                :externalValidationError="v$.dashboard.name.$error"
                :externalValidationErrorMessage="resolveErrorMessage(v$.dashboard.name)"
            />
            <Dropdown
                v-if="platforms.length > 1"
                v-model="dashboard.platform"
                placeholder="Platform"
                :searchAble="platforms.length > 3"
                :options="platformsList"
                enableExternalValidation
                :externalValidationError="v$.dashboard.platform.$error"
                :externalValidationErrorMessage="resolveErrorMessage(v$.dashboard.platform)"
            />
            <Dropdown
                v-model="dashboard.user"
                placeholder="Choose a user"
                :searchAble="users.length > 3"
                :options="users"
                :disabled="!dashboard.platform"
                enableExternalValidation
                :externalValidationError="v$.dashboard.user.$error"
                :externalValidationErrorMessage="resolveErrorMessage(v$.dashboard.user)"
            />
            <div v-if="!isPlatformSet" class="error-message">
                <span
                    >Please connect a platfrom to the company (or division) before dashboard can be
                    created.</span
                >
            </div>
        </div>
        <div class="actions">
            <Button @click.native="$emit('close')" buttonStyle="secondary" class="actions_cancel">
                <span>Cancel</span>
            </Button>
            <Button
                @click.native="validate"
                buttonStyle="primary"
                :loading="loading"
                :disabled="!isPlatformSet"
            >
                <span>Create</span>
            </Button>
        </div>
    </div>
</template>

<script setup>
import { TextInput, Dropdown, Button } from "@feedbackcompany/feedback-company-vue-components";
import { ref, watch, onMounted, computed } from "vue";
import { resolveErrorMessage } from "@/validation/resolveErrorMessageComposition";
import { getEntityUsers } from "@/graphql/dashboards/getEntityUsers.gql";
import cloneDeep from "lodash.clonedeep";
import { useLazyQuery } from "@vue/apollo-composable";
import { useVuelidate } from "@vuelidate/core";
import { createDashboardSchema } from "../validation/schemas/createDashboard";

const emit = defineEmits(["save"]);

const platformsList = ref([]);
const users = ref([]);
const dashboard = ref({ name: null, platform: null, user: null });

const props = defineProps({
    isResponseOverview: {
        type: Boolean,
        required: true,
    },
    loading: {
        type: Boolean,
    },
    platforms: {
        type: Array,
        required: true,
    },
    entities: {
        type: Array,
        required: true,
    },
});

const v$ = useVuelidate(createDashboardSchema(), { dashboard });

const title = computed(() => {
    return props.isResponseOverview ? "New response overview" : "New dashboard";
});

const isPlatformSet = computed(() => {
    return props.platforms.length > 0;
});

function validate() {
    v$.value.$touch();
    if (v$.value.$error) return;
    if (props.isResponseOverview) {
        dashboard.value.type = "RESPONSE_OVERVIEW";
    } else {
        dashboard.value.type = "DASHBOARD";
    }
    emit("save", dashboard.value);
}

const { load: loadEntityUsers, onResult: onEntityUsersResult } = useLazyQuery(getEntityUsers);
onEntityUsersResult((res) => {
    if (res.loading) return;
    const resUsers = cloneDeep(res.data.entityUsers);
    users.value = resUsers.map((user) => ({
        value: user.id,
        displayValue: user.username,
    }));
});

function getUsers() {
    loadEntityUsers(getEntityUsers, {
        entities: props.entities,
        platform: dashboard.value.platform,
    });
}

watch(
    () => dashboard.value.platform,
    () => {
        if (!dashboard.value.platform) {
            dashboard.value.user = null;
        } else {
            getUsers();
        }
    }
);

onMounted(async () => {
    platformsList.value = props.platforms.map((platform) => ({
        value: platform,
        displayValue: platform,
    }));
    if (props.platforms.length === 1) {
        dashboard.value.platform = platformsList.value[0].value;
    }
});
</script>

<style lang="scss" scoped>
@import "../style_variables/style_variables";

.title {
    @extend %headline5_style;
    margin-bottom: 30px;
}
.fields {
    * {
        margin-bottom: 12px;
    }
}
.actions {
    display: flex;
    justify-content: flex-end;
    .actions_cancel {
        margin-right: 12px;
    }
}
.error-message {
    @extend %caption_style;
    margin-left: 13px;
    display: block;
    color: $red;
    max-width: 350px;
    margin-bottom: 20px;
}
</style>
