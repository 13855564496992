<template>
    <div>
        <Table
            title="Questionnaire overview"
            :columns="[
                { label: '', property: 'active' },
                { label: 'Name', property: 'name' },
                { label: 'Public results', property: 'publicResults' },
                { label: 'Inactive date', property: 'inactiveDate' },
            ]"
            :disableHeaderTextWrap="true"
            sortable
            :empty="tableState.data.length === 0"
            :sortableColumns="['name', 'publicResults', 'inactiveDate']"
            :currentPage="tableState.currentPage"
            :currentSorted="tableState.currentSorted"
            :currentSortDirection="tableState.currentSortDirection"
            searchable
            :searchableColumns="['name', 'publicResults', 'inactiveDate']"
            :searching="tableState.searchTerm !== null"
            :paginate="
                tableState.pages > 1 || (tableState.currentPage === 1 && tableState.itemCount > 10)
            "
            :defaultPaginationSize="tableState.paginationSize"
            searchbarPlaceholder="Search"
            :pages="tableState.pages"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="onPageChange"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <TableRow
                v-for="(row, index) in tableState.data"
                :key="index"
                :isLast="tableState.data.length - 1 === index"
                bordered
            >
                <TableCell compact style="width: 0">
                    <SwitchInput v-model="row.active" @click.native="toggleStatus(row)" small />
                </TableCell>
                <TableCell>
                    <router-link
                        v-if="row.active"
                        :to="`/company/${companyId}/form-builder/${row.id}`"
                        class="edit_quest_link"
                    >
                        {{ row.name }}
                    </router-link>
                    <span v-else class="inactive_quest_name">{{ row.name }}</span>
                </TableCell>
                <TableCell>{{ row.publicResults ? "Yes" : "No" }}</TableCell>
                <TableCell>{{ row.inactiveDate }}</TableCell>
                <TableCell compact>
                    <IconButton label="">
                        <router-link
                            :to="`/company/${companyId}/form-builder/${row.id}`"
                            v-tippy="'Edit questionnaire'"
                        >
                            <font-awesome-icon :icon="['far', 'edit']" />
                        </router-link>
                    </IconButton>
                </TableCell>
            </TableRow>
            <template v-if="!isLoading" v-slot:filter>
                <FilterButton
                    id="questionnaires-filter"
                    @toggleFilter="setFilterOpenPercentage(1)"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#questionnaires-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button button-style="secondary" @click.native="clearQuestFilters">
                                Clear all filters
                            </Button>
                            <Button @click.native="applyQuestFilters"> Apply </Button>
                        </div>
                        <div class="filter__input">
                            <label>Status</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="questStatus.active" label="Active" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="questStatus.inactive" label="Inactive" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="filter__input">
                            <label>Results visibility</label>
                            <div class="checkbox_filters">
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="resultVisibility.public" label="Public" />
                                </div>
                                <div class="checkbox_filters_group">
                                    <Checkbox v-model="resultVisibility.private" label="Private" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:empty>
                <div v-if="isLoading" class="loading-and-messages">
                    <LoaderCircular />
                </div>
                <span v-else-if="tableState.searchTerm !== null" class="loading-and-messages">
                    No results
                </span>
                <span v-else class="loading-and-messages"> No questionnaires </span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="tableState.pages"
                    :currentPage="tableState.currentPage"
                    :currentPageItemCount="tableState.data.length"
                    :current-page-range-start="tableState.currentPageRangeStart"
                    :current-page-range-end="tableState.currentPageRangeEnd"
                    :itemCount="tableState.itemCount"
                    itemDescription="questionnaires"
                    ofString="of"
                />
            </template>
        </Table>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import {
    LoaderCircular,
    IconButton,
    PaginationIndicator,
    TableCell,
    TableRow,
    Table,
    useTableState,
    FilterButton,
    Tray,
    Checkbox,
    Button,
    SwitchInput,
} from "@feedbackcompany/feedback-company-vue-components";
import { ref, watch } from "vue";
import { useStore } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import cloneDeep from "lodash.clonedeep";
import getQuestionnaires from "../graphql/overview/getQuestionnaires.gql";
import updateQuestionnaireActiveStatus from "../graphql/overview/updateQuestionnaireStatus.gql";

library.add(faEdit);

const props = defineProps({
    companyId: {
        type: String,
        required: true,
    },
    companyPlatform: {
        type: String,
        required: true,
    },
});

const store = useStore();

const left = ref(0);
const questionnaires = ref([]);
const isLoading = ref(false);

// General table state
const {
    data,
    setData,
    sort,
    search,
    searchTerm,
    setPage,
    setPaginationSize,
    filtersApplied,
    applyFilters,
    clearFilters,
    setFilters,
} = useTableState();

const tableState = ref(data);

const filterTrayOpenPercentage = ref(0);
const setFilterOpenPercentage = (percentage) => {
    filterTrayOpenPercentage.value = percentage;
};

const resultVisibility = ref({
    public: false,
    private: false,
});
const questStatus = ref({
    active: false,
    inactive: false,
});

const resultVisibilityLocal = ref(cloneDeep(resultVisibility));
const questStatusLocal = ref(cloneDeep(questStatus));

const applyQuestFilters = () => {
    resultVisibilityLocal.value = cloneDeep(resultVisibility.value);
    questStatusLocal.value = cloneDeep(questStatus.value);

    if (
        resultVisibilityLocal.value.public ||
        resultVisibilityLocal.value.private ||
        questStatusLocal.value.active ||
        questStatusLocal.value.inactive
    ) {
        applyFilters();
    } else {
        clearFilters();
    }
    filterTrayOpenPercentage.value = 0;
};

const clearQuestFilters = () => {
    clearFilters();
    resultVisibilityLocal.value.public = false;
    resultVisibilityLocal.value.private = false;
    questStatusLocal.value.active = false;
    questStatusLocal.value.inactive = false;
};

setFilters([
    ({ active, publicResults }) => {
        return (
            (active === questStatusLocal.value.active ||
                !active === questStatusLocal.value.inactive) &&
            (publicResults === resultVisibilityLocal.value.public ||
                !publicResults === resultVisibilityLocal.value.private)
        );
    },
]);

function setTableData() {
    setData(questionnaires.value, {
        paginationSize: 10,
        paginationThreshold: 11,
        paginationEnabled: true,
    });
}

function onPageChange(page) {
    setPage(page);
}

function fetchQuestionnaires() {
    const { result, onResult, loading } = useQuery(getQuestionnaires, {
        companyId: props.companyId,
    });
    isLoading.value = loading;
    if (result.value) questionnaires.value = cloneDeep(result.value.questionnaires);
    onResult((response) => {
        questionnaires.value = cloneDeep(response.data.questionnaires);
    });
    setTableData();
}

// mutation
const { mutate: mutateStatus, onDone, onError } = useMutation(updateQuestionnaireActiveStatus);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "Updated",
        message: "Questionnaire succesfully updated!",
    });
});
onError(() => {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Error",
        message: "The questionnaire could not be updated.",
    });
});

function toggleStatus(row) {
    const variables = {
        platform: props.companyPlatform,
        questionnaireId: row.id,
        status: row.active,
    };
    mutateStatus(variables);
}

watch(
    () => questionnaires.value,
    () => {
        setTableData();
    }
);
watch(filterTrayOpenPercentage, () => {
    const filterButton = document.getElementById("questionnaires-filter");
    left.value = filterButton.offsetLeft - 335;
});

fetchQuestionnaires();
</script>
<style lang="scss" scoped>
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_colors";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
.edit_quest_link {
    color: $blue;
    text-decoration: none;
}
.inactive_quest_name {
    color: $grey_french;
}
.fa-edit {
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
}

.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 20px 32px;
    height: 100%;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}
.checkbox_filters {
    display: flex;
}
.checkbox_filters_group {
    display: flex;
    flex-direction: column;

    > :first-child {
        margin-bottom: 12px;
        width: 160px;
    }
}
</style>
