<template>
    <div>
        <div v-if="isLoading" class="loading-and-messages">
            <LoaderCircular />
        </div>
        <div v-else v-for="integration in integrations" :key="integration.type">
            <TracebuzzConnectionTable
                v-if="integration.type === 'TRACEBUZZ'"
                :data="integration"
                :hasPlatform="hasPlatform"
                @deleteConnection="confirmDelete($event)"
                @addConnections="onAddConnection('tracebuzz')"
            />
            <KidsKonnectConnectionTable
                v-if="integration.type === 'KIDS_KONNECT'"
                :data="integration"
                :hasPlatform="hasPlatform"
                @deleteConnection="confirmDelete($event)"
                @addConnections="onAddConnection('kids-konnect')"
            />
        </div>
        <ConfirmPopup
            v-if="showConfirmPopup"
            title="Warning"
            message="Are you sure you want to continue? Once a connection has been deleted, <b>this cannot be undone.</b>"
            confirm-btn="Delete"
            confirmButtonStyle="negative"
            @confirm="deleteConnection"
            @cancel="closeConfirmDialog"
        />
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore, useRouter } from "@/helpers/composition-helper";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { LoaderCircular, ConfirmPopup } from "@feedbackcompany/feedback-company-vue-components";
import { faEdit, faTrashAlt, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
// @todo test ok and ko deletions when the UI is complete.
import cloneDeep from "lodash.clonedeep";
import deleteIntegration from "../graphql/connections/deleteIntegration.gql";
import getIntegrations from "../graphql/connections/getIntegrations.gql";
import TracebuzzConnectionTable from "./TracebuzzConnectionTable.vue";
import KidsKonnectConnectionTable from "./KidsKonnectConnectionTable.vue";

library.add(faTrashAlt, faEdit, faPlus);

// props & helpers
const props = defineProps({
    company: {
        type: Object,
        required: true,
    },
});
const store = useStore();
const router = useRouter();

// data
const showConfirmPopup = ref(false);
const deletionConnection = ref(null);
const integrations = ref([]);
const isLoading = ref(false);

// computed
const hasPlatform = computed(() => {
    return !!(props.company.platform && props.company.entityType && props.company.entityId);
});

// methods
function confirmDelete(connection) {
    showConfirmPopup.value = true;
    deletionConnection.value = connection;
}
function closeConfirmDialog() {
    showConfirmPopup.value = false;
    deletionConnection.value = null;
}
function onAddConnection(provider) {
    router.push({ name: "CreateConnection", params: { provider } });
}
function fetchIntegrations() {
    const { result, onResult, loading } = useQuery(getIntegrations, {
        companyId: props.company.id,
    });
    isLoading.value = loading;
    if (result.value) integrations.value = cloneDeep(result.value.integrations);
    onResult((response) => {
        integrations.value = cloneDeep(response.data.integrations);
    });
}

// mutations
const { mutate, onDone, onError } = useMutation(deleteIntegration);
onDone(() => {
    store.dispatch("pushNotification", {
        type: "success",
        title: "Success",
        message: `"${deletionConnection.value.name}" was deleted successfully.`,
    });
    showConfirmPopup.value = false;
    deletionConnection.value = null;
});
onError((error) => {
    // @todo CONNECTION_NOT_FOUND is just a placeholder. Find and use the real constant.
    if (resolveGraphQLErrors(error)[0] === "CONNECTION_NOT_FOUND") {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Unknown connection",
            message: "There is no connection with this id.",
        });
    } else {
        /* eslint-disable prefer-template */
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: '"' + deletionConnection.value.name + '" could not be deleted.',
        });
    }
    showConfirmPopup.value = false;
    deletionConnection.value = null;
});

function deleteConnection() {
    const variables = {
        id: deletionConnection.value.id,
        integrationType: deletionConnection.value.integrationType,
    };
    mutate(variables, {
        refetchQueries: [{ query: getIntegrations, variables: { companyId: props.company.id } }],
    });
}

// code formerly inside created() hook
fetchIntegrations();
</script>

<style lang="scss" scoped>
.loading-and-messages {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
