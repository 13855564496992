<template>
    <SettingsForm class="settings_form" ref="formEl">
        <SettingsBlock class="settings_block" title="Webhook URL">
            <SettingsFieldSet class="settings_fieldset" label="">
                <FieldToClipboard
                    value="webhook URL goes here"
                    @onCopied="triggerCopyToast"
                    :disabled="innerConnectionData.id === null"
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block settings_block__credentials" title="Settings">
            <template v-slot:side-block>
                <div class="test_connection">
                    <FontAwesomeIcon
                        v-if="isConnectionChecked"
                        :icon="['fas', isConnectionValid ? 'circle-check' : 'times-circle']"
                        :class="`status-icon--${isConnectionValid ? 'valid' : 'invalid'}`"
                        v-tippy="
                            isConnectionValid
                                ? 'Connection is successful'
                                : 'Connection is unsuccessful'
                        "
                    />
                    <span @click="testConnection(true)" class="test_connection_button">
                        Test connection
                    </span>
                </div>
            </template>
            <SettingsFieldSet class="settings_fieldset" label="">
                <label class="subtitle3">Tracebuzz settings</label>
                <TextInput
                    label="Client secret"
                    v-model="innerConnectionData.clientSecret"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.clientSecret.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.clientSecret)
                    "
                />

                <TextInput
                    label="Username"
                    v-model="innerConnectionData.username"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.username.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.username)
                    "
                />

                <TextInput
                    label="Password"
                    is-password
                    v-model="innerConnectionData.password"
                    enableExternalValidation
                    :externalValidationError="
                        v$.innerConnectionData.password.$error || isConnectionInvalid
                    "
                    :externalValidationErrorMessage="
                        isConnectionInvalid
                            ? 'Credentials are not valid. Please check them.'
                            : resolveErrorMessage(v$.innerConnectionData.password)
                    "
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="API user">
            <SettingsFieldSet class="settings_fieldset" label="Select a platform">
                <label class="body2 language_body">
                    Before selecting a user, please select a platform.
                </label>
                <Dropdown
                    v-model="innerConnectionData.currentPlatform"
                    placeholder="Choose a platform"
                    :searchAble="platforms.length > 3"
                    :options="platforms"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.currentPlatform.$error"
                    :externalValidationErrorMessage="
                        resolveErrorMessage(v$.innerConnectionData.currentPlatform)
                    "
                />
            </SettingsFieldSet>

            <SettingsFieldSet class="settings_fieldset" label="Select a user">
                <label class="body2 language_body">
                    Select a user that you want to connect to this connection.
                </label>
                <Dropdown
                    :disabled="!innerConnectionData.currentPlatform || !users.length"
                    v-model="innerConnectionData.apiUser"
                    placeholder="Choose a user"
                    :searchAble="users.length > 3"
                    :options="users"
                    enableExternalValidation
                    :externalValidationError="v$.innerConnectionData.apiUser.$error"
                    :externalValidationErrorMessage="
                        resolveErrorMessage(v$.innerConnectionData.apiUser)
                    "
                />
            </SettingsFieldSet>
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="Select a questionnaire">
            <SettingsFieldSet class="settings_fieldset" label="Questionnaire">
                <label class="body2 language_body">
                    Select a platform and API user before selecting a questionnaire. The
                    questionnaire is based on this data.
                </label>
            </SettingsFieldSet>

            <div
                class="questionnaire_block selection_confirmation"
                :class="questBlockDisabledClass"
            >
                <div>
                    <div class="subtitle3">Selected questionnaire</div>
                    <div class="body1" :class="selectedQuestClass">
                        {{ selectedQuestName }}

                        <TextInput
                            class="selected-quest"
                            v-model="innerConnectionData.questionnaire.name"
                            enableExternalValidation
                            :externalValidationError="
                                v$.innerConnectionData.questionnaire.name.$error
                            "
                            externalValidationErrorMessage="Please select a questionnaire"
                        />
                    </div>
                </div>
            </div>

            <TreeView
                v-if="treeItems.length"
                class="questionnaire_block"
                :items="treeItems"
                :onClickHandler="setActive"
            />
        </SettingsBlock>

        <SettingsBlock class="settings_block" title="Message text">
            <SettingsFieldSet class="settings_fieldset" label="Message text">
                <label class="body2 language_body">
                    Write the message that the respondent will receive when a case is closed. Use
                    the placeholder ‘INVITATION_LINK’ to insert the link that leads to the
                    questionnaire.
                </label>

                <div class="editor__invitation-link">
                    <Editor
                        :api-key="key"
                        v-model="innerConnectionData.invitationLink"
                        :init="tinyMCEConfig"
                    />
                    <div
                        v-if="v$.innerConnectionData.invitationLink.$error"
                        class="validation__error"
                    >
                        At least you have to include "<strong>INVITATION_LINK</strong>".
                    </div>
                </div>
            </SettingsFieldSet>
        </SettingsBlock>
    </SettingsForm>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, watch, computed, onMounted, nextTick } from "vue";
import { useStore, useRoute } from "@/helpers/composition-helper";
import { useQuery, useLazyQuery } from "@vue/apollo-composable";
import SettingsForm from "@/components/layout/SettingsForm.vue";
import SettingsBlock from "@/components/layout/SettingsBlock.vue";
import SettingsFieldSet from "@/components/layout/SettingsFieldSet.vue";
import {
    TextInput,
    Dropdown,
    FieldToClipboard,
    TreeView,
} from "@feedbackcompany/feedback-company-vue-components";
import Editor from "@tinymce/tinymce-vue";
import { resolveErrorMessage } from "@/validation/resolveErrorMessageComposition";
import { scrollToFirstError } from "@/validation";
import { useVuelidate } from "@vuelidate/core";
import { createTracebuzzConnection } from "@/validation/schemas/connections";
import getIntegrationQuestionnaireCategoryNodes from "@/graphql/integrations/getIntegrationQuestionnaireCategoryNodes.gql";
import getEntityUsers from "@/graphql/dashboards/getEntityUsers.gql";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import testTracebuzzConnection from "@/graphql/connections/testTracebuzzConnection.gql";
import cloneDeep from "lodash.clonedeep";
import getCompany from "../../graphql/overview/getCompany.gql";

library.add(faTimesCircle, faCheckCircle);

const props = defineProps({
    connection: {
        type: Object,
        required: true,
    },
});
const store = useStore();
const route = useRoute();

const treeItems = ref([]);
const key = ref(process.env.VUE_APP_TINYMCE_KEY);
const tinyMCEConfig = ref({
    plugins: ["paste", "link", "code"],
    width: 400,
    height: 110,
    branding: false,
    statusbar: false,
    menubar: false,
    toolbar: "undo redo code",
    forced_root_block: false,
    entity_encoding: "raw",
});
const users = ref([]);
const emptyData = ref({
    provider: "",
    name: "",
    id: null,
    invitationLink: "",
    clientSecret: "",
    username: "",
    password: "",
    currentPlatform: "",
    apiUser: "",
    questionnaire: { name: "", isActive: false },
});
const innerConnectionData = ref(cloneDeep(emptyData.value));
const company = ref(null);
const isConnectionChecked = ref(false);
const isConnectionValid = ref(false);
const showToaster = ref(false);
const formEl = ref(null);
const selectedTreeItem = ref(null);

const emit = defineEmits(["save"]);

// validations
const v$ = useVuelidate(createTracebuzzConnection(), { innerConnectionData });

// computed
const selectedQuestClass = computed(() => {
    const { name } = innerConnectionData.value.questionnaire;
    return {
        no_questionnaire_selected: name === "" || name === null,
    };
});
const questBlockDisabledClass = computed(() => {
    const { currentPlatform, apiUser } = innerConnectionData.value;
    const currentPlatformIsEmpty = currentPlatform === "";
    const apiUserIsEmpty = apiUser === "";

    return {
        questionnaire_block_disabled: currentPlatformIsEmpty || apiUserIsEmpty,
    };
});
const selectedQuestName = computed(() => {
    const { name } = innerConnectionData.value.questionnaire;
    return name !== "" && name !== null ? name : "No questionnaire selected";
});
const entities = computed(() => {
    if (company.value.entityId) {
        return [{ entityId: company.value.entityId, entityType: company.value.entityType }];
    }
    return company.value.divisions.map((element) => ({
        entityId: element.entityId,
        entityType: element.entityType,
    }));
});
const platforms = computed(() => {
    if (!company.value) return [];
    const list = company.value.platform
        ? [company.value.platform]
        : company.value.divisionPlatforms;
    return list.map((platform) => ({
        value: platform,
        displayValue: platform,
    }));
});
const isConnectionInvalid = computed(() => {
    return isConnectionChecked.value && !isConnectionValid.value;
});

// methods
async function triggerCopyToast() {
    await store.dispatch("pushNotification", {
        type: "success",
        title: "Copied",
        message: "The value has successfully been copied to your clipboard.",
    });
}

function setActive(item) {
    selectedTreeItem.value = item;
    if (item.leaf) {
        if (item.isActive || item.children) return;
        if (innerConnectionData.value.questionnaire.name !== "")
            innerConnectionData.value.questionnaire.isActive = false;
        item.isActive = true;
        innerConnectionData.value.questionnaire = item;
    } else {
        // eslint-disable-next-line no-use-before-define
        loadQuestData(getIntegrationQuestionnaireCategoryNodes, {
            categoryNodeId: item.id,
            platform: innerConnectionData.value.currentPlatform,
            username: innerConnectionData.value.apiUser,
        });
    }
}

/**
 * Recursive function to find the active leaf independently how deep is it.
 */
function activateTreeLeaf(tree) {
    const rawId = innerConnectionData.value.questionnaire.id;
    const storedId = `ric_${rawId}`;

    tree.forEach((item) => {
        if (item.id === storedId || item.id === rawId) {
            setActive(item);
        } else {
            item.isActive = false;
        }

        if ("children" in item) {
            activateTreeLeaf(item.children);
        }
    });
}

const { load: loadQuestData, onResult: onQuestDataResult } = useLazyQuery(
    getIntegrationQuestionnaireCategoryNodes
);

onQuestDataResult((res) => {
    if (res.loading) return;
    const resData = cloneDeep(res.data.integrationQuestionnaireCategoryNodes);
    // eslint-disable-next-line no-use-before-define
    selectedTreeItem.value.children = prepareDisplayData(resData);
    selectedTreeItem.value = null;
});

const { load: loadRootQuestData, onResult: onRootQuestDataResult } = useLazyQuery(
    getIntegrationQuestionnaireCategoryNodes
);

onRootQuestDataResult((res) => {
    if (res.loading) return;
    const resData = cloneDeep(res.data.integrationQuestionnaireCategoryNodes);
    if (innerConnectionData.value.questionnaire.id !== null) {
        activateTreeLeaf(resData);
    }
    // eslint-disable-next-line no-use-before-define
    treeItems.value = prepareDisplayData(resData);
});

function setQuestionnaireData() {
    loadRootQuestData(getIntegrationQuestionnaireCategoryNodes, {
        categoryNodeId: "rootnode",
        platform: innerConnectionData.value.currentPlatform,
        username: innerConnectionData.value.apiUser,
    });
}

function prepareDisplayData(treeData) {
    return treeData.map((node) => {
        if (!node.leaf) {
            node.children = [];
            node.isOpen = false;
        }
        node.isActive = false;
        return node;
    });
}

function showError() {
    store.dispatch("pushNotification", {
        type: "error",
        title: "Tested",
        message: "Connection is unsuccessful. Please check your credentials",
    });
}

function showTestConnectionToaster() {
    if (showToaster.value) {
        if (isConnectionValid.value) {
            store.dispatch("pushNotification", {
                type: "success",
                title: "Tested",
                message: "Connection is successful.",
            });
        } else {
            showError();
        }
    }
}

const {
    result: testResult,
    load: callTestTracebuzzConnection,
    onResult: onTestTracebuzzConnection,
    onError: onTestTracebuzzConnectionError,
} = useLazyQuery(testTracebuzzConnection);

function displayToasterAndSave(isValid) {
    isConnectionValid.value = cloneDeep(isValid);
    isConnectionChecked.value = true;
    showTestConnectionToaster();

    if (!showToaster.value && !v$.value.$error && !isConnectionInvalid.value) {
        emit("save", innerConnectionData.value);
    }
}
onTestTracebuzzConnection((res) => {
    if (!res.loading) {
        displayToasterAndSave(res.data);
    }
});

onTestTracebuzzConnectionError(() => {
    isConnectionValid.value = false;
    isConnectionChecked.value = true;
    showTestConnectionToaster();
});

function testConnection(showToasterFlag) {
    showToaster.value = showToasterFlag;
    callTestTracebuzzConnection(testTracebuzzConnection, {
        input: {
            clientSecret: innerConnectionData.value.clientSecret,
            username: innerConnectionData.value.username,
            password: innerConnectionData.value.password,
        },
    });
    if (testResult.value?.testTracebuzzConnection != undefined) {
        displayToasterAndSave(testResult.value.testTracebuzzConnection);
    }
}

/**
 * Returns the user input.
 *
 * This method is really only called by the parent since this
 * component itself is a piece of a bigger form.
 */
function submitDataToParent() {
    v$.value.$touch();
    if (v$.value.$error || !!formEl.value.$el.querySelector("[class*=--invalid]")) {
        nextTick(() => {
            scrollToFirstError(formEl.value.$el);
        });
        return;
    }
    if (!v$.value.$error) {
        testConnection(false);
    }
}

function setPlatformUsersData(entityUsers) {
    const entUsersList = cloneDeep(entityUsers);
    users.value = entUsersList.map((item) => ({
        value: item.username,
        displayValue: item.username,
    }));
}

const { load: loadEntityUsers, onResult: onEntityUsersResult } = useLazyQuery(getEntityUsers);
onEntityUsersResult((res) => {
    if (res.data) {
        setPlatformUsersData(res.data.entityUsers);
    }
});

function setCompanyAndGetUsers(companyName) {
    company.value = companyName;

    // load entity users
    loadEntityUsers(getEntityUsers, {
        entities: entities.value,
        platform: company.value.platform,
    });
}

function reset() {
    innerConnectionData.value = cloneDeep(emptyData.value);
}

// watchers
watch(
    () => innerConnectionData.value.currentPlatform,
    (newPlatform, oldPlatform) => {
        if (newPlatform != oldPlatform && !newPlatform) {
            innerConnectionData.value.apiUser = "";
        }
    }
);

watch(
    () => innerConnectionData.value.apiUser,
    (newApiUser, oldApiUser) => {
        if (oldApiUser !== "" && props.connection.apiUser !== newApiUser) {
            innerConnectionData.value.questionnaire = { name: "", isActive: false };
        }
        treeItems.value = [];
        setQuestionnaireData();
    }
);

onMounted(async () => {
    const { result, onResult } = useQuery(getCompany, {
        id: route.params.id,
    });
    if (result.value) setCompanyAndGetUsers(result.value.company);
    onResult((res) => {
        setCompanyAndGetUsers(res.data.company);
    });
    // {...props.connection} for detaching reference (cloning).
    innerConnectionData.value = { ...props.connection };
    delete innerConnectionData.value.enabled;
});

defineExpose({
    submitDataToParent,
    reset,
});
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables";
@import "~include-media";

.settings_block {
    margin-bottom: 40px;

    &.settings_block__credentials {
        .subtitle3 {
            margin-bottom: 10px;
            display: block;
        }

        .text_input_holder {
            margin-bottom: 10px;
        }
    }

    .test_connection {
        @extend %button_typography_style;
        color: $blue;
        cursor: pointer;

        .status-icon--invalid {
            color: $red;
        }

        .test_connection_button {
            &:hover {
                color: $blue_sapphire;
            }
        }
    }
}

.language_body {
    margin-bottom: 12px;
    display: block;
    max-width: 400px;
}

.settings_form {
    padding-bottom: 100px;

    ::v-deep .formgroup__header {
        margin-bottom: 0;
    }
}

.unknown_platform {
    @extend %body2_style;
    color: $red;
    padding-left: 14px;
    letter-spacing: 0px;
}

.questionnaire_block {
    margin-top: 12px;
    padding: 24px;
    border: 1px solid $grey_alabaster;
    border-radius: 4px;

    &.selection_confirmation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &.tree {
        margin-bottom: 0;
        padding: 8px 24px 8px 24px;
    }

    &_disabled {
        background-color: $grey-bg;
        border-color: $grey_mischka;
        & * {
            color: $grey_mischka !important;
        }
    }
}

.no_questionnaire_selected {
    color: $grey_bombay;
}

.selected-quest {
    height: 1px;

    ::v-deep {
        .text_input {
            visibility: hidden;
            height: 1px;
        }

        .validation-message {
            margin: 0;
        }
    }
}

.editor__invitation-link {
    .validation__error {
        display: block;
        color: #c24242;
        padding-top: 4px;
        overflow: hidden;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        letter-spacing: 0.4px;
        text-align: left;
        line-height: 16px;
        margin-left: 13px;
    }
}
</style>

<style lang="scss">
.mce-tinymce {
    border: 1px solid #d1d1d6;
    border-radius: 4px 4px 0px 0px;
}

.mce-toolbar-grp {
    border-radius: 4px !important;
    background: #f9f9f9 !important;
}

.mce-tinymce {
    box-shadow: none !important;
}

.mce-top-part::before {
    box-shadow: none !important;
}

.mce-btn-group .mce-btn {
    background: #f9f9f9;
    &:hover {
        background: #f9f9f9;
    }
}
</style>
